/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 05.01.2015, 14:45:18
    Author     : florianreiner
*/
@import "utils";


%clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

#omedoo_medschedule_user_dateOfBirth select{
    display: inline-block;
    margin-right: 20px;
}

.bootstrap-datetimepicker-widget table td span.month {
    margin: 0;
}

/* =boxElement======================================*/
.boxElement {
    display: block;
    position: relative;

    &.collection li{
        @extend %clearfix;
        padding: 5px 0;
    }

    .formBlockWrapper {
        padding: 5px 0;
    }
}

.boxElement > .boxElement {
    margin-top: 0;
}
/* =========================*/

/* =shiftGroupPersonList============================*/
.shiftGroupPersonList{
    margin-top: 20px;
}
/* =========================*/



/* =dayList======================================*/
.dayList {
    overflow: hidden;
    margin: 15px auto 0;
    padding: 0;
    height: 30px;
    width: 96%;
    display: table;
    border-collapse: collapse;

    span {
        overflow: hidden;
        text-align: center;
        vertical-align: middle;
        padding: 0;
        margin: 0;
        height: 30px;
        border: 1px #ccc solid;
        border-right: none;
        background-color: #fff;
        color: #404040;
        cursor: pointer;
        display: table-cell;
        width: 14.2857%;


        label{
            cursor: pointer;
            line-height: 30px;
            color: #404040;
        }

        input{
            display: none;
        }

    }

    .primingBGC{
        @include transition(background-color 150ms ease-in-out);

        &:hover{
            background-color: rgba(144, 222, 144, 0.5);
        }
    }



    span:first-child{
        @include border-radius(4px 0px 0px 4px);
    }

    span:last-child{
        border-right: 1px solid #ccc;
        @include border-radius(0px 4px 4px 0px);
    }

}

.activeDay{
    border-collapse: collapse;
    background-color: #90DE90 !important;

    label {
        //color: rgba(0,163,0,1);
    }

}

.primingBGCActive{
    @include transition(background-color 150ms ease-in-out);

    &:hover{
        background-color: rgba(204, 204, 204, 0.5) !important;
    }
}

.dayList span label{
    display: inline-block;
    vertical-align: top;
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
}


.dayList span label {
}

.dayList span input {
    max-width: 20%;
}

/* =========================*/


/* =matrix======================================*/
.matrix {
    font-size: 14px;
    border: none;

    tr + tr {
        border-top: 1px #ccc solid;
    }

    td {
        padding: 10px;
        vertical-align: middle;
        color: #0293ED;
        font-size: 14px;
    }

    td + td {
        border-left: 1px #ccc solid;
    }


}

.matrix.checkboxMatrix{

    height: 200px;

    tr:first-child{
        text-align: center;
    }

    tr:nth-last-child(-n+2){
        height: 60px;
        text-align: right !important;
    }

    td, tr{
        border: none;
    }

    td:first-child{
        width: 25%;
    }

    td:nth-last-child(-n+2){
        display: inline-block;
        margin: 5px 5px;
        width: 45%;
        height: 60px;
    }

    td:nth-last-child(-n+1){
        margin-right: 15px !important;
    }

    tr:last-child td:nth-last-child(-n+2){
        border-top: 1px #F5F5F5 solid !important;
    }

    .tdQualification{
        text-align: right;
    }
}

.matrixContainer{
    margin: 0 0 0 0;
    overflow: hidden;
}

.matrixContainer + .matrixContainer{
    border-top: 1px #ccc solid;
}

td.tdCheckable{
    background-color: white;
    cursor: pointer;
    @include transition(background-color 150ms ease-in-out 0ms);


    &:hover{
        cursor: pointer;
    }

    input {
        display: none;
    }

}

.hoverGreen{
    background-color: rgba(50, 205, 50,0.5) !important;
    background-image: url("../images/checkmark_f5f5f5.png") !important;
    background-position: center;
    background-repeat: no-repeat;
}

.hoverGreyCross{
    background-color: #ddd !important;
    background-image: url("../images/cross_f5f5f5.png") !important;
    background-position: center;
    background-repeat: no-repeat;
}

.hoverGreyCheck{
    background-color: #ddd !important;
    background-image: url("../images/checkmark_f5f5f5.png") !important;
    background-position: center;
    background-repeat: no-repeat;
}


td.matrixChecked {
    background: rgba(50, 205, 50,0.5);
    background-image: url("../images/checkmark_f5f5f5.png");
    background-position: center;
    background-repeat: no-repeat;
}
/* =========================*/


/* =workplace matrices==================*/

.workplaceElement{
    background-color: white;
    display: block;
    width: 96%;
    margin: 20px auto !important;
    padding: 0 !important;
    background-color: white;
    border: none;
    @include box-shadow(1px 1px 2px 1px rgba(0,0,0,0.25));
    @include border-radius(4px);

    table.inputMatrix{

        border: none;

        td{
            border: none;

            span{
                margin: 0 auto;

                input{
                    text-align: center;
                }
            }

            span.allocationInputField{
                float: none;
                padding: 0;
                width: 33%;
            }

            span.hiddenInputField{
                display: none;
            }
        }

        tr:first-child{
            text-align: center;
        }



    }


}
/* =========================*/


.addButtonRow {
    display: table;
    width: 100%;
}

.addButtonRow .addSection.mainWrap {
    display: table-cell !important;
    width: auto !important;
    float: none !important;
}

.ulShiftGroup{
    border-bottom: 1px #ccc solid;
    width: 100%;
    height: 40px;
}

fieldset {

    width:100%;
    position:relative;
    background:$facegrey;
    padding:35px 15px 15px 15px;

    &.subnavLocationFieldset {
        border:1px solid $lightgrey;

        legend {
            top:-1px;
            left:-1px;
        }
    }

    & + fieldset {
        margin-top:15px;
    }

    fieldset {
        background:$colorWhite;
        legend {
            background:$mediumgrey;
        }
    }

    legend {
        padding: 1px 15px;
        margin: 0px 0 10px;
        font-weight: 400;
        position: absolute;
        top: 0px;
        left: 0px;
        color:$colorWhite;
        background:$colorPrimary;
        font-size: 14px;
        &.permissions {
            padding-top: 2px;
            padding-bottom: 2px;
            .btn {
                margin: 0;
            }
        }
    }
}

select {
    max-width:100%;
}

.innerHeading{
    margin-bottom: 5px;
}

.innerHeadingShiftGroup{
    margin-top: 15px;
    padding: 0 !important;
    color: #06A8EE;
}



/* =shiftSkeleton======================================*/

.collection-fields-list {

    .addListElement {
        vertical-align:top;
        display:inline-block;
        margin:0 0.6667%;

        &.__minWidth {
            width: auto;
        }

        .addElementButton {
            background-color:$colorPrimary;
            opacity: 0.7;
            background-image: url("../images/plus_f5f5f5.png");
            background-position: center;
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            padding: 0;
            color: #fff;
            display: inline-block;
            @include transition(opacity 150ms ease-in-out);

            &:hover{
                opacity: 1;
            }
        }
    }
}

.collection-fields-list li.boxElement > div > div + div {margin-top:5px;}

.collection-fields-list .boxElement {

    width: 31%;
    display: inline-block;
    margin: 0 0.6667% 20px 0.6667%;
    padding:10px;
    background-color:$colorWhite;
    border:1px solid $lightgrey;

    &.full-width {
        width: 100%;
        margin-bottom: 0;
    }

    &:hover {
        border-color: rgb(2, 147, 237);
    }

    select + select {
        margin-left:10px;
    }

    label.required{
        margin-right: 5px;
    }

    label {
        margin-top: 5px;
        margin-bottom: 0;
    }

    .formBlockWrapper{
        margin: 5px;
    }

    /*user, shiftgroup*/

    .timeWrapper{
        margin: 0;
        padding: 0;
    }

    .startTimeWrapper{
        display: inline-block;
        margin-bottom: 0px;

    }

    .endTimeWrapper{
        display: inline-block;
        margin-bottom: 0px !important;
    }

    .holidayWrapper{
        display: block;
        height: 24px;
        border: 1px #ccc solid;
        background-color: #fff;
        cursor: pointer;
        text-align: center;
        width: 50%;
        padding: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include border-radius(4px);


        label{
            display: inline-block;
            cursor: pointer;
            line-height: 24px;
            color: #404040;
        }

        input{
            display: none;
        }

        &.primingBGC{
            @include transition(background-color 150ms ease-in-out);

            &:hover{
                background-color: rgba(144, 222, 144, 0.5);
            }
        }


    }


    /*workplace*/

    .availableWrapper{
        display: block;
        height: 24px;
        border: 1px #ccc solid;
        background-color: #fff;
        cursor: pointer;
        text-align: center;
        width: 96%;
        margin: 15px 0 15px 5px;
        @include border-radius(4px);
        @include transition(background-color 150ms ease-in-out);

        &:hover{
            background-color: #F6B093;
        }

        label{
            display: inline-block;
            cursor: pointer;
            line-height: 24px;
            color: #404040;
        }

        input{
            display: none;
        }
    }

    .notAvailable{
        background-color: #F6B093 !important;

        &:hover{
            background-color: rgba(204, 204, 204, 0.5) !important;
            //border: 1px solid #ccc;

        }
    }

    .startDateWrapper{
        margin: 15px 0 15px 5px;

        .startDateDateWrapper{
            display: inline-block;
            margin-right: 10px;
        }

        .startDateTimeWrapper{
            display: inline-block;
        }

    }

    .endDateWrapper{
        margin: 15px 0 15px 5px;

        .endDateDateWrapper{
            display: inline-block;
            margin-right: 10px;
        }

        .endDateTimeWrapper{
            display: inline-block;
        }
    }

    &.collection-item-newly-added {
        border-width: 2px;
        border-style: dashed;
    }

    /*global*/
    .deleteCollectionItem  .deleteCollectionItem {
        background-position: 4px;
    }

    &.addListElement {
        vertical-align: bottom;
    }
}

.boxElementTab{
    height: 24px;
    background-color: #0293ed;
    padding: 0;
    margin: 0;
    width: 100%;
    color: white;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    @include border-radius(4px 4px 0px 0px);
}

/* =========================*/



.shiftFrameDeleted {
    display: none !important;
}

.deleteIcon {
    background: url("../images/trash-can-white.png") no-repeat scroll 4px 2px transparent;
    background-color: #F3966F;
    display: block;
    height: 23px;
    position: relative;
    top: 5px;
    right: -5px;
    float: right;
    width: 23px;
    opacity: 0.7;
    @include border-radius(4px);
    @include transition(opacity 150ms ease-in-out);

    &:hover {
        opacity: 1;
    }
}

.duplicateIcon {
    // background: url("../images/duplicate.png") no-repeat transparent;
    border: 1px solid #4d4d56;
    display: block;
    width: 23px;
    height: 23px;
    position: relative;
    top: 5px;
    float: right;
    opacity: 0.7;
    @include border-radius(4px);
    @include transition(opacity 150ms ease-in-out);

    &:hover {
        opacity: 1;
    }
}

.duplicateIconNw {
    font-size: 15px;
    padding: 3px;
    color: #4d4d56;
    font-weight: bold;
}

.dayElementHole{
    width: 12px;
    height: 12px;
    background-color: #F5F5F5;
    position: absolute;
    top: 5px;
    @include box-shadow(1px 1px 2px 1px rgba(0,0,0,0.25) inset);
    @include border-radius(50%);

    &.leftHole{
        left: 5px;
    }

    &.rightHole{
        right: 5px;
    }
}

table.userLocationTable {
    border-spacing: 5px !important;
    border-collapse: separate !important;
}

form {
    .collection {
        .deleteItem {
            background-position: 4px;
        }
    }
}

#customerLogoField {
    position: absolute;
    left: 318px;
    top: 52px;
}

#customerKeyField {
    position: absolute;
    left: 30px;
}

#customerSubmitButton {
    position: absolute;
    left: 68%;
    top: 92px;
    margin-bottom: 15px;
    margin: 15px;
}

#valuePref {
    position: absolute;
    left: 55%;
    display: block;
}

#customerPreferencesForm {
    margin-bottom: 65px;
}

.customerPreferencesTd {
    vertical-align: middle;
}

.customerPreferencesSquare {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    margin-left: 46%;
}

@media (min-width: 768px){
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1  {
        width: 100%;
        *width: 100%;
    }
}

@media (min-width: 992px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
    }
}

@media (min-width: 1200px) {
    .seven-cols .col-md-1,
    .seven-cols .col-sm-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
    }
}

.AdjustTimetrack {
    width: 100px;
}

.first-col-Tt {
    padding-left: 1pc !important;
}

.first-col-Tt-delete
{
    padding-left: 1pc !important;
    i:hover{
        cursor: pointer;
        color: #f50057;
    }
}


.approve-uae {
    padding-left: 1pc !important;
    i:hover{
        cursor: pointer;
        color: #46bd15;
    }
}

.reactivate-Tt {
    padding-left: 1pc !important;
    i:hover{
        cursor: pointer;
        color: yellowgreen;
    }
}

.timesIcon {
    zoom: 1.2;
    -ms-zoom: 1.2;
    -webkit-zoom: 1.2;
}

.user-delete-Tt {
    i:hover{
        cursor: pointer;
        color: #f50057;
    }
}

.edit-Tt-btn:hover {
    cursor: pointer;
    color: #628cf5;
}

.timetrackDeleted {
    text-decoration: line-through;
    font-style: italic;
    color: tomato;
}

.administrationRequest {
    background: #fff;
    box-shadow: 5px 5px 5px rgba(224, 207, 207, 0.31);
    border: 1px solid #0293ed;
}

.requestManagement__fieldset--max-height {
    max-height: 750px;
    overflow-y: auto;
}

.AdjustApprovedVacation, .openPeriod, .adjustPause
{
    i:hover{
        cursor: pointer;
        color: #0293ed;
    }
}

.administrationForm {
    padding-top: 10px;
    display: none
}
.shiftFrameQualifications {
    margin-bottom: 10px;
}
.compensationTimes {
    border: 1px solid #ccc;
    padding: 5px;
    overflow-y: scroll;
    height: 100px;
    width: 100%;
}

.shiftFrameQualificationsItem, .compensationTimesItem {
    display: flex;
    margin-bottom: -5px;
}

.shiftFrameQualificationsLabel, .compensationTimesLabel {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 4px;
}

.qualificationInputItem, .compensationTimeInputItem {
    width: 13px;
    height: 13px;
    padding: 0;
    margin:0;
    vertical-align: text-bottom;
    position: relative;
    top: -1px;
}

.shiftFrameStartTime {
    margin-top: 15px;
    margin-bottom: -5px;
}

.shift-frame {
    .duration {
        font-size: 12px;
        padding-top: 5px;
    }
    input {
        &:disabled {
            background: #ddd;
        }
    }
}
.time-type-field {
    display: flex;

    .time-type-field-label {
        margin: 5px;
    }

    .field-hours {
        display: flex;
    }
    .field-minutes {
        display: flex;
    }
}

.activeItem {
    float: right;
    color: #0293ed;
    font-size: 11px;
}

.createdItem {
    font-size: 11px;
    float: right;
}

.enrollmentItem {
    font-size: 11px;
    float: right;
}

.selectionItem {
    font-size: 11px;
    float: right;
}

.planningItem {
    font-size: 11px;
    float: right;
}

.userAvailabilitiesWidget {
    font-size: initial;
    padding-top: 10px;
    margin-bottom: -8px;
}

.shiftPlanIsEditing {
    border: 2px solid #0293ed;
    padding: 5px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.shiftplanRow {
    border: 1px solid #d3d3d3;
    padding: 5px;
    margin-left: -9px;
    margin-right: -9px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.sp-separation-line {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #d3d3d3;
}

.sp-comment-mg {
    cursor: pointer;
    float: right;
    padding-right: 12px;
    text-decoration: underline;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.highlightErrorInput {
    box-shadow: 0 0 8px #ee5151;
    padding: 3px 0px 3px 3px;
    margin: 5px 1px 3px 0px;
    border: 1px solid #ee5151;
}

.displayInline {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
}

.wrongInputs {
    box-shadow: 0 0 5px red;
    border: 1px solid red;
}
.validationText {
    color: red;
}

.delete-ph-btn {
    i:hover{
        cursor: pointer;
        color: #f50057;
    }
}

.deleted {
    background: #eecbca;
}

.first-col-delete-ph
{
    padding-left: 0;
    i:hover{
        cursor: pointer;
        color: #f50057;
    }
}

.first-col-edit-ph
{
    padding-left: 0;
    i:hover{
        cursor: pointer;
        color: #337ab7;
    }
}

.ph-year:hover {
    cursor: pointer;
    text-decoration: underline;
}

.select-y :hover {
    cursor: pointer;
}


.email-preferences {
    font-family: "FontAwesome", "-apple-system", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif
}

#user_work_times_container .schedule-rows td {
    width: 80px;
    height: 10px;
    line-height: 10px;
    margin: 3px;
    padding: 0px;
    background-color: #74bd74;
    cursor: pointer;
}

#user_work_times_container .schedule-rows tr {
    border-top-color: black;
}

#user_work_times_container .schedule-rows td:first-child {
    background-color: transparent;
    text-align: right;
    position: relative;
    top: -6px;
}
#user_work_times_container .schedule-rows td[data-selected],
#user_work_times_container .schedule-rows td[data-selecting=selecting] {
    background-color: #ea8b5e;
}
#user_work_times_container .schedule-rows td[data-selecting=deselecting] {
    background-color: #74bd74;
}
#user_work_times_container .schedule-rows td[data-disabled] {
    opacity: 0.55;
}

#user_work_times_container .time-slot {
    border: 1px solid #777;
}

#user_work_times_container .time-slot[data-time$=":45"] {
    border-bottom: 1px solid black;
}

th.rotate {
    white-space: nowrap;
    cursor:pointer;
    height: 30px;
    width: 25px;
    border: 1px solid lightgrey !important;
    padding: 0 !important;
}

th.rotate > div {
    -ms-transform: translate(-8px, -8px) rotate(270deg); /* IE 9 */
    -webkit-transform: translate(-8px, -8px) rotate(270deg);
    transform: translate(-8px, -8px) rotate(270deg);
    width: 30px;
}

th.rotate > div > span {
    padding: 0px 5px;
}

.uae-available { //available
    color: white;
    background-color: #00bd13 ;
}


.uae-not_wanted { //not_wanted
    color: white;
    background-color: #bd6a24 ;
}


.uae-not_available, .uae-before_starting_contract, .uae-after_ending_contract { //not_available
    background-color: #bd1711 ;
    color: white;
}

.invisibleUAECell {
    display: none;
}

.uae-vacation { //vacation
    color: white;
    background-color: #80bf27 ;
}


.uae-priority_off { //priority_off
    color: white;
    background-color: #9E9E9E ;
}


.uae-training { //training
    color: white;
    background-color: #AB47BC ;
}

.uae-illness { //illness
    color: white;
    background-color: #0277BD ;
}

.uae-free_time_comp { //free_time_comp
    color: white;
    background-color: #009688 ;
}

.uae-off_duty { //off_duty
    color: white;
    background-color: #2196F3 ;
}

.uae-part_time_free { //part_time_free
    color: white;
    background-color: #795548 ;
}

.uae-flexible { //flexible
    color: white;
    background-color: #a07ebd ;
}

.uae-school { //school
    color: white;
    background-color: #9aa8bd ;
}

.uae-flexible1 { //flexible1
    color: white;
    background-color: #a07ebd ;
}

.uae-flexible2 { //flexible2
    color: white;
    background-color: #a07ebd ;
}

.uae-sick_with_kid {
    color: white;
    background-color: #bd9b4b ;
}


.uae-illness_part_day {
    color: white;
    background-color: #bd72a0 ;
}

.uae-illness_after_6_weeks {
    color: white;
    background-color: #41bdad ;
}

.uae-half_day_vacation {
    color: white;
    background-color: #bd2993 ;
}

.uae-unpaid_vacation {
    color: white;
    background-color: #75bd07 ;
}


.uae-half_schoolday {
    color: white;
    background-color: #bd8f67 ;
}

.uae-maternity_leave {
    color: white;
    background-color: #324cbd ;
}


.uae-parental_leave {
    color: white;
    background-color: #aebd17 ;
}


.uae-childcare_leave {
    color: white;
    background-color: #bd6b1b ;
}


.uae-special_leave {
    color: white;
    background-color: #297301 ;
}

//
//.exceptionItem {
//    border: 0;
//}

.exceptionItemHighlighted {
    outline: 3px solid red;
}
/*
.isEndDate.exceptionItemHighlighted {
  border-bottom: 3px solid red !important;
  border-right: 3px solid red !important;
  border-left: 3px solid red !important;
  width: 9px;
  height: 12px;
}

.isStartDate.exceptionItemHighlighted {
  border-top: 3px solid red !important;
  border-right: 3px solid red !important;
  border-left: 3px solid red !important;
  width: 9px;
  height: 12px;
}

.notEndDate.notStartDate.exceptionItemHighlighted {
  border-right: 3px solid red !important;
  border-left: 3px solid red !important;
  width: 9px;
}
*/
.uaeNotApproved {
    color: #d462b9 !important;
    border: 1px solid #d462b9 !important;
    -webkit-filter: opacity(.6);
    filter: opacity(.6);
}

.username:hover {
    background-color: #e8e8e8;
}

#previous-year:hover {
    color: tomato;
}

#next-year:hover {
    color: tomato;
}

.exception-description-modal {
    position: fixed;
    top: 31px;
    right: 100px;
    bottom: 0;
    left: 25px;
    width: 250px;
    margin: 0;
    z-index: 10040;
    overflow: auto;
}

.year-navigator-def {
    font-size: larger;
    position: relative;
    .btn {
        &.filter-toggle {
            position: absolute;
            right: 0;
            .fa {
                position: relative;
                width: auto;
            }
        }
    }
}

#tableContent {
    overflow-y: scroll;
    width: fit-content;
    border-bottom: 1px solid lightgrey;
}


.advanced-calendar-explanation {
    margin-top: 10px;
    margin-left: 45px;
    display: block;
    float: left;

    table {
        border-spacing: 5px !important;
        border-collapse: separate !important;;
        width: auto;
    }

    .color-square-vacation {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #80bf27;
    }

    .color-square-illness {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #0277BD;
    }

    .color-square-training {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #AB47BC;
    }

    .color-square-free-time-comp {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #009688;
    }

    .color-square-off-duty {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #2196F3;
    }

    .color-square-part-time-free {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #795548;
    }

    .color-square-priority-off {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #9E9E9E;
    }

    .color-square-not-available {
        width: 20px;
        height: 20px;
        border-radius: 50% / 10%;
        background-color: #ed6102;
    }

    .label {
        text-align: left;
        vertical-align: middle;
        display: block;
    }
}

.hidden-uae-item {
    background-color: white !important;
}

.hide-left-menu {
    margin-left: 280px;
    position: fixed;
    font-size: x-large;
    margin-top: -5px;
    cursor: pointer;
    &:hover{
        color: tomato;
    }
}

.show-left-menu {
    position: fixed;
    font-size: x-large;
    margin-left: -25px;
    margin-top: -5px;
    cursor: pointer;
    &:hover{
        color: tomato;
    }
}

.calendar-filters {
    margin-left: 170px;
}

.uae-user-cell-style {

}

.advancedAbsencesView {
    padding-top: 30px;
    /*width: 0 !important;*/
}

.first-table-cell {
    width: 40px;
    border-left: 3px solid white;
    border-bottom: 1px solid lightgrey;
}

.second-table-cell {
    width: 30px;
    border: 1px solid white;
    border-bottom: 1px solid lightgrey;
}

.current-connected-user {
    font-weight: bold;
    background-color: #e8e8e8;
}

.table-row-def {
    text-align: center;
    border: 1px solid lightgrey;
}

.table-month-def {
    width: 40px;
    padding: 0 !important;
    border-left: 3px solid lightgrey;
    background-color: transparent !important;
    vertical-align: middle !important;
}

.table-month-count {
    width: 100px;
    padding: 0 !important;
    border-left: 3px solid lightgrey;
    background-color: transparent !important;
    vertical-align: middle !important;
    color: black;
}

.table-month-rotate {
    transform: translate(0px, 0px) rotate(270deg);
    -ms-transform: translate(0px, 0px) rotate(270deg);
    -webkit-transform: translate(0px, 0px) rotate(270deg);
    font-weight: bold;
}

.table-month-day {
    width: 30px;
    border: 1px solid lightgrey;
    padding: 0 !important;
}

.table-month-day-number {
    width: 15px !important;
    border: 1px solid lightgrey;
    padding: 0 !important;
}

.table-cell-description {
    width: 15px;
    height: 15px;
    border: 1px solid lightgrey;
    padding: 0 !important;
    vertical-align: middle;
    color: white;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.table-cell-count-description {
    width: 25px;
    height: 21px;
    border: 1px solid lightgrey;
    padding: 0 !important;
    vertical-align: middle !important;
    color: black;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-size: 11px;
    font-weight: 700;
}

.div-count-description {
    margin-bottom: -7px;
    transform: translate(0px, 0px) rotate(270deg);
    -ms-transform: translate(0px, 0px) rotate(270deg);
    -webkit-transform: translate(0px, 0px) rotate(270deg);
    font-weight: bold;
}

.clickable-item {
    cursor: pointer
}

#getVacationSpinner {
    width: 100px; position: absolute; display: none; margin-left: 75px; margin-top: 15px; background: white;
}

.uae-description-modal-header {
    padding: 8px
}

.uae-description-modal-title {
    text-align: center;
}

.uae-description-modal-body {
    padding: 10px;
}

#vacationButtons {
    padding-top: 5px;
}

.filters-span {
    color: #000;
}

.advancedAbsencesListViewFilters {
    width: 98%;
    height: 24%;
    border: 3px solid white;
    padding: 5px;
    margin: 5px;
}

.scrollable-left-block {
    float: left;
    width: 135px;
    height: 600px;
    overflow: hidden;
    border-right: 1px solid lightgrey;
}

.dropdown-bold, .dropdown-bold option {
    font-weight:bold;
}

.filter-uae-label {
    color: white;
    line-height: 22px;
}

.filter-item-uae {
    background-color: #bd1711;
}

.filter-uae-available { //available
    background-color: #00bd13;
}

.filter-uae-not_wanted { //not_wanted
    background-color: #bd6a24;
}

.filter-uae-not_available { //not_available
    background-color: #bd1711;
}

.filter-uae-vacation { //vacation
    background-color: #80bf27;
}

.filter-uae-priority_off { //priority_off
    background-color: #9E9E9E;
}

.filter-uae-training { //training
    background-color: #AB47BC;
}

.filter-uae-illness { //illness
    background-color: #0277BD;
}

.filter-uae-free_time_comp { //free_time_comp
    background-color: #009688;
}

.filter-uae-off_duty { //off_duty
    background-color: #2196F3;
}

.filter-uae-part_time_free { //part_time_free
    background-color: #795548;
}

.filter-uae-school { //school
    background-color: #9aa8bd;
}

.filter-uae-flexible { //flexible
    background-color: #a07ebd;
}

.filter-uae-flexible1 { //flexible1
    background-color: #a07ebd;
}

.filter-uae-flexible2 { //flexible2
    background-color: #a07ebd;
}

.filter-uae-sick_with_kid {
    background-color: #bd9b4b;
}

.filter-uae-illness_part_day {
    background-color: #bd72a0;
}

.filter-uae-illness_after_6_weeks {
    background-color: #41bdad;
}

.filter-uae-half_day_vacation {
    background-color: #bd2993;
}

.filter-uae-unpaid_vacation {
    background-color: #75bd07;
}

.filter-uae-half_schoolday {
    background-color: #bd8f67;
}

.filter-uae-maternity_leave {
    background-color: #324cbd;
}

.filter-uae-parental_leave {
    background-color: #aebd17;
}

.filter-uae-childcare_leave {
    background-color: #bd6b1b;
}

.filter-uae-special_leave {
    background-color: #297301;
}

#transfer-hours-table td, #transfer-hours-table th{
    text-align: center;
}
.uae-user-not_available { //not_available
    background-color: #ed6102;
    color: white;
}

.print_vacation_form {
    &:hover {
        color: #0293ed;
        cursor: pointer;
    }
}


html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.absenceView.cell {
    border-top: 1px solid black;
    border-right: 1px solid black;
}

.absenceView.cell.danger {
    background-color: #f2dede;
}

#absenceView {
    position: relative;
    width: 7700px;
    height: 2300px;

    .active {
        background: #f5f5f5;
    }

    .warning{
        background: #fcf8e4;
    }

    .transparent {
        background: #ffffff;
    }

    .highlighted-user {
        background-color: #e8e8e8 !important;
    }
}

.pane {
    width: auto;
    float: left;
    height: 100%;
}

.cellBorders {
    border-top: 1px solid black;
    border-right: 1px solid black;
}

#vertical_text{
    width: 80px;
    -ms-transform:rotate(270deg); /* IE 9 */
    -moz-transform:rotate(270deg); /* Firefox */
    -webkit-transform:rotate(270deg); /* Safari and Chrome */
    -o-transform:rotate(270deg); /* Opera */
    text-align: left;
    font-size: 12px;
    transform-origin: 45px 33px;
    -moz-transform-origin: 45px 33px;
    -webkit-transform-origin: 45px 33px;
}

.leftMenuAbsence{
    padding: 0px 6px 0px 0px;
    text-align: right;
    font-size: small;
}

table.tablesorter {
    background-color: #CDCDCD;
    margin:10px 0pt 15px;
    width: 100%;
    text-align: left;
}
table.tablesorter thead tr th, table.tablesorter tfoot tr th {
    background-color: #e6EEEE;
    border: 1px solid #FFF;
    padding: 4px;
}

table.tablesorter th.tablesorter-headerUnSorted:not(.sorter-false) {
    background-image: url("../images/bg.gif");
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
}

table.tablesorter tbody td {
    color: #3D3D3D;
    padding: 4px;
    background-color: #FFF;
    vertical-align: top;
}

table.tablesorter tbody tr.odd td {
    background-color:#F0F0F6;
}

table.tablesorter th.tablesorter-headerAsc:not(.sorter-false) {
    background-image: url("../images/asc.gif");
    background-repeat: no-repeat;
    background-position: center right;
}

table.tablesorter th.tablesorter-headerDesc:not(.sorter-false) {
    background-image: url("../images/desc.gif");
    background-repeat: no-repeat;
    background-position: center right;
}

table.tablesorter th.headerSortDown,
table.tablesorter th.headerSortUp {
    background-color: #8dbdd8;
}

[data-form-name="RolePermissionForm"] {
    .clickable-permission + label {
        max-height: 18px;
    }
}

.timesheet-row-bold {
    .first_name,.last_name {
        font-weight: bold;
    }
    .last_name:after {
        content: "*";
    }
}

.monthly-overview-edited-value {
    font-weight: bold;
    &:after {
        content: "*";
    }
}

.flexitime_value {
    white-space: nowrap;
}

.bootstrap-tagsinput input {
    border: none !important;
}

.disabledFilter {
    cursor: not-allowed;
    opacity: 0.5;
}

.delete-button-disabled {
    padding: 1em 0;
    font-style: italic;
}
.reject-box-container {
    text-align: center;
    textarea {
        min-width: 100%;
        max-width: 100%;
    }
    .btn {
        margin-top: 15px;
    }
    z-index: 301;
}
.reject-box-container-wrapper {
    background-color: rgba(200, 200, 200, 0.3);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 300;
}
.select2-container--bootstrap {
    margin: 0!important;
    .select2-selection--multiple {
        box-shadow: none;
        border-radius: 0;
        .select2-search--inline {
            .select2-search__field {
                line-height: 32px;
            }
        }
        .select2-selection__choice {
            border-radius: 2px;
            color: hsl(0, 0%, 20%);
            background-color: hsl(0, 0%, 90%);
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            border: none;
            padding: 0 0 0 5px;
        }
        .select2-selection__choice__remove {
            margin-right: 0;
            margin-left: 5px;
            background: #e6e6e6;
            &:hover {
                background-color: #FFBDAD;
                color: #DE350B;
            }
        }
    }
}
.select2-selection__choice__display {
    margin: 0!important;
    font-size: 12px;
    color: rgb(51, 51, 51);
}

.tooltip-icon-smaller {
    font-size: 10px !important;
    margin: 3px;
    float: right;
}

.pagination {
    margin: 5px 0;
}

#header-teaser-wrapper {
  width: 100%;
  text-align: center;

  #header-teaser-container {
    display: inline-block;
    background-color: #233c53;
    padding: 6px 15px;
    border-radius: 14px;
    margin: 9px 10px 9px 10px;
    visibility: hidden;
    color: #fff;
    cursor: pointer;
    height: 32px;
    box-sizing: border-box;
    vertical-align: middle;

    &:empty {
        display: none;
    }

    &:hover {
      filter: brightness(110%);
    }

    & button {
      filter: brightness(90%);
      &:hover {
        filter: brightness(110%);
      }
    }

  }
}


.setup-container {
  background-color: white;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto;

  .setup-client {
    background-color: whitesmoke;
    margin-top: 10px;
    width: 1000px;
    position: relative;
    left: 25%;

    .setup-client__form {
      padding: 10px;

      .setup-client_form-item {
        .setup-client_form-item__button {
          float: right;
          margin-right: 0;
        }
      }
    }

    .setup-client__customers {
      padding: 10px;

      .setup-client__customers-list {
        margin-top: 5px;
        height: 400px;
        overflow-y: auto;
        overflow-x: hidden;

        .setup-client__customers-list__item {
          margin-left: 0;
          background-color: white;
          box-shadow: #b1b1b1 1px 1px 2px;
          font-size: 14px;

          .setup-client__customers-list__item-dates {
            font-weight: bold;
          }
        }
      }
    }
  }

  .row + .row {
    margin-top: 8px;
  }
}


.setup-client__customers-title, .setup-client_form-title {
  font-weight: 500;
  color: #fff;
  background: #0293ed;
  font-size: 16px;
  margin-right: -5px;
  margin-left: -5px;
  text-align: center;
}

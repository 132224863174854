.matrix-shift-notifications__list  {
    overflow-y: auto;
    height: 300px;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-thumb {

        background-color: #999;
    }

    .row + .row {
        margin-top: 0;
    }

  margin-top: 10px;
}

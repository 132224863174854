div[data-react-shifts] {
  .spinner {
    font-size: 60px;
  }
}

.rotated {
	transform: rotate(270deg);
}

.btn {
	margin-right: 5px;
}

.btnModal {
  margin-top: 10px;
}

.btnModalCal {
  margin-top: 8px;
}

table.shiftPlanTable {
  max-width: 960px;
  min-width: 950px;
  width: 100%;
  border-spacing: 5px !important;
  border-collapse: separate !important;;

  thead td {
    font-weight: bold;
    text-align: center;
  }

  tbody td {
    text-align: right;
  }

  thead td:nth-child(1) {
    width: 60px;
  }

  thead td:nth-child(2) {
    width: 60px;
  }

  thead td:nth-child(3) {
    width: 120px;
  }

  thead td:nth-child(4) {
    width: 200px;
  }

  thead td:nth-child(5) {
    width: 90px;
  }

  thead td:nth-child(6) {
    width: 90px;
  }

  thead td:nth-child(7) {
    width: 200px;
  }

  thead td:nth-child(8) {
    width: 60px;
  }

  tr.emptyUser {
    .emptyIcon {
      display: block;
    }
    .infoIcon {
      display: none;
    }
  }

  td.group {
    vertical-align: middle;
    color: white;
    text-align: center;
  }

  td.group.calendarWeek {
    background-color: dodgerblue;
  }

  td.group.day {
    background-color: #FF8E0C;
  }

  td.group.location {
    background-color: dodgerblue;
  }
  
  .plaintext {
    font-size: 14px;
    vertical-align: middle;
    text-align: center;
  }
  
  .workplace-label {
    font-size: 12px;
    text-align: center;
    background-color: white;
    color: #6a6674;
    line-height: 25px;
  }
  
  .assignable-users-info {
    position: absolute;
    line-height: 27px;
    margin: 0px 0 0 175px;
    padding: 0 4px;
    background-color: lightblue;
    border-radius: 5px;
    color: black;
    width: 34px;
  }
  
  td.workplace-column, td.user-column, td.location-column {
    select {
      width: 100%;
    }

    li {
      text-align: left;
      position: relative;

      &.unassignable {
        background-color: #ffcccc;
      }

      &.weeklyDayLess i.weeklyDay {
        background-image: url('../images/shift-list/t_light_green.png');
      }

      &.weeklyDayMore i.weeklyDay {
        background-image: url('../images/shift-list/t_red.png');
      }

      &.weeklyDayOk i.weeklyDay {
        background-image: url('../images/shift-list/t_dark_green.png');
      }

      &.weeklyHourLess i.weeklyHour {
        background-image: url('../images/shift-list/s_light_green.png');
      }

      &.weeklyHourMore i.weeklyHour {
        background-image: url('../images/shift-list/s_red.png');
      }

      &.weeklyHourOk i.weeklyHour {
        background-image: url('../images/shift-list/s_dark_green.png');
      }

      .weeklyHour, .weeklyDay {
        position: absolute;
        height: 17px;
        width: 24px;
        top: 4px;
      }

      .weeklyDay {
        right: 2px;
      }

      .weeklyHour {
        right: 27px;
      }
    }
  }

  .iconBar {
    position: relative;

    .emptyIcon, .removeIcon, .infoIcon {
      position: absolute;
      height: 25px;
      width: 25px;
      top: 0;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-transition: opacity 150ms ease-in-out;
    }

    .emptyIcon {
      background-color: #ff9999;
      left: 30px;
      display: none;
      i {
        left: 3px;
      }
    }

    .infoIcon {
      background-color: #0293ed;
      left: 30px;
      cursor: pointer;
      i {
        left: 9px;
      }
    }

    .removeIcon {
      background-color: #F3966F;
      left: 0;
      cursor: pointer;
      i {
        left: 5px;
      }
    }

    .weeklyDayIcon, .weeklyHourIcon {
      position: absolute;
      height: 25px;
      width: 25px;
    }

    .weeklyDayIcon {
      left: 60px;
    }

    .weeklyHourIcon {
      left: 90px;
    }

    &.weeklyDayLess .weeklyDayIcon {
      background-image: url('../images/shift-list/t_light_green_square.png');
    }

    &.weeklyDayMore .weeklyDayIcon {
      background-image: url('../images/shift-list/t_red_square.png');
    }

    &.weeklyDayOk .weeklyDayIcon {
      background-image: url('../images/shift-list/t_dark_green_square.png');
    }

    &.weeklyHourLess .weeklyHourIcon {
      background-image: url('../images/shift-list/s_light_green_square.png');
    }

    &.weeklyHourMore .weeklyHourIcon {
      background-image: url('../images/shift-list/s_red_square.png');
    }

    &.weeklyHourOk .weeklyHourIcon {
      background-image: url('../images/shift-list/s_dark_green_square.png');
    }


    i {
      position: absolute;
      color: #fff;
      font-size: 20px;
      top: 2px;
    }

    .infoContainer {
      width: 300px;
      right: -30px;
      top: 50px;
      text-align: left;
      td {
        text-align: left;
      }
      .triangle {
        top: -17px;
        right: 30px;
      }
    }
  }
}

div.shiftPlanButtons {
  position: relative;

  .refresh-waiting {
    font-size: 18px;
    display: none;
    &.refreshing {
      display: inline;
    }
  }

  .addContainer {
    left: 86px;
    top: 50px;
    width: 550px;

    .triangle {
      left: 30px;
    }
  }

  .statisticContainer {
    left: 0;
    top: 50px;
    width: 1200px;

    .triangle {
      left: 610px;
    }
  }

  .legendContainer {
    left: 90px;
    top: 50px;
    width: 500px;

    .triangle {
      left: 400px;
    }

    table {
      td:nth-child(1) {
        width: 50px;
      }
    }
  }
}

div.shiftListContainer {
  &.close {
    display: none;
  }

  position: absolute;
  z-index: 120;
  padding: 10px;
  width: 550px;
  background-color: #fff;
  border: 2px solid dodgerblue;
  border-radius: 4px;
  right: -30px;
  top: 50px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-transition: opacity 150ms ease-in-out;
  text-align: left;

  .triangle {
    position: absolute;
    top: -17px;
    right: 30px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent dodgerblue transparent;
  }
}

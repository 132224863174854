@charset "UTF-8";
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 06.08.2014, 14:52:01
    Author     : falkmichel  lalala
    Author     : falkmichel wartet auf das css
*/
/* Header, Navigation und ContentWrapper */

@import 'utils';

.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

.badge {
    background-color: red;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    background-color: #dc0d17;
    background-image: linear-gradient(#fa3c45, #dc0d17);
    color: #fff;
    padding: 1px 4px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
    font-family: 'helvetica neue', helvetica, arial, sans-serif;
    font-size: 11px;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 1.3;
}

.badgeGrey {
    background-color: #999999;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    color: #fff;
    padding: 1px 4px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
    font-family: 'helvetica neue', helvetica, arial, sans-serif;
    font-size: 11px;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 1.3;
}

.badgeBlue {
    background-color: #3a87ad;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    color: #fff;
    padding: 1px 4px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
    font-family: 'helvetica neue', helvetica, arial, sans-serif;
    font-size: 11px;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 1.3;
}

.badgeGreen {
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .7);
    background-color: green;
    color: #fff;
    padding: 1px 4px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .4);
    font-family: 'helvetica neue', helvetica, arial, sans-serif;
    font-size: 11px;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 1.3;
}

.wrapper, .content {
    width:100%;
    height: 100%;
    overflow: hidden;
}

.wrapper {
    background-color: white;
    @include respond-to(wide) {
        margin: 0px auto;
        border-left:1px solid $lightgrey;
        border-right:1px solid $lightgrey;

        &.login {
            width: 1440px;
        }
    }
}

body.body{
    font-family: "Roboto", sans-serif;
    background-image: url(../images/debut_light.png);
    overflow:hidden;
    color:$textColorDefault;
    &.shift-template-preview {
        background-image: none;
    }
}

body {
    &.iframe {
        .wrapper {
            header {
                display: none;
            }
            .mainNavigation {
                display: none;
            }
        }
        .contentWrapper {
            top: 0;
            left: 0;
            bottom: 0;
        }
    }
}

header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 300;
    overflow: visible;
    border-bottom:1px solid $lightgrey;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include respond-to(mobile)         { display: none; }
    @include respond-to(tablet-small)   { display: none; }
    @include respond-to(tablet-large)   { display: block; }
    @include respond-to(normal)         { display: block; }
    @include respond-to(wide)           { display: block; }

    .navbar {
        border: 0;
        background: white;
        border-bottom: 1px solid grey;
        border-radius: 0;

        .badge {
            position: absolute;
            top: -14px;
            left: 16px;
        }

        .dropdown-submenu {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            top: 100%;
			right: 0;
			width: 525px;
			transform: translateY(-10px);
			transition: all 0.2s ease 0s, visibility 0s linear 0.2s;
			background: #fff;
            border: 2px solid rgba(0, 0, 0, .2);
            -webkit-box-shadow: 0 2px 10px rgba(0,0,0,.2);
		    box-shadow: 0 2px 10px rgba(0,0,0,.2);
		    outline: none;
		    -webkit-border-radius: 2px;
		    border-radius: 2px;
		    -webkit-user-select: text;

            h2 {
                font-size: 12px;
                margin-left: 10px;
                font-weight: bold;
            }

            h3 {
                font-size: 11px;
                text-align: center;
                font-weight: bold;
                margin-top: 10px;
            }

            h4 {
                font-size: 11px;
                margin-left: 10px;
                font-weight: bold;
                color: #2979FF;
            }

            .list-group {
                margin-bottom: 10px;
                border: 1px solid #ddd;
                overflow-y: scroll;
                height: 400px;
            }

            .list-group-item-text {
                margin-left: 10px;
                .actions {
                    margin-top: 5px;
                    .btn {
                        font-weight: 600;
                        border-radius: 3px !important;
                        &-danger {
                            border-color: #d43f3a!important;
                            background: #d43f3a!important;
                        }
                    }
                }
            }

            .message-date {
                font-size: 11px;
                color: black;
                margin-left: 5px;
                line-height: 1.3;
            }
        }

        .dropdown-parent.in .dropdown-submenu {
            visibility: visible; /* shows sub-menu */
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0s; /* this removes the transition delay so the menu will be visible while the other styles transition */
        }

        #btn-messages {
            margin-right: 15px;
        }

        .list-group-item.unread {
            background: #DAF1FF;
        }

        .date {
            color: #9C9C9C;
            float: left;
            padding-left: 16px;
            font-size:13px;
        }

        .saving {
            float:left;
            margin-left:15px;
            position:relative;

            .waiting {
                display:none;
                color: $colorPrimary;
            }

            .complete {
                display:none;
                color: $colorSuccess;
            }
        }

        .user {
            color: #9C9C9C;
            float: left;
            padding-right: 8px;
            font-size: 13px;
        }

        .idle {
            color: #9C9C9C;
            float: left;
            padding-right: 8px;
            font-size: 13px;
        }

        .saving, .saved, .error {
            display: none;
            i.fa {
                position: absolute;
                top: 6px;
                font-size: 30px;
            }
            span.label {
                font-size: 14px;
                color: $textColorDefault;
                font-weight: normal;
                margin-left: 40px
            }
        }
        .top-spinner {
            i {
                vertical-align: middle;
                &.fa {
                    font-size: 30px;
                }
            }
            span {
                vertical-align: middle;
                &.label {
                    font-size: 14px;
                    color: $textColorDefault;
                    font-weight: normal;
                }
            }
        }

        .saving i.fa  {
            color: grey;
            top: -5px;
        }
        .saved i.fa  {
            color: green;
        }
        .error i.fa  {
            color: red;
            padding-left: 4px;
        }
    }

    .wrapperHeading {
        position: absolute;
        width: 100%;
        height: 60px;
        text-align: center;
    }

    .wrapperDateTime {
        width: auto;
        height: 15px;
        line-height: 16px;
        margin:20px 22px;
        position: absolute;
        left: 0;
        font-size:15px;
        @include respond-to(tablet-large)   { margin: 18px; }
        @include respond-to(tablet-small)   { display:none}

    }

    .wrapperUser {
        height:60px;
        width: auto;
        position: absolute;
        right: 0;
        padding:15px 0;
        margin: 0px 10px;
        @include respond-to(tablet-large)   { padding: 11px 0; }
        @include respond-to(tablet-small)   { padding: 11px 0; }

        .user {
            color: #9C9C9C;
            float: left;
            padding-right: 8px;
            font-size: 13px;
            margin:6px 7px;
        }

    }
} /* header Closing Tag */


/*  Hauptnavigation */
.contentWrapper {
    position: absolute;
    overflow: hidden;

    @include respond-to(mobile) {
        top: 50px;
        left: 0;
        right: 0;
        bottom: 25px;
    }
    @include respond-to(tablet-small) {
        top: 0px;
        left: 50px;
        right: 0;
        bottom: 25px;
    }
    @include respond-to(tablet-large) {
        top: 50px;
        left: 75px;
        right: 0;
        bottom: 25px;
    }
    @include respond-to(normal) {
        top: 50px;
        left: 90px;
        right: 0;
        bottom: 25px;
    }
    @include respond-to(wide)   {
        top: 50px;
        left: 90px;
        right: 0;
        bottom: 25px;
    }

    .mainContentWrapper, .mainFormWrapper {
        position:absolute;
        top: 0px;
        bottom:0px;
        right: 0px;
        text-align: center;
        padding:  10px;
        overflow: auto;
        background-color: #daf1ff;
    }

    .mainContentWrapper {
        left: 500px;

        -webkit-transition: top ease-in-out 700ms;
        -moz-transition: top ease-in-out 700ms;
        transition: top ease-in-out 700ms;
        z-index: 111;

        @include respond-to(mobile) {
            &.open {
                top: 0;
                -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
                -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
            }
        }
        @include respond-to(tablet-small) {

            &.open {
                top: 0;
                -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
                -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
                box-shadow: 0 1px 10px rgba(0, 0, 0, 0.7);
            }
        }
    }

    .mainFormWrapper {
        /*top: 100%;*/
        background-color: transparent;
        padding:0;
        overflow: hidden;

    }

    .mainFormBackground, .mainFormContent {
        position:absolute;
        top: 0px;
        bottom:0px;
        right: 0px;
        left: 0px;
        z-index: 280;
    }

    .mainFormBackground {
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0.95;

    }

    .mainFormContent {
        text-align: center;
        padding: 5%;
        overflow: auto;
    }

}

.flashMessagesContainer {
    position:fixed;
    top:10%;
    left:50%;
    margin-left:-250px;
    width:500px;
    z-index:10000;

    /* class="flashMessage */
    .flashMessage {
        padding:12px 20px;
        text-align:center;
        color:$colorWhite;
        background:$colorPrimary;
        font-weight:400;
        font-size:16px;
        margin-bottom:10px;
        display:none;
        @include box-shadow(0 0 3px -1px #646464);

        &.status0 {
            background:$colorError;
        }

        &.status1 {
            background:$colorPrimary;
        }

        &.status2 {
            background:$colorSuccess;
        }
    }
}

.overlayContainer {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:280;
    display:none;
    overflow: auto;

    &[data-react-overlay="fullscreen"] {
        background:white;
        display:block;
        z-index:350;

        &>* {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
        }
    }

    &[data-react-overlay="mainContent"] {
        position: fixed;
        left: 400px;
    }

    .contentOverlay {
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        background:#E8E8E8;
        z-index:1;
    }

    .contentOverlayClose {
        position:absolute;
        top:0;
        left:0;
        text-align:center;
        border-right: 1px solid $lightergrey;
        border-bottom: 1px solid $lightergrey;
        color: $colorPrimary;
        cursor: pointer;
        font-size: 14px;
        width: 40px;
        height: 40px;
        font-size:25px;
        padding-top:6px;
        background:$facegrey;
    }

    .overlay {
        position:absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        z-index:350;
        background:rgba(0,0,0,0.5);
    }

    .contentOverlayContentContainer {
        width:100%;
        margin:0 auto;
        padding-top:50px;
        padding-left:50px;
        background:#E8E8E8;

        @media screen and (max-width: $break-wide) {
            padding-left:25px;
        }

        @media screen and (max-width: $break-large) {
            padding-left:10px;
        }
    }

    .overlayContentContainer {
        width:100%;
        margin:0 auto;
    }
}

.fill {
    min-height: 100%;
    height: 100%;
}

.contentWrapper .overlayContainer {
    z-index: 280;
}

.shiftoverview {
    .mainContentWrapper {
        left:0;
    }
}

.spinnerWrapper {
    text-align:center;
    color:$facegrey;
    font-size:80px;
    position:absolute;
    width:100%;
    height: 100%;
    background-color: white;

    &.content {
        font-size:70px;
    }

    &.mainContent {
        font-size:60px;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -150px;
        margin-top: -180px;
    }

}

.calendar {
    .mainContentWrapper {
        @include respond-to(mobile) {
            width: 100%;
            left: 0px;
        }
        @include respond-to(tablet-small) {
            width: 100%;
            left: 0px;
        }
        @include respond-to(tablet-large) {
            left: 200px;
        }
        @include respond-to(normal) {
            left: 250px;
        }
        @include respond-to(wide) {
            left: 350px;
        }
    }
}


.mainContentWrapper,.mainFormWrapper {
    @include respond-to(mobile) {
        top: 100%;
        height: 100%;
        bottom: auto;
        left: 0;
    }
    @include respond-to(tablet-small) {
        top: 100%;
        height: 100%;
        bottom: auto;
        left: 0;
    }
    @include respond-to(tablet-large) {
        left: 300px;
    }
    @include respond-to(normal) {
        left: 350px;
    }
    @include respond-to(wide) {
        left: 400px;
    }
}

.mainContentWrapper,.mainFormWrapper {
    @include respond-to(mobile) {
        top: 100%;
        height: 100%;
        bottom: auto;
        left: 0;
    }
    @include respond-to(tablet-small) {
        top: 100%;
        height: 100%;
        bottom: auto;
        left: 0;
    }
    @include respond-to(tablet-large) {
        left: 300px;
    }
    @include respond-to(normal) {
        left: 350px;
    }
    @include respond-to(wide) {
        left: 400px;
    }
    .singleBlockWrapper {
        float:right;
    }
}


.mainFormWrapper {
    -webkit-transition: top 0.5s ease-in-out;
    -moz-transition: top 0.5s ease-in-out;
    -o-transition: top 0.5s ease-in-out;
    transition: top 0.5s ease-in-out;
}




.contentOnlyWrapper {
    position: absolute;
    overflow: hidden;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 25px;
    @include respond-to(mobile) {top: 50px;}
    @include respond-to(tablet-small) { top: 50px; }
    @include respond-to(tablet-large) { top: 50px; }
    @include respond-to(normal) { top: 50px; }
    @include respond-to(wide)   { top: 50px; }

    .mainContentOnlyWrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        text-align: center;
        padding:  10px;
        overflow: hidden;
        background-color: #daf1ff;
        background-size: cover;
        background-image: url("../images/background-login-jan2021.jpg");
        background-repeat: no-repeat;
        background-position: center top;
    }
}

.contentAjaxWrapper , .contentOverlayer,.contentPassivOverlayer{
    position: absolute;
    width: 100%;
    top:0;
    bottom:0px;
}

.contentOverlayer{
    width: auto;
    right:0;
}


.singleBlockWrapper, .singleBlockFormWrapper {
    &.sixColums  {
        width: 16.6%;
    }

    &.fiveColums  {
        width: 20%;
    }

    &.fourColums  {
        width: 25%;
    }

    &.threeColums  {
        width: 33.3%;
    }

    &.twoColums {
        width: 50%
    }
}

.singleBlockFormWrapper {
    float: left;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow:auto;
    border-radius:4px;
}

footer {

    /*@include respond-to(mobile) {display: none;}  */

}

/* Sliderbar höhe */

.ui-slider-horizontal {
    height: 5px;
}
/* Sliderbar colortrack */
.ui-widget-header {
    background: #0293ed;
}


/* Slider Button Position */
.ui-slider-horizontal .ui-slider-handle {
    top: -5px;
}
/* Slider Button größe */
.ui-slider .ui-slider-handle {
    height:13px;
    width:13px;
    &.ui-state-hover {
        border:1px #0293ed solid;
        background:#bde6ff;
    }
    &.ui-state-focus {
        border:1px #0293ed solid;
        background:#bde6ff;
    }
    &.ui-state-active {
        border:1px #0293ed solid;
    }
}
p {
    padding-right: 35px;
    input {
        color:#0293ed;
        font-size:10px;
    }
}
.slider-range-max.ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    width: 90%;
}
#scrollUp {
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background: #555;
    color: #fff;
    z-index:99999;

}

.overlayerDelete {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    z-index: 1000;
}


/*
.newDateLayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    -webkit-transition: background 0.5s ease-in-out,left 0.5s ease-in-out,right 0.5s ease-in-out;
    -moz-transition: background 0.5s ease-in-out,left 0.5s ease-in-out,right 0.5s ease-in-out;
    -o-transition: background 0.5s ease-in-out,left 0.5s ease-in-out,right 0.5s ease-in-out;
    transition: background 0.5s ease-in-out;

}


.navchild, .listChild {
    width: 100%;
    float: left;
    border-bottom: 1px #e8e8e8 solid;
    cursor: pointer;
    font-weight: 400;
    color: #646464;
    /* box-sizing: border-box;
    display:block;
}

.listChild {
    padding: 10px 0;
    border-right:1px transparent solid;
    overflow: hidden;
}

*/

.promptWrapper {

    background-color:$colorWhite;
    left: 50%;
    margin-left: -170px;
    position: fixed;
    top: 15%;
    width: 340px;

    .inner {
        padding:15px 25px;
    }

    .inputWrap {
        margin:7px 0;

        input {
            &.inputfield {
                border:none;
                border-radius:0;
                box-shadow:none;
                display:block;
                float: left;
                font-size: 16px;
                line-height:1.4em;
                padding:4px 7px;
                width:100%;
                color:$textColorDefault;
                background:#eee;
                @include respond-to(mobile){
                    width:100%;
                    position: absolute;
                    line-height:25px;
                    height:50px;
                    background-color:transparent;
                    padding-left:51px;
                    margin:0;

                }
            }

            &:focus {
                color:$colorPrimary;
                border-bottom-color:$colorPrimary;
            }
        }

        &.hasIcon {
            position:relative;

            input {
                &.inputfield {
                    padding-left:40px;
                }
            }
        }

        @include respond-to(mobile){
            margin: 0;
            width:100%;
            position:relative;
            height:50px;
            &:last-child{
                border-bottom:none;
            }
        }

        .inputIcon {
            font-size:21px;
            position:absolute;
            left:5px;
            top:4px;
            color:$textColorDefault;

            &.passwordIcon {
                left:7px;
                font-size:23px;
            }
        }



        label {
            color:$colorPrimary;
            display: block;
            float: left;
            width:40%;
        }
    }

    .options {
        margin:15px 0 10px;

        @include respond-to(mobile) {
            margin: 9px 15px 5px;
        }

        .radioCheck {
            @include respond-to(mobile) {
                margin: 0px 5px 0px;
                font-size:12px;
            }
            .rememberMeCheckbox {
                float: left;
            }
            .rememberMeLable {
                color: $textColorDefault;
                float:left;
                padding-left:20px;
                cursor:pointer;
                @include respond-to(mobile) {
                    color:$colorWhite;
                    padding:0;
                }
            }
        }
    }

    .titleContainer {
        width:100%;
        height:40px;
        line-height:30px;
        background-color:$colorPrimary;
        color:$colorWhite;
        text-align:center;
        font-size:$fontsizeLarge;
        padding:5px;
        text-transform: uppercase;

        @include respond-to(mobile) {
            background-color: transparent;
            color:$colorPrimary;
            width:100%;
            margin:0 auto;
            border:none;

        }
        .title {
            @include respond-to(mobile) {
                display:none;
            }
        }
    }
}

[type="submit"],
[type="button"] {
    &.buttonPrimary {
        border-radius:4px;
        box-shadow:0 1px 1px 0 rgba(0, 0, 0, 0.1) inset;
        font-size:16px;
        line-height:30px;
        margin:8px 0;
        padding:4px 7px;

        &.full {
            width:100%;
        }
    }

    &.buttonSecondary {
        width:32%;
        border-radius:4px;
        box-shadow:0 0px 1px 0 rgba(0, 0, 0, 0.1) inset;
        font-size:16px;
        line-height:30px;
        margin:8px 0;
        padding:2px 7px;
        @include linear-gradient(#ddd, #c3c3c3);
    }
}

.text-center {text-align:center;}
.text-left {text-align:left;}
.text-right {text-align:right;}

.markAllMessageRead{
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: #2979ff;
    }
}

.refresh_notifications {
    margin-left: 390px ;
    cursor: pointer;
    &:hover {
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;
    }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 1200px) {
    .navbar-text.calendar-tipp {
        display: none; /* hide the tipp if the window is small */
    }
}
.align-items-center {
    display: flex;
    align-items: center;
}
.dropdown-wrapper {
    position: relative;
    z-index: 9;
}
[class*=fa-info-circle][title],[class*=glyphicon-info-sign][title] {
    cursor: pointer;
    font-size: 17px;
    color: #0080CF;
    &.white {
        color: #fff
    }
}
.info-popover {
    max-width: 550px;
}
.colored-workplace-example {
    background: url("../images/colored-workplace.jpg") center center no-repeat;
    padding-top: 15px;
    display: block;
    height: 300px;
}
.visible-for-planerio-admin {
    background: #5bc0de;
    color: #334C61;
    padding: 2px;
    margin-left: -2px;
    margin-right: -2px;
    fieldset {
        background: none;
    }
}
.full-width {
    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 20px;
        .modal-content {
            height: auto;
            min-height: 100%;
            border-radius: 0;
        }
    }
}

body.navigation-v2 {

    // hide header
    header {
        display: none !important;
    }

    // hide vertical navigation (sidebar)
    nav.mainNavigation {
        display: none !important;
    }

    // hide footer
    footer.footerBar {
        display: none !important;
    }

    // hide symfony console
    > .sf-toolbar {
        display: none !important;
    }

    main > .contentWrapper {
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;

        .mainContentWrapper {

            &.planning .overlayContainer {
                left: 360px !important;
            }

        }
    }
}

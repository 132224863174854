.shiftHistoryNotificationList {
    padding-top: 15px;
    font-weight: bold;
    margin-left: 10px;
}
.matrix-shift-notifications__item  {
    margin-top: 10px;
    border-bottom: 2px solid white;
    margin-right: 10px;
    margin-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #F4F5F7;
    .matrix-shift-notifications__info {
        padding-left: 20px;
    }
}

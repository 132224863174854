.onboarding {
  margin-right: 5px;
  text-align: left;

  .onboarding-reload {
    padding-right: 10px;
  }
}

.customer_timezone__select, .customer_country__select {
    width: 100%;
}

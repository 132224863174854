.fc-sat {
  background-color: #ECECEC;
}

.fc-sun {
  background-color: #ECECEC;
}

.calendar-view .fc-view {
  background-color: #fff;
}
.div-center {
  width:800px;
  margin:0 auto;
}

.calendar-explanation {
  margin-top: 20px;

  table {
    border-spacing: 5px !important;
    border-collapse: separate !important;;
	width: auto;
  }

  .color-square-vacation {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #80bf27;
  }

  .color-square-illness {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #0277BD;
  }

  .color-square-training {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #AB47BC;
  }

  .color-square-free-time-comp {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #009688;
  }

  .color-square-off-duty {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #2196F3;
  }

  .color-square-part-time-free {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #795548;
  }

  .color-square-priority-off {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #9E9E9E;
  }
  .color-square-not-available {
    width: 20px;
    height: 20px;
    border-radius: 50% / 10%;
    background-color: #ed6102;
  }

  .label {
    text-align: left;
    vertical-align: middle;
    display: block;
  }
}

.calendarPublicHoliday {
  background: rgba(0, 0, 255, 0.19);
}

// It is important to have files without extensions (.css, .scss)  in order to keep the right order
// see https://github.com/symfony/webpack-encore/issues/283#issuecomment-373311907
@import "normalize";
@import "~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker";
@import "~jquery-ui/themes/base/base.css"; // it doesn't work without .css because of an error in the module
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/v4-shims";
@import "~bootstrap/dist/css/bootstrap";
@import "~bootstrap-tagsinput/dist/bootstrap-tagsinput";
@import "~bootstrap-slider/dist/css/bootstrap-slider";
@import "~spectrum-colorpicker/spectrum";
@import "~animate.css/animate";
@import "calendar-heatmap";
@import 'app';
@import 'fullcalendar';
@import 'dataTables.fixedColumns';
@import 'globalStyle';
@import 'technicals';
@import 'subNav';
@import 'homeStyle';
@import 'settingsStyle';
@import 'logreg';
@import 'calendarStyle';
@import 'notifications';
@import 'employee';
@import 'shiftplanning';
@import 'periodoverview';
@import 'notifications';
@import 'devFeatures';
@import 'administration';
@import 'forms';
@import 'shiftPlan';
@import 'enrollment';
@import 'mainNavigation';
@import 'matrix';
@import 'selectList';
@import 'Onboarding';
@import 'FilterBar';
@import 'DropDownItem';
@import 'OnboardingPreferenceItem';
@import 'InputSubmit';
@import 'OnboardingReload';
@import 'SensitiveDataButton';
@import 'CheckboxWithLabel';
@import 'MatrixShiftNotifications';
@import 'MatrixShiftNotificationsItem';
@import 'SetupClient';
@import './component/datetime';
@import './component/matrix/index';
@import './component/button/index';
@import './component/header-teaser/index';
@import "~select2";
@import "~select2-bootstrap-theme/dist/select2-bootstrap.css";

$matrix-week-background: repeating-linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.05),
                rgba(0, 0, 0, 0.05) 1px,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 0) 12px
);

$matrix-day-background: repeating-linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.03),
                rgba(0, 0, 0, 0.03) 300px,
                white 300px,
                white 1001px,
                rgba(0, 0, 0, 0.03) 1000px,
                rgba(0, 0, 0, 0.03) 1200px
);

$matrix-day-row-background: repeating-linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.05),
                rgba(0, 0, 0, 0.05) 1px,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 0) 50px
);

/*
Copyright (C) 2014 falkmichel

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/  
/* 
    Created on : 12.11.2014, 14:17:10
    Author     : falkmichel
*/

@import 'utils';

.mainContentWrapper {  
    &.administration {
        left:400px; 

        @include respond-to(mobile) { 
            top: 100%;
            height: 100%;
            bottom: auto;
            left: 0;
        }
        @include respond-to(tablet-small) { 
            top: 100%;
            height: 100%;
            bottom: auto;
            left: 0;
        }
        @include respond-to(tablet-large) { 
            left: 300px;
        }
        @include respond-to(normal) { 
            left: 350px;
        }
        @include respond-to(wide) { 
            left: 350px;
        }
    }
}

li.addToList, li.forAdd {

    .personForm.add {
        padding:0;
        .span {
            padding:0;
        }

        .smallInput {
            float:left;
            width:49%;
            background:white;
            background-color:white;  
            margin: 6px 0;
            height: 30px;
            border: 1px lightgrey solid;
            background-color:white;
            border-radius:4px;
            font-size: 14px;
            padding: 0 15px;

            &.em {
                width:100%;
            }
            &.left {
                float:left;
            }
            &.right {
                float:right;
            }
            &.single {
                width:100%;
            }
        }


    }
}
.regButtonAdmin {
    &.right {
        float:right;
    }
    &.left {
        float:left;
    }
}
.fallback-explanation {
    display: block;
    margin-bottom: 15px;
    .rules-below {
        display: block;
        padding: 10px 0;
    }
}

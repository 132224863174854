.selectList {

  position: relative;
  width: 100%;

  button {
    width: 100%;
    background: #fff;
    color: #6a6674;
    line-height: 25px;
    font-size: 14px;
    padding: 0 10px;
    cursor: pointer;
    border: 1px solid #eee;
  }

  .choices {
    width: 100%;
    display: none;
    position: absolute;
    margin: 0;
    background: #dddddd;
    z-index: 100;

    li {
      width: 100%;
      background: #eee;
      line-height: 25px;
      font-size: 14px;
      padding: 0 10px;
      cursor: pointer;
    }

    li:hover {
      background: #aaa !important;
    }
  }
}

.nUserFilter {
  display: none;
}

.userFilter {
  display: table-row;
}

.typeFilter {
  display: table-row;
}

.nTypeFilter {
  display: none;
}

.nUserFilter {
  &.nTypeFilter {
    display: none !important;
  }
}

.nUserFilter {
  &.typeFilter {
    display: none !important;
  }
}

.userFilter {
  &.nTypeFilter {
    display: none !important;
  }
}

.userFilter {
  &.typeFilter {
    display: table-row !important;
  }
}

.nTypeFilter {
  &.nUserFilter{
    display: none !important;
  }
}

.typeFilter{
  &.nUserFilter{
    display: none !important;
  }
}

.nTypeFilter{
  &.userFilter{
    display: none !important;
  }
}

.typeFilter {
  &.userFilter{
    display: table-row !important;
  }
}
/*
Copyright (C) 2014 jonathanberroth

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
/* 
    Created on : 12.11.2014, 16:48:20
    Author     : jonathanberroth
*/

@import 'utils';

$bluecolor: #0293ed;
$greencolor: #1cb07a; 
$lightgrey: #c6cacc; 
$black: #2e3133;
$whitesmoke: #fafafa;
$facegrey: #F3F3F3; 

.wrapperDevFeatures {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(25,25,25,.95);
    z-index: 99999;

}

.headLogo {
    display: block;
    padding-top: 5px;
}

.devFeaturesHeader {
    position: absolute;
    width: 100%;
    height: 60px;
    top:0;
    border-bottom: 1px solid grey
}

.devFeaturesContent {
    position: absolute;
    width: 100%;
    top: 61px;
    bottom: 81px;
}

.devFeaturesFooter {
    position: absolute;
    width: 100%;
    height: 80px;
    bottom: 0;
    border-top: 1px solid grey
}

.listDescription {
    border-bottom: 1px solid white;
    color: white;
    font-size: 19px;
    margin: 20px auto;
    padding: 5px;
    width: 94%;
}

.logListWrapper {
    width:100%;
    height:100%;
    overflow-y:scroll;

}

.logList {
    height: auto;
    margin: 20px auto;
    overflow: auto;
    text-align: center;
    width: 100%;
    z-index: 999999;

    .subChild {
        &.login {
            display: inline-block;
            height: auto;
            margin: 15px 0;
            min-width: 200px;

            .mainWrap {
                height: auto;
                margin: 0;
                padding: 5px 20px;
                text-align: center;
                width: auto;

                .profileCircle {
                    width:100px;
                    height:100px;
                    border:2px solid white;
                    border-radius:50%;
                    display:block;
                    margin:0 auto;
                }

                .userRow {
                    display: inline-block;
                    height: 20px;
                    margin-right: 25px;
                    margin-top: 20px;

                    .statusIcon {
                        background-color: orange;
                        border-radius: 50%;
                        float: left;
                        height: 10px;
                        margin-top: 3px;
                        width: 10px;

                        &.active {
                            background-color:$greencolor;
                        }
                    }
                    .listHeading {
                        color: white;
                        display: inline;
                        padding-left: 15px;
                        text-align: center;
                    }

                }
            }
        }
    }
    &.admin {

    }
    &.worker {

    }
    &.intern {

    }
}








/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 19.08.2014, 12:09:57
    Author     : falkmichel juchu
*/

@import 'utils';

/**
Override bootstrap's styles for small default buttons
 */
.btn {
    &.btn-default {
        &.btn-small {
            border: 0 none;
            background-image: linear-gradient(to bottom,  #0293ed 0%,#0074bd 100%);
            &:hover {
                background-image: linear-gradient(to bottom,  #0297f4 0%, #0079c5 100%);
                color: #fff;
            }
            &:focus, &:active {
                color: #fff;
                background-image: linear-gradient(to bottom,  #0079c5 0%, #0297f4 100%);
                @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15));
            }
        }
    }
}
button:not(._pendo-button, ._pendo-badge, .close), .button, .btn-default, [type="button"], [type="submit"] {
    @include linear-gradient(#0293ed, #0074bd);

    border: 0 none;
    text-decoration: none;
    cursor: pointer;
    font-weight:400;
}

.btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default {
    @include linear-gradient(#0293ed, #0074bd);
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default,
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    color: white;
}

//:not([data-action]) excludes datepicker plugin buttons
.btn:not(.datepickeraction), .btn-default, .fc-button {
    color: white;

    &:hover {
        @include linear-gradient(#0297f4, #0079c5);
        color: #fefefe;
    }

    &.active,
    &:active {
        @include linear-gradient(#0079c5, #0297f4);
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15));
        color: #fefefe;
        outline: none;
    }

    &:focus {
        color: #fefefe;
        outline: none;
    }

    &:active:focus {
        color: #fefefe;
        outline: none;
    }

    &.btn-red {
        @include linear-gradient(#e23939, #c93232);

        &:hover {
            @include linear-gradient(#ee3d3d, #d23535);
            color: white;
        }
    }

    &.btn-red:active {
        @include linear-gradient(#d23535, #ee3d3d);
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15));
    }

    &.btn-red:focus {
        outline: none;
        color: white;
    }

    &.btn-grey {
        @include linear-gradient(#eeeeee, #cbcbcb);
        color: black;
        border: 1px solid #cccccc;

        &:hover {
            @include linear-gradient(#e8e8e8, #d0d0d0);
            color: black;
        }
    }

    &.btn-grey:active {
        @include linear-gradient(#cbcbcb, #eeeeee);
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15));
    }

    &.btn-grey:focus {
        outline: none;
        color: black;
    }

    &.btn-small {
        @extend .btn, :not(.datepickeraction);
        padding: 2px 6px 3px 6px;
        line-height: 14px;
        font-size: 12px;
    }

    &.button {
        padding-top: 6px;
        padding-bottom: 4px;
    }
    &.btn-green {
        @include linear-gradient(#34c740, #2bae36);
    }

    &.btn-green:hover {
        @include linear-gradient(#37d143, #2eb73a);
        color: white;
    }

    &.btn-green:active {
        @include linear-gradient(#2eb73a, #37d143);
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15));
        outline: 0 none;
    }

    &.btn-green:focus {
        outline: 0 none;
        color: white;
    }

    &.btn-light-green {
        @include linear-gradient(#8ce196, #52b15e);
    }

    &.btn-light-green:hover {
        @include linear-gradient(#9df3a7, #52b15e);
        color: white;
    }

    &.btn-light-green:active {
        @include linear-gradient(#52b15e, #8ce196);
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15));
        outline: 0 none;
    }

    &.btn-light-green:focus {
        outline: 0 none;
        color: white;
    }

    &.btn-gray {
        @include linear-gradient(gray, gray);
        color: white;
    }
    &.btn-shadow {
        &.shadow-white {
            -webkit-box-shadow: 0px 0px 5px 0px rgba(255,255,255,1);
            -moz-box-shadow: 0px 0px 5px 0px rgba(255,255,255,1);
            box-shadow: 0px 0px 2px 0px rgba(255,255,255,1);
        }
    }
}

button::-moz-focus-inner, .btn-default:-moz-focus-inner, input::-moz-focus-inner {
    margin: 0;
    padding: 0;
    border-width: 0;
}


input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
[contenteditable="true"],
textarea {
    margin: 0;
    font-size: 12px;
    display: inline-block;
    line-height: 1.3;
    border: 1px $lightgrey solid;
    padding: 5px 7px 4px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-fit:border !important;
    -webkit-box-align: start;
    -webkit-border-fit: lines;
    -webkit-margin-collapse: discard;
    outline: 0 none;
    background: $colorWhite;
    vertical-align: top;
    min-width: 50px;
    width: 100%;
    font-weight:400;

    &[small-input] {
        width: auto;
    }

    &:hover:not([disabled], [readonly], :focus) {
        border: 1px $hoverBorderColor solid;
    }

    &:focus {
        border: 1px $colorPrimary solid;
        outline: 0 none;
    }
}

[contenteditable="true"].textarea,
textarea {
    -webkit-resize: none;
    -moz-resize: none;
    resize: none;
    min-width: 300px;
}

//input:invalid,
.invalid {
    border: 1px red solid;
}

select {
    font-size: 12px;
    line-height: 1.3;
    border: 1px #ccc solid;
    padding: 5px 23px 5px 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-fit: border !important;
    -webkit-box-align: start;
    -webkit-border-fit: lines;
    -webkit-margin-collapse: discard;
    outline: 0 none;
    background: #fff;
    margin: 0;
    background: #fff url("../images/menu.png") no-repeat scroll 100% 50%;

    &:hover:not([disabled], [readonly]) {
        cursor: pointer;
    }

    &:hover:not([disabled], [readonly], :focus) {
        border: 1px $hoverBorderColor solid;
    }

    option[disabled] {
        color: lightgrey;
    }
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

select:active {
    border: 1px #0293ed solid;
    outline: 0 none;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 4px rgba(2,147,237,0.5));
}

select:focus {
    border: 1px #0293ed solid;
    outline: 0 none;
    @include box-shadow(0 1px 1px rgba(0, 0, 0, 0.15), 0 0 4px rgba(2,147,237,0.5));
}

[disabled="true"] {
    opacity: 0.6;
    cursor: default;
    @include linear-gradient(#999, #797979);
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.15));
}

.adminButtonSpan{
    padding: 0 3%;
}

.w100, .w75, .w50, .w33, .w25, .w15 {
    display: block;
}

.w75, .w50, .w33, .w25, .w15 {
    @extend %clearfix;

    float: left;
}

.w100 {
    width: 100%;
}

.w75 {
    width: 75%;
}

.w50 {
    width: 50%;
}

.w50 select, .w100 select{
    width: 100%;
}

.w33 {
    width: 33.3333%;
}

.w25 {
    width: 25%;
}

.w15 {
    width: 15%;
}

.grouped-elements + .grouped-elements {
    margin-top:15px;
}

.checkbox-container {
    cursor:pointer;
    padding-left:35px;
    padding-top:2px;
    position:relative;
    min-width:50px;
    min-height:25px;

    input[type=checkbox],
    input[type=radio] {
        display:none;
    }

    .checkbox {
        position:absolute;
        border:1px solid $lightgrey;
        width:25px;
        height:25px;
        left:0;
        top:0;
        background:$colorWhite;
        margin: 0;

        &.selected {
            .checkbox-checked-indicator {
                display:block;
            }
        }

        .checkbox-checked-indicator {
            display:none;
            position:absolute;
            top:3px;
            left:3px;
            font-size:18px;
        }

        &:hover:not(.disabled) {
            border:1px solid $hoverBorderColor;
        }
    }

    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        .checkbox {
            background: #e4e4e4;
        }
        label {
            pointer-events: auto;
        }
    }
}

.list-checkbox {
    li {
        margin-bottom:5px;
    }
}

.list-horizontal {
    li {
        float:left;
        margin-right:15px;
        margin-bottom:10px;
    }
}

.list-three-columns {
    li {
        float:left;
        width:33.333333%;
        margin-bottom:10px;
        padding-right:10px;
        &.sg-description-header {
            width: 100%;
        }
    }
}

.list-two-columns {
    li {
        float:left;
        width:50%;
        margin-bottom:10px;
        padding-right:10px;
    }
}




.contentWrapper, .contentOverlayer {
    background-color:  #E8E8E8;
    overflow: hidden;
}


.contentPassivOverlayer{
    background-color: rgba(255,255,255,0.8);
    @include transition(background 0.1s ease-out);
    z-index: 1000;
    cursor: pointer;

    &:hover {
        background-color: rgba(218, 241, 255, 0.9)
    }

}


.contentOverlayer {
    z-index: 1000;
    @include transition(background 0.5s ease-out);
    left:100%;
    @include box-shadow(1px 0 3px 1px #888);


    &.left.hover {
        left: 50px !important;
    }

    &.fast {
        @include transition(background 0.25s ease-out 0.25s);
    }

}

.heading {
    color: #9C9C9C;
    font-weight: 400;
    display:inline-block;
    margin: 15px 0 0px;
    height: 30px;
    line-height: 30px;
    font-size:15px;
    @include respond-to(tablet-large)   { margin: 8px 0; }
    @include respond-to(tablet-small)   { margin: 8px 0; }
}

.blur {
    -webkit-filter: blur(3px);
    filter: progid:DXImageTransform.Microsoft.Blur(pixelradius=3);
    -ms-filter: progid:DXImageTransform.Microsoft.Blur(pixelradius=3);
    filter: blur(3px);
}

.overlayAll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,0.5);
    z-index: 1000;
}

.overlayJS {
    position: absolute;
    height: 80px;
    width: 80px;
    background: rgba(40, 40, 40, 0.7);
    @include box-shadow(0 3px 5px -2px rgba(0,0,0,0.4));
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    @include border-radius(10px);
}

.overlayJS.success {
    background-image: url('../images/checkmark.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: rgba(85, 171, 90,0.5);
}

.overlayJS.hide {
    display: none;
}

footer {
    &.footerBar {
        position:absolute;
        left:0;
        right:0;
        bottom:0;
        height:25px;
        background-color:white;
        @include box-shadow(0 -1px 1px 0 rgba(0, 0, 0, 0.15));
        z-index:9999;
        @include respond-to(mobile) {
            height:20px;
        }

        .impress, .copyright {
            color:#9c9c9c;
            font-size:12px;
            float:left;
            line-height:25px;
            margin: 0 15px;
            @include respond-to(mobile) {
            }

        }
        .copyright {
            float:right;
        }
        .impress {
            cursor: pointer;
        }
    }
}

/* ============================================================
 SWITCH SLIDER COMMON
============================================================ */
.switch {
    display:inline-block;
    float:left;
    margin:2px 0 0 30px;
}
.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}
.cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* ============================================================
  SWITCH 2 - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat + label {
    padding: 2px;
    width: 40px;
    height: 20px;
    background-color: #dddddd;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
    -webkit-transition: background 0.4s;
    -moz-transition: background 0.4s;
    -o-transition: background 0.4s;
    transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:before, input.cmn-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
}
input.cmn-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
    -webkit-transition: background 0.4s;
    -moz-transition: background 0.4s;
    -o-transition: background 0.4s;
    transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 12px;
    background-color: #dddddd;
    @include border-radius(52px);
    @include transition(margin 0.4s, background 0.4s);
}
input.cmn-toggle-round-flat:checked + label {
    background-color: #8ce196;
}
input.cmn-toggle-round-flat:checked + label:after {
    margin-left: 20px;
    background-color: #8ce196;
}

input.cmn-toggle-round-flat:disabled {
    + label:after {
        opacity: .4;
        cursor: not-allowed;
    }

    + label:before {
        cursor: not-allowed;
    }
}

input.cmn-toggle-round-flat:checked:not(:disabled) {
    + label:hover {
        background-color: #5aaf64;
    }

    + label:hover:after {
        background-color: #5aaf64;
    }
}

input.cmn-toggle-round-flat:not(:checked):not(:disabled) {
    + label:hover {
        background-color: #bbbbbb;
    }

    + label:hover:after {
        background-color: #bbbbbb;
    }
}




//global styling elements that gather subelements
.checkBoxElement{
    display: block;
}

.checkBoxElement input{
    display: inline-block;
}

.checkBoxElement label{
    display: inline-block;
}

.dropbtn {
     border: none;
     cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-lang-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    margin-left: -6px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 9999999;

    a {
        height: 40px;
        color: black;
        padding: 5px;
        text-decoration: none;
        display: block;
        cursor: pointer;
    }

    img {
        height: 30px;
    }

    a:hover {
        background-color: #aeefd7
    }
}

.dropdown:hover .dropdown-lang-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.content-shown {
	position: relative;
	width: auto;
	background: white;
}

.content-hidden {
	position: absolute;
    width: 1350px;
    z-index: -1;
}

.lobibox-notify-wrapper.top {
	top: 40px;
}

.lobibox-notify.notify-mini .lobibox-notify-icon-wrapper {
    top: 3px;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.vacStatHead {
    text-align: center;
    vertical-align: middle !important;
}

#heatmap {
    position: relative;
    width: 1000px;
    min-height: 125px;
    margin: 0 auto;
}

.wizard-container {

    background-color: white;
    width: 1200px;
    margin: auto;
    margin-top: 100px;

    .wizard-header {
        background-color: #0293ed;
        color: white;
        font-size: 28px;
    }

    .wizard-steps {
        float: left;
        width: 200px;
        height: 540px;
        background-color: #f5f5f5;

        .wizard-step {
            text-align: left;
            cursor: pointer;
            height: 50px;
            padding: 15px;
            color: #08C;

            &.active {
                color: white;
                background-color: #08C;
            }
        }
    }

    .wizard-form>div {
        float: left;
        padding: 15px;
        text-align: left;
        width: 1000px;
        height: 500px;
        overflow: auto;
    }

    .wizard-table {
        th, td {
            padding: 5px;
            text-align: center;
            width: auto !important;

            input[type=number] {
                width: 150px;
            }

            [button] {
                padding-left: 10px;
            }
        }

        .wizard-shiftframe-block {
            display: block;
            float: left;
            text-align: left;
        }
    }

    .wizard-navigation {
        margin: 10px;
    }

}


/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
        0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.workplaceAvailabilityException {
    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05) 8.51px, rgba(255, 255, 255, 0) 8.51px, rgba(255, 255, 255, 0) 17.02px);
    color: #0a0a0a;
    border-color: lightgrey;
    &.fc-event:hover {
        color: #0a0a0a;
    }
}

.public-holiday-item:hover {
  background-color: whitesmoke;
}

li.team-member-option {
    padding: 6px;
    margin-bottom: 5px;
    background-color: white;
    box-shadow: #b1b1b1 1px 1px 2px;
    user-select: none;

    &:focus { outline: none; }

    .member-name {
    	margin-left: 2px;
    }
}

.saved-public-holidays-items,.public-holidays-items {
	.list-group-item {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

		i {
			line-height: 40px;
		}
	}

	.list-group-item:first-child {
		border-radius: 0;
	}

	.row .list-group-item {
		margin-bottom: 5px;
	}
}

.user-availability-type-items {
  .list-group-item {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);

    i {
      line-height: 40px;
    }
  }

  .list-group-item:first-child {
    border-radius: 0;
  }

  .row .list-group-item {
    margin-bottom: 5px;
  }
}

fieldset.search-fieldset input.search-input {
    margin-bottom: 5px;
}

.shift-group-availability-type-items {
  width: 49%;
  float: left;
  border: 1px solid rgba(102, 102, 102, 0.24);
  margin-left: 4px;
  margin-bottom: 4px;
  background: rgba(193, 193, 193, 0.27)
}

#closePeriodDropdown {
    a.closed {
        background-color: #fcf8e3;
    }
    a:not(.closed) {
        background-color: #dff0d8;
    }
}
.payroll__datev-export__error {
    color: red;
}

.userVacationResetInput:hover {
    cursor: pointer;
    background-color: #ccc;
}

.wage-type-unit {
    position: absolute;
    left: 10px;
    display: flex;
}

.pending-requests-container {
    margin-bottom: 40px;
    max-height: 200px;
    overflow-y: scroll;
}

.pb-10 {
    padding-bottom: 10px;
}

.pending-requests-btn {
    width: 100px;
    margin-bottom: 5px;
}

.request-badge {
    cursor: pointer;
    margin-top: 3px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
    background-image: linear-gradient(#fa3c45, #dc0d17);
    color: white;
    padding: 1px 4px;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    font-family: 'helvetica neue', helvetica, arial, sans-serif;
    font-size: 11px;
    line-height: 1.3;
}

input#newAbsenceName {
    padding: 0px;
    margin: 10px 0 0 2px;
    color: black;
    width: 50%;
    font-size: 14px;
}
.display-flex {
    display: flex;
}
.align-items-end {
    align-items: flex-end;
}
.time-picker-wrapper {
    [time-picker] {
        width: 50px!important;
        text-align: center;
    }
    .input-group-addon {
        cursor: pointer;
        display: inline-block;
        padding: 5px 5px 3px 5px;
        border-radius: 0;
        margin: 0;
        width: auto;
        span {
            margin: 0;
        }
    }
}
.timepicker-box {
    width: 100px;
    display: inline-block;
    vertical-align: middle;
}
.timepickers {
    display: flex;
    align-items: center;
    .timepicker-box {
        &:first-child {
            margin-right: 15px;
        }
        &:last-child {
            margin-left: 15px;
        }
        .grey {
            .form-control {
                color: #b5b3b3;
            }
        }
    }
    .end-on-nex-day {
        margin-left: 15px;
    }
}
.select-search-box {
    &.with-remove-button {
        .form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    .remove-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .typeahead {
        max-height: 400px;
        overflow-y: scroll;
    }
}
.default {
    &-ul {
        margin: auto;
        list-style: inherit;
        padding-left: 15px;
    }
}

.input-error {
    border-color: #a94442 !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

ul.typeahead {
    overflow-x: hidden;
}

.swal-big-font {
    font-size: 1.6rem !important;
}

body.swal2-shown > [aria-hidden="true"] {
    filter: blur(10px);
}

.events-shift-group-select {
  z-index: 3;
}

iframe.new-notification-service {
    width: 100%;
    border: none;
    padding-left: 10px;
}

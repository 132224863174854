/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.08.2014, 15:30:04
    Author     : falkmichel superstar
*/

@import 'utils';

.profileWrap {
    width:100%;
    height:auto;
    padding:15px;
}

.photoBox {
    float:left;
    width:15%;
    height:130px;
    border:2px solid $bluecolor;
    background-color: $facegrey;

    .placeHolder {
        background:url("../images/cam.png") no-repeat center;
        background-position: 0 0;
        width:48px;
        height:48px;
        display:block;
        margin:39px auto;
        opacity:0.4;

    }
}

.profilePic {
    width:171px;
    height:215px;
    background: url(../images/passfoto.png) no-repeat;
}

.singleBlockWrapper {
    width: 100%;
    float: left;
    height: auto;

    .blockStyle{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        background-color: white;
        margin-bottom: 20px;
        padding:0;

        .blockHead {
            width:100%;
            height:30px;
            background-color:#0293ed;
            color:white;
            text-align:center;
            font-size:14px;
            line-height: 32px;

            .headElement{
                height: 30px;
                padding: 0 20px 0 35px;
                width: auto;
                position: relative;
                float: left;


                .entityIcon {
                    width:23px;
                    height:25px;
                    background: url("../images/adminlistsprite.png") no-repeat center;
                    position: absolute;
                    top: 2px;
                    left: 7px;

                    &.department{
                        background-position: -3px 4px;
                    }
                    &.workplace{
                        background-position: -132px 3px;
                    }
                    &.shiftgroup{
                        background-position: -69px 3px;
                    }
                    &.user{
                        background-position: -35px 4px;
                    }
                    &.customer {
                        background-position: -98px 4px;
                    }

                     &.location {
                        background-position: -162px 4px;
                    }

                    &.workplaceallocation {
                        background-position: -195px 4px;
                    }

                    &.timetrack {
                        background: url("../images/icons/timetrack.png") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }

                    &.timetrackIdentifier {
                        background: url("../images/icons/timetrackIdentifier.png") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }

                    &.setup {
                      background: url("../images/settings-24.png") no-repeat;
                      width:24px;
                      height:24px;
                      margin: 5px 3px;
                      background-size: 18px;
                    }

                    &.onboarding {
                        background: url("../images/icons/onboarding.png") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }

                    &.devices {
                        background: url("../images/icons/devices.png") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }

                    &.payroll {
                        background: url("../images/icons/payrollAdmin.png") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }

                    &.rolePermission {
                        background: url("../images/icons/rolePermission.png") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }

                    &.qualification {
                        background: url("../images/icons/qualification.png") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }

                    &.wishes-configuration {
                        background: url("../images/icons/thumbs-up.svg") no-repeat;
                        width:24px;
                        height:24px;
                        margin: 5px 3px;
                        background-size: 18px;
                    }
                }

            }


        }
        .blockNavWrapper {
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 0px;

            li {
                display: inline-block;
                width: 15%;
                font-size: 12px;
                line-height: 20px;
                margin:0;
                border-bottom: none;
                border-left: 1px #c6cacc solid;
                border-bottom: 1px #c6cacc solid;
                background-color: #f5f5f5;
                cursor: pointer;
                text-align: center;
                padding-bottom: 5px;
                color: #9b9b9b;

                &:last-child {
                    border-right: 1px #c6cacc solid;
                }
                &:hover {
                    background-color: #eeeeee;
                }

                .listIcon {
                    width:23px;
                    height:25px;
                    background: url("../images/adminlistsprite_grey.png") no-repeat center;
                    margin: 5px auto 0;
                    display:table;

                    &.department{
                        background-position: -3px 3px;
                    }
                    &.workplace{
                        background-position: -132px 2px;
                    }
                    &.shiftGroup{
                        background-position: -69px 3px;
                    }
                    &.user{
                        background-position: -35px 2px;
                    }
                    &.customer {
                        background-position: -98px 2px;
                    }

                     &.location {
                        background-position: -162px 4px;
                    }

                    &.workplaceallocation {
                        background-position: -195px 4px;
                    }


                }
            }
        }


    }
}

.circleIcon {
    width: 23px;
    height: 23px;
    float: left;
    margin: 5px;

    &.createNew {
        display: inline-block;
        border-radius: 60px;
        box-shadow: 0px 0px 2px #ffffff;
    }

    &.qualification {
        background: url("../images/icons/bulb.svg") no-repeat;
        width: 24px;
        height: 24px;
        margin: 8px 13px;
        margin-left: -4px;
        background-size: 12px;
        &.iconPlusItem {
            background-position: 7px;
        }
    }

    .createNewQualificationPlusIcon {
        float: left;
        margin-left: 96%;
        margin-top: -10%;
        zoom: 1;
        -ms-zoom: 1;
        -webkit-zoom: 1;
        -moz-transform:  scale(1,1);
        -moz-transform-origin: left center;
    }
}

.popupHeadline {
    padding: 0 20px;
    font-weight: 700;
    color: white;
    line-height: 30px;
    background-color: #0293ed;
    width:100%;
}

.overlayerDelete {
    background-color: rgba(0,0,0,0.6);
    -webkit-transition: background 0.5s ease-in-out;
    -moz-transition: background 0.5s ease-in-out;
    -o-transition: background 0.5s ease-in-out;
    transition: background 0.5s ease-in-out;


    .wrapperDelete {
        position: absolute;
        top:50%;
        left: 50%;
        width: 300px;
        background-color: white;
        margin-left: -150px;
        margin-top: -75px;
        padding: 10px;

        .deleteHeadline {
            width: 100%;
            height: 30px;
            background-color: $bluecolor;
            color: white;
            text-align: center;
            font-size: 14px;
            line-height: 32px;
            margin-bottom: 10px;
        }
        .deleteText {
            width: 100%;
            font-size: 13px;
            text-align: center;
        }

        .deleteButtons {
            margin: 10px 0;
            width: 100%;
            font-size: 13px;

            .form {
                float: right;
                width: auto;
            }

            .finalDeleteEntity {
                float: right;
                margin-right: 20px;
            }

            .cancelDeleteEntity {
                float: left;
                margin-left: 20px;
            }
        }
    }
}




.personForm.employee {
    width:100%;
    padding: 0 20px 20px 20px;
    text-align:left;
    float:left;
    @extend %clearfix;

    .personData {
        padding-left: 20px;
        font-weight: 700;
        color: white;
        line-height: 30px;
        background-color: #0293ed;
        margin-bottom: 15px; }
    .clear {
        height: 56px;
        width: 100%;
    }
    .formHeading {
        border-bottom: 1px solid lightgrey;
        color: #0293ed;
        height: 35px;
        line-height: 36px;
        margin: 0;
        padding: 0;

        &.employee {
            line-height:13px;
        }
    }
    span{
        display: block;
        float: left;
        margin-top: 8px;
        padding: 0 15px;
        width:100%;
        &.fieldHeading {
            margin-top:25px;
        }
        &.w50 {
            width:50%;
        }
        &.w85 {
            width:85%;
        }
        &.w100 {
            float:none;
            width:100%;
            &.heading {
                /*border:1px solid #0293ed;*/
                font-size:16px;
                line-height:22px;
                margin: 30px 0 0;
                border-radius:4px;
                padding-left: 5px;

            }



            &.attachment
            {
                padding-top:25px;
            }

            &.formParagraph {
                padding: 15px 15px 0
            }

            &.aditionalContent {
                padding: 0 36px;
            }

        }
        &.w15 {
            width:15%;
        }
        &.w25 {
            width:25%;
        }

        &.w125 {
            width:125%;
        }
        &.w33 {
            width:33.3333%;
        }
        &.w66 {
            width:66.6666%;
        }
        &.clear {
            clear: left
        }

        .radioWrapper {
            height: 30px;
            line-height: 30px;
            margin: 0;
            padding: 0;

            input {
                margin-left: 10px;
            }



            span {
                margin-right:10px;
                display:inline;
                float:none;
                color:$bluecolor;
                font-size:14px;
                padding:0 5px;

            }
            &.state {
                display:inline;
                height:auto;
            }
        }
    }
    &.down {
        margin-top:100px;
    }
    .additionalContent {
        width: 100%;
        float:left;
        padding: 0 15px 0 51px;

    }
}

.addButton  {
    border-radius:4px;
    width:auto;
    height:25px;
    float:left;
    padding:5px 7px;
    line-height:12px;
    display:inline-block;
    font-size:13px;
    margin:20px 0;
    &:hover {
        cursor:pointer;
    }
}

.regButton {
    border-radius:4px;
    width:auto;
    height:24px;
    float:left;
    padding:4px 7px;
    line-height:12px;
    display:inline-block;
    font-size:13px;
    margin: 5px 0 10px 0;

    &.right {
        float:right;
        margin-right:2px;
    }
    &.left {
        margin-left:2px;
    }
}


.editBtn {
    background-color: $bluecolor;
    color:white;
    border-radius: 4px;
    display: inline-block;
    float: right;
    font-size: 13px;
    height: 25px;
    line-height: 14px;
    padding: 6px 7px;
    width: 60px;
    font-size:13px;
    &:hover {
        cursor:pointer;
    }
}

.xpTable {
    width:100%;
    height:auto;
    float:left;
    border-collapse: collapse;
    border-spacing: 0;

    th {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid lightgrey;
        height: 25px;
        text-align:center;
        padding-top:5px;
    }
    tbody {
        tr {

            td {
                width:25%;
                padding:10px;
                height:25px;
                border:1px solid lightgrey;
                &:first-child {
                    padding-left:50px;
                }

                .machine {
                    font-weight:700;
                    padding-top: 20px;

                }

                .radio {
                    /* Enable absolute positioning of the hidden form controls */
                    position:relative;
                    /* Just a bit of space. */
                    margin-bottom:0.5em;
                    /*
                    Match line-height to the height of the replacement image to ensure it
                    doesn't get clipped
                    */
                    line-height:22px;
                }
                input[type="radio"] {
                    position:absolute;
                    /* Match the image dimensions */
                    width:20px;
                    height:20px;
                    /* Reset anything that could peek out or interfere with dimensions */
                    overflow:hidden;
                    margin:0;
                    padding:0;
                    border:0;
                    outline:0;
                    top:13px;
                    left:51px;
                    opacity:0;
                    curser:pointer;
                }
                input[type="radio"] + label:before {
                    display:inline-block;
                    width:35px;
                    height:35px;
                    margin-right:4px;
                    border:1px solid $bluecolor;
                    border-radius:4px;
                    content:" ";
                    vertical-align:top;
                }
                input[type="radio"]:focus + label:before {
                    border:1px solid #006dcc;
                }
                input[type="radio"]:checked + label:before {
                    background-color:red;
                    padding:1px;
                }
                input[type="radio"]:checked:focus + label:before {
                    background-color:red;
                }
                input[type="radio"]:checked:hover + label:before {
                    background-color:light;
                }
                label {
                    cursor:pointer;
                    display: inline-block;
                    padding: 13px 37%;
                }
            }
        }
        .mark {
            width:100%;

            input {
                display:inline-block;
                margin:0 45%;
            }

            &.active {
                width:20px;
                height:20px;
                background: url("https://cdn3.iconfinder.com/data/icons/faticons/32/done-01-20.png") no-repeat;
            }
        }
        .tableDescription {
            color:#0293ed;
        }
        .machine {
            font-size: 13px;
            font-weight: 700;
            padding-left: 10px;
            text-align: left;

        }
    }

    .popUp {
        height: 776px;
        left: 0px;
        top:60px;
        width:900px;
        position: absolute;
        right: 15px;
        z-index: 99999;

    }
    .closePop {
        width:27px;
        height:27px;
        background: url("../images/big_close.png") no-repeat;
        cursor: pointer;
        float: right;
        height: 27px;
        margin: 3px;
        position: absolute;
        right: 5px;
        top: -2px;
        width: 27px;
        z-index: 999999;
    }
    .xpTable {

        th {
            border:1px solid lightgrey;
            height:25px;
        }
    }
}

.createNewQualificationLegend {
    height: 40px;
}

.createNewQualificationLegendText {
    padding-top: 10px;
}

.wpSave-btn {
    float: right;
    margin: 10px;
}

.skipCreateNewQualificationText {
    float: right;
    margin-right: -90px;
    margin-top: 5px;
    font-weight: bold;
}

.wpSkip-btn {
    float: right;
    margin: 10px;
    margin-top: 0;
    margin-bottom: 0;
}

.invalid-input {
    border-color: #a94442 !important;
}

@mixin btn-custom-grey-hovers-inactive {
    color: #1E2025!important;
    background: #F4F5F7!important;
    box-shadow: none!important;
}
@mixin btn-custom-grey-hovers-active {
    @include btn-custom-grey-active;
}
@mixin btn-custom-grey-disabled-inactive {
    background: #F4F5F7!important;
    color: #999999!important;
}
@mixin btn-custom-grey-disabled-active {
    @include btn-custom-grey-active;
}
@mixin btn-custom-grey-active {
    color: #fff!important;
    background: #0094CD!important;
    border: 1px solid #e5e5e5!important;
}
@mixin btn-custom-grey {
    color: #1E2025!important;
    background: #F4F5F7!important;
    box-shadow: none!important;
}
.btn-custom-grey {
    @include btn-custom-grey;
    &.active {
        @include btn-custom-grey-active;
        &:focus, &:active, &:hover {
            @include btn-custom-grey-hovers-active;
        }
        &[disabled] {
            @include btn-custom-grey-disabled-active;
            &:focus, &:active, &:hover {
                @include btn-custom-grey-disabled-active;
            }
        }
    }
    &.focus {
        outline: none;
    }
    &:focus, &:active, &:hover {
        @include btn-custom-grey-hovers-inactive;
    }
    &[disabled] {
        @include btn-custom-grey-disabled-inactive;
        &:focus, &:active, &:hover {
            @include btn-custom-grey-disabled-inactive;
        }
    }
}
@mixin btn-custom-grey-no-borders {
    border-top-color: transparent!important;
    border-bottom-color: transparent!important;
    &:first-child {
        border-left-color: transparent!important;
    }
    &:last-child {
        border-right-color: transparent!important;
    }
}
.btn-group {
    .btn-custom-grey {
        @include btn-custom-grey-no-borders;
    }
}
/**
* Overriding special cases where styles have bigger priority.
*/
#matrix-detail {
    .btn-group {
        .btn-custom-grey {
            @include btn-custom-grey;
            &.active {
                @include btn-custom-grey-active;
                @include btn-custom-grey-no-borders;
                &:focus, &:active, &:hover {
                    @include btn-custom-grey-hovers-active;
                    @include btn-custom-grey-no-borders;
                }
                &[disabled] {
                    @include btn-custom-grey-disabled-active;
                    @include btn-custom-grey-no-borders;
                    &:focus, &:active, &:hover {
                        @include btn-custom-grey-disabled-active;
                        @include btn-custom-grey-no-borders;
                    }
                }
            }
            &:focus, &:active, &:hover {
                @include btn-custom-grey-hovers-inactive;
            }
            &[disabled] {
                @include btn-custom-grey-disabled-inactive;
                &:focus, &:active, &:hover {
                    @include btn-custom-grey-disabled-inactive;
                }
            }
        }
    }
}


@import 'utils';

nav.mainNavigation {
  position: absolute;
  background-color:$colorPrimary;
  z-index: 200;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include respond-to(mobile) {
    height: 50px;
    top:  0;
    left: 0;
    right: 0;
    margin-bottom: 2px;
  }
  @include respond-to(tablet-small) {
    width: 50px;
    top: 0px;
    left: 0;
    bottom: 25px;
  }
  @include respond-to(tablet-large) {
    width: 75px;
    top: 50px;
    left: 0;
    bottom: 25px;
  }
  @include respond-to(normal) {
    width: 90px;
    top: 50px;
    left: 0;
    bottom: 25px;
  }
  @include respond-to(wide)   {
    width: 90px;
    top: 50px;
    left: 0;
    bottom: 25px;
  }

  & div.row {
    height: 100%;

    & div.col-md-12 {
      height: 100%;
    }
  }

  ul.sidebar {
    @include respond-to(mobile) {
      position:absolute;
      top:0;
      left:0;
      right:0;
      height:50px;
    }

    li {

      @include transition(background 0.1s ease-in-out);

      &.child {
        width:100%;
        height: 80px;
        padding-top:52px;
        cursor: pointer;
        position: relative;

        @include respond-to(tablet-large) {
          width:100%;
          height:50px;
        }
        @include respond-to(tablet-small) {
          width:100%;
          height:50px;
        }
        @include respond-to(mobile) {
          width:16%;
          height:50px;
          float:left;
          position: relative;
          padding-top: 50px;
        }

        &.setts {
          vertical-align: bottom;
          border-bottom:none;
          position:absolute;
          bottom:0;

          @include respond-to(mobile) {
            width:20%;
            height:50px;
            float:left;
            position: static;
          }
        }

        &.active {
          @include box-shadow(inset 1 -6px 0px -3px rgba(0, 0, 0, 0.25), inset 0 -3px -3px -3px rgba(0, 0, 0, 0.25));
          background-color:#0074BD;
          border-color:#116CA5;
          color:white;
          &:hover {
            background-color:#0074BD;
          }
        }
      }
      .icon {
        position:absolute;
        top:10px;
        left:29px;
        background-image: url("../images/navsprite.png");
        background-repeat: no-repeat;
        width:34px;
        height:34px;
        @include respond-to(tablet-large) {
          height: 28px;
          width: 25px;
        }
        @include respond-to(tablet-small) {
          height:24px;
          width:25px;
          left: 12px;
        }
        &.home, &.homescreen {
          background: url("../images/icons/home.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.matrix{
          background: url("../images/icons/matrix_logo_white.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: 24px auto;
          }
          @include respond-to(tablet-small) {
            background-size: 21px auto;
          }
          @include respond-to(mobile) {
            background-size: 21px auto;
          }
        }
        &.calendar{
          background: url("../images/icons/calendar.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.user_absence{
          background: url("../images/icons/user_absence.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.usercalendar{
          background: url("../images/icons/calendar.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.enrollment {
          background: url("../images/icons/note.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.planning{
          background: url("../images/icons/planning.png") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: 24px;
          }
          @include respond-to(tablet-small) {
            background-size: 24px;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.timetrack{
          background: url("../images/icons/clock.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.proposal{
          background: url("../images/icons/documents.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.reporting{
          background: url("../images/icons/reporting.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }

        &.payroll{
          background: url("../images/icons/payrollAdmin.png") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }

        &.admin{
          background: url("../images/icons/config.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.setting{
          background: url("../images/icons/settings.svg") no-repeat;
          background-size: auto 34px;

          @include respond-to(tablet-large) {
            background-size: auto;
          }
          @include respond-to(tablet-small) {
            background-size: auto;
          }
          @include respond-to(mobile) {
            background-size: auto 28px;
          }
        }
        &.help{
          background: url("../images/icons/message-question-solid.svg") no-repeat;

          @include respond-to(tablet-large) {
            background-size: 24px auto;
          }
          @include respond-to(tablet-small) {
            background-size: 24px auto;
          }
          @include respond-to(mobile) {
            background-size: 24px auto;
          }
        }
      }
      &.bg-hover {
        @include transition(background 0.1s ease-in-out);
      }
      &:hover {
        background-color: #1086D1;
      }
      .navTitle {
        color:$colorWhite;
        width: 100%;
        text-align: center;
        font-size: small;
        @include respond-to(tablet-large) {
          display:none;
        }
        @include respond-to(tablet-small) {
          display:none;
        }
        @include respond-to(mobile) {
          display:none;
        }
      }
      &#nav-menu-help {
        position: absolute;
        bottom: 0;
        @include respond-to(tablet-small) {
          width: 50px;
        }
        @include respond-to(tablet-large) {
          width: 75px;
        }
        @include respond-to(normal) {
          width: 90px;
        }
        @include respond-to(wide)   {
          width: 90px;
        }
      }
    }
    .newIcon {
      background: url("../images/shield.png") no-repeat;
      width:34px;
      height:34px;
      margin: 10px 23px;
    }
  }
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 06.08.2014, 15:04:46
    Author     : falkmichel
*/

@import 'utils';

.adminButton {
    position: relative;
    cursor: pointer;
    line-height: 18px;


    .adminButtonArrow {
        font-size: 25px;
        float: left;
        line-height: 36px;
        margin-left: 5px;
    }


    .adminButtonText {
        width: auto;
        height: auto;
        float: left;
    }

    .adminDropdown {
        display: none;
        position: absolute;
        top: 32px;
        right: -1px;
        width: auto;
        min-width: 110%;
        height: auto;
        float: left;
        background-color: $whitesmoke;
        border: 1px $lightgrey solid;
        @include box-shadow(0px 5px 15px -2px rgba(0,0,0,0.2));
        z-index: 100;
        cursor: default;
        float: left;
    }

    .adminDropdownList {
        width: auto;
        min-width: 100%;
        height: auto;
        float: left;
        font-weight: 300;

        .adminListElements {
            width: 100%;
            min-width: 100%;
            height: 26px;
            line-height: 26px;
            border-bottom: 1px solid $lightgrey;
            display: block;
            padding: 0 30px;
            color:$bluecolor;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: #f6f6f6;
            }
        }
    }

    &.active-dropdown {
        height: 34px;
        line-height: 22px;

        .adminButtonArrow {
            line-height: 40px;
        }
    }

}

iframe.fill {
    height: inherit;
    width: 100%;
}

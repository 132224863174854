.onboarding-item {
  top: 50%;
  font-size: 12px;
  margin: 15px 0 0 0;
  background-color: white;
  box-shadow: 1px 1px 1px #d2d2d2;
  display: flex;
  align-items: center;

  .onboarding-item__item {
    padding: 5px;
  }

  .onboarding-item__subtext {
    font-weight: 400;
  }

  .onboarding-item__custom {
    margin-top: 0px;

    .checkbox-with-label {
      input {
        position: relative;
        width: 20px;
        height: 20px;
      }
    }

    .checkbox-with-label input:hover {
      cursor: pointer;
    }
  }

  .onboarding-item__options {
    padding: 0;
    text-align: right;
    margin-left: -20px;
  }
}

.onboarding-item__bold {
  font-weight: bold;
}

@charset "UTF-8";

@import "utils";

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    
    // Webkit 'hack' for non-jittering animations
    -webkit-font-smoothing: subpixel-antialiased;
}


/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
    position: relative;
}

/* IE specific for preventing the mobile 300ms delay */
a, button, input {
    -ms-touch-action: none;
}

html, body {
    height: 100%;
    min-height: 100%;
    width: 100%;
}

body {
    line-height: 1;
    font-family: 'Roboto Slab', sans-serif;  
    font-weight: 300;
}

ol, ul {
    list-style: none;
    //width: 100%;
    //float:left;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

q {
    quotes: '“' '”' '‘' '’';
}

q:before {
    content: '“';
    content: open-quote;
}

q:after {
    content: '”';
    content: close-quote;
}

/*button {
    display: inline-block;
    text-decoration: none;
    background-color: white;
    border:1px grey solid;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    font-size: 100%;
    white-space:nowrap;
}

input {
    outline: none;
}*/


.clearfix {
    @extend %clearfix;
}

.wrapper {
    height: auto;
    min-height: 100%;
    position: relative;
}

a {
    text-decoration: none;
    color: #ed6f01;
}


@import 'variables';

$matrix_cell_width: 48px;
$matrix_cell_height: 48px;

$matrix_left_width: 86px;

$matrix_width: 52 * $matrix_cell_width;
$matrix_height: 120 * $matrix_cell_height;

$viewport_width: 28 * $matrix_cell_width;
$viewport_height: 16 * $matrix_cell_height + 1px;

[draggable=true] {
  -khtml-user-drag: element;
}

[popout-load] {
	display: initial !important;
}

td.cw:hover {
  cursor: pointer;
  background-color: #e0dbdb;
}

td.cw.selected {
  background-color: #e0dbdb;
}

.sidebar-nav .bootstrap-datetimepicker-widget li {
  text-indent: 0;
}

#wrapper.collapsed #sidebar-wrapper:not(:hover) .bootstrap-datetimepicker-widget {
	visibility: hidden;
}

.notransition, .notransition *:not(.refresh, .refresh *, .fa-spinner, .fa-spinner *) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation: none !important;
}

.matrix.top {
	display: block;
	position: relative;
	margin-left: $matrix_left_width + 1px;
	height: 54px;
	overflow: hidden;
	width: $viewport_width;

	&.matrix-navigation {
		height: 45px;
	}

	.inner {
		width: $matrix_width;
	}

	.month {
		float: left;
		position: relative;
		height: 18px;
		font-size: 12px;
		background: none;
		background-color: #0288D1;
		color: white;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		text-align: left;
		padding-left: 10px;
	}

	.week {
		float: left;
		position: relative;
		height: 18px;
		font-size: 12px;
		background: none;
		background-color: #689F38;
		color: white;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		color: white;
		text-align: left;
		padding-left: 10px;
	}

	.firstrow {
		float: left;
		position: relative;
		width: $matrix_cell_width;
		height: 18px;
		font-size: 10px;
		background: none;
		background-color: #455A64;
		color: white;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
		text-align: left;
		padding-left: 10px;

		&.today {
			background-color: rgba(69, 90, 100, 0.8)
		}
	}

	.day {
		.month,.week,.firstrow {
			margin-top: -2px;
    		height: 16px;
		}
	}
}

.matrix {
	&.left,&.right {
		position: relative;
		float: left;
		width: $matrix_left_width;
		overflow: hidden;
		height: $viewport_height;

		.inner {
			height: $matrix_height;
			.column-item {
				margin-left: 2px;
				&.limit-reached {
					color: red;
				}
			}
		}

		.placeholder {
			height: 2px;
			background-color: #ccc;
		}

		.drag {
			cursor: move;
		}
	}
}

.matrix-wrapper {
	//.noselect {
		-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			 -khtml-user-select: none; /* Konqueror HTML */
				 -moz-user-select: none; /* Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
							user-select: none; /* Non-prefixed version, currently
																		supported by Chrome and Opera */
	//}

	.shift.droptarget {
		box-shadow: grey 0 0 0 1px inset;
	}

	.matrix-row.notdroptarget {
		background-color: lightgrey;
		opacity: 0.5;
    }

	.view {
		position: relative;
		width: $viewport_width;
		height: $viewport_height;
		overflow-x: auto;
		overflow-y: auto;
		border-top: 1px solid #ddd;
		border-left: 1px solid #ddd;
	}

	.inner {
		width: $matrix_width;
		height: $matrix_height;

		&.week {
			background-size: $matrix_cell_width $matrix_cell_height ;
			background-image: $matrix-week-background;
		}

		&.day {
			background: $matrix-day-background;

			.row {
				background: $matrix-day-row-background;
			}
		}
	}

	.inner:not(.week):not(.day) {
		.item {
			overflow: hidden;
			ul {
				background-color: rgba(255, 255, 255, 0.8);
			}
		}
	}

	.row {
		width: 200000px;
		margin: 0;
	}

	.matrix-row:hover {
		-moz-box-shadow:    3px 1px 6px 1px #ccc;
		-webkit-box-shadow: 3px 1px 6px 1px #ccc;
		box-shadow:         3px 1px 6px 1px #ccc;
		background-color: 	rgba(60,60,60,0.05);
  	}

	.item {
		float: left;
		position: relative;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		width: $matrix_cell_width;
		height: $matrix_cell_height;
		font-size: 8px;
		white-space: nowrap;

		&:first-child {
			clear: both;
		}

		.matrix__shift {
			display: block;
			width: 100%;
			text-align: left;
			text-overflow: ellipsis;
			border-bottom: 1px solid #ddd;
			background: none;
			background-color: rgba(  0,255,255,0.15);
			line-height: 10px;
			padding: 2px;
			cursor: pointer;
			transition: all .2s;

			&.highlight {
				&.selectable {
					color: #000;
					opacity: 0.7 !important;
					background-image: linear-gradient(90deg, #0288d1 50%, transparent 50%), linear-gradient(90deg, #0288d1 50%, transparent 50%), linear-gradient(0deg, #0288d1 50%, transparent 50%), linear-gradient(0deg, #0288d1 50%, transparent 50%);
					background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
					background-size: 15px 1px, 15px 1px, 1px 15px, 1px 15px;
					background-position: left top, right bottom, left bottom, right   top;
					animation: border-dance 1s infinite linear;
					&:hover {
						opacity: 1!important;
					}
					@keyframes border-dance {
						0% {
							background-position: left top, right bottom, left bottom, right top;
						}
						100% {
							background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
						}
					}
				}
				&.selected {
					box-shadow: #2aa3e0 0 0 0 2px inset !important;
				}
				&.not-selectable {
					cursor: not-allowed!important;
				}
			}

			&.doctari {
				&.doctari-highlight {
					color: #fff!important;
					background-color: #264B66!important;
				}
				&.candidate-booked {
					color: #264B66!important;
					background: #f3ffff !important;
					.doctari-shift {
						padding-left: 0;
					}
					.icon {
						background-image: url("./img/doctari-pro-icon-blue.svg")!important;
					}
				}
				.cell-shift-span {
					.doctari-shift {
						display: inline-block;
						padding: 0 1px;
						vertical-align: baseline;
						margin: 0 1px;
						.icon {
							display: inline-block;
							background: url("./img/doctari-pro-icon-white.svg") left center no-repeat;
							background-size: 8px 8px;
							border-radius: 2px;
							padding-left: 10px;
							padding-top: 1px;
							margin-top: -1px;
							font-size: 9px;
							padding-right: 2px;
							vertical-align: top;
						}
						.fa {
							font-size: 8px;
							vertical-align: top;
							padding-top: 1px;
							&.request-created {
								color: #FF7577;
							}
						}
					}
				}
			}

			&.assignable {
				border: 1px solid rgba(255, 120, 0, 0.6);
			}

			&:first-child {
				border-top: none;
			}

			&:nth-child(2n) {
				background-color: rgba(  0,255,255,0.05);
			}

			&.user-empty,&.availability-unapproved {
				background-color: rgba(200, 148, 0, 0.15);

				&:nth-child(2n) {
					background-color: rgba(200, 148, 0, 0.05)
				}
			}

			&.user-occupied,&.user-unqualified {
				background-color: rgba(0, 0, 0, 0.25);

				&:nth-child(2n) {
					background-color: rgba(0, 0, 0, 0.15)
				}
			}

			&.hover:not(.not-selectable) {
				background-color: white !important;
				color: black !important;
				transition: all .2s;
                box-shadow: #2aa3e0 0 0 0 2px inset !important;
			}

            &.multi-connected-hover {
                box-shadow: #ffa726 0 0 0 2px inset !important;
            }

			&.highlighted {
				background-color: #0288D1 !important;
				color: white !important;
			}

			&.highlighted-user {
				transition: all .2s;
			}

			&.highlighted-history {
				box-shadow: gray 0 0 0 2px inset
			}

			&.detailed {
				background-color: #EEE8AA;
				border-radius: 7px;
			}

    		&.virtual {
    			background-color: rgba(0, 255, 43, 0.15);

	    		&:nth-child(2n) {
					background-color: rgba(0, 255, 43, 0.05);
				}
    		}

		}

		&.weekend {
			background-color: rgba(  0,  0,  0,0.05);
		}

		&.holiday {
			background-color: rgba(  0,  0,255,0.05);
		}

	}

	.availability {
		height: 100%;

		&.unapproved  {
			background-color: #e8e8e8 !important;
		}

		&.available {
			background-color: rgba(  0,255,  0,0.15);
		}

	}

}

.matrix-floating-buttons {
	z-index: 600;
	.legend {
		font-size: 12px;
		white-space: nowrap;

		span.legend {
			display: inline-block;
			width: 16px;
			height: 16px;
			vertical-align: middle;
			margin-right: 6px;
		}

		ul {
			margin-top: 12px;
			line-height: 20px;
		}

		.avatar {
			color: black;
			transition: all 0.5s;
			background-color: rgba(255, 226, 0, 1);
			margin: 0;
		}
	}

	.grow {
		height: 48px;
		width: 48px;
		position: absolute;
		right: 85px;
		margin-top: -75px;
		padding: 0;
		transition: all 0.5s;
		-webkit-transition: all 0.5s; /* For Safari */
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(255, 255, 255, 0);
		overflow: hidden;
		z-index: 200;
		cursor: pointer;
		ul {
			visibility: hidden;
		}

		&:not(.grow_clicked) {
			height: 48px !important;
			margin-top: -75px !important;
		}

		&.grow_clicked {
			width: 230px;
			background-color: rgba(255, 255, 255, 1);
			border: 1px solid rgba(0, 0, 0, 0.15);
			box-shadow: 2px 4px 3px 0 rgba(0, 0, 0, 0.25);
			padding: 5px;

			ul {
				visibility: visible;
			}

			.avatar {
				box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.15);
			}
		}
	}


	.refreshmatrix {
		height: 48px;
		width: 48px;
		position: absolute;
		right: 35px;
		margin-top: -75px;
		padding: 0;
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(255, 255, 255, 0);
		overflow: hidden;

		.avatar {
			color: black;
			margin: 0;
			cursor: pointer;

			&.refresh {
				background-color: rgba(104, 159, 56, 1);
			}
			&.spinner {
				background-color: rgba(219, 219, 219, 1);
			}

			i {
				@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
				@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
				@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
			}

			&.refresh:hover i, &.spinner i {
				-webkit-animation:spin 1s linear infinite;
				-moz-animation:spin 1s linear infinite;
				animation:spin 1s linear infinite;
			}
		}
	}

	.matrixmultiselect {
		height: 48px;
		width: 48px;
		position: absolute;
		margin-top: -75px;
		padding: 0;
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(255, 255, 255, 0);
		transition: all 0.5s;
		-webkit-transition: all 0.5s; /* For Safari */
		overflow: hidden;

		.avatar {
			color: black;
			margin: 0;
			cursor: pointer;

			&.matrixmultiselectAvatar {
				background-color: #0288d1;
			}

			i {
				@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
				@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
				@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
			}
		}
	}

	.matrixprint {
		height: 48px;
		width: 48px;
		position: absolute;
		margin-top: -75px;
		padding: 0;
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(255, 255, 255, 0);
		overflow: hidden;
		.avatar {
			color: black;
			margin: 0;
			cursor: pointer;

			&.matrixPrint {
				background-color: #babfb6;
			}

			i {
				@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
				@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
				@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
			}
		}
	}

	.matrix-notifications {
		height: 48px;
		width: 48px;
		position: absolute;
		margin-top: -75px;
		padding: 0;
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(255, 255, 255, 0);
		overflow: hidden;
		.avatar {
			color: black;
			margin: 0;
			cursor: pointer;

			&.matrix-notifications-avatar{
				background-color: #c3a637;
			}

			i {
				@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
				@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
				@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
			}
		}
	}

	.matrixdoctari {
		height: 48px;
		width: 48px;
		position: absolute;
		margin-top: -75px;
		padding: 0;
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(255, 255, 255, 0);
		overflow: hidden;
		.avatar {
			color: black;
			background: white;
			margin: 0;
			cursor: pointer;

			&.matrix-doctari {
				border: grey 0.1px solid;
				background: white url("../images/icons/doctari-pro-icon-blue.svg") center no-repeat;
			}
		}
	}

	.matrix-pendingApproval {
		height: 48px;
		width: 48px;
		position: absolute;
		margin-top: -75px;
		padding: 0;
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(255, 255, 255, 0);
		overflow: hidden;
		.avatar {
			color: black;
			margin: 0;
			cursor: pointer;

			&.matrix-pendingApproval-avatar{
				background-color: orange;
			}

			i {
				@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
				@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
				@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
			}
		}
	}
}

.firstcol {
	height: $matrix_cell_height;
    width: $matrix_left_width;

    .avatar {
    	width: $matrix_left_width - 6px;
    	font-size: 12px;
	}
}

.avatar {
	height: $matrix_cell_width - 6px;
    width: $matrix_cell_width - 6px;
    margin: calc((($matrix_cell_height - $matrix_cell_width) / 2) + 3px) 3px;
    border-radius: calc($matrix_cell_width / 2);
    display: inline-block;
    text-align: center;
    line-height: $matrix_cell_width - 6px;
    font-size: 14px;
    color: #ffffff;
    -webkit-user-select: none;
	-moz-box-shadow:    2px 4px 3px 0 rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: 2px 4px 3px 0 rgba(0, 0, 0, 0.25);
	box-shadow:         2px 4px 3px 0 rgba(0, 0, 0, 0.25);

    &.group {
    	background-color: #455A64;

	    &.group-0 {
			background-color: #b9b9b9;
		}

		&.group-1 {
	    	background-color: #455A64;
	    }

	    &.group-2 {
	    	background-color: #0288D1;
	    }

	    &.group-3 {
	    	background-color: #689F38;
	    }

	    &.group-4 {
	    	background-color: #4BB3FD;
	    }

	    &.group-5 {
	    	background-color: #3E6680;
	    }

	    &.group-6 {
	    	background-color: #588157;
	    }

	    &.group-7 {
	    	background-color: #403F4C;
	    }

		&.group-availability {
			background-color: #3AAED8;
		}
    }
}

.contentWrapper {
	overflow: hidden;
}

#matrix-statistics {

	.table-bordered {
		border: none;
	}

	th[scope=col] {
		text-align: center;
		border: none;
	}

	th {
		font-weight: bold;
	}
}

/* Navigation Styles */

.contentWrapper {
	background-color: white;
}

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow: hidden;
}

#wrapper.toggled {
    padding-left: 250px;
    overflow: scroll;
}

#sidebar-wrapper {
    z-index: 200;
    position: absolute;
    top: 1px;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow: hidden;
    background: white;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .2);
}

#custom-scrollbar {
    height: 100%;
    overflow-y: auto;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    position: absolute;
    padding: 10px;
    width: 100%;
    overflow: hidden;

	.container-fluid {
	    min-width: 360px;
	    padding: 5px;
	}

	.tooltip {
		position: fixed;
	}
}

#wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
}

.fixed-brand{
    width: auto;
}

/* Detail view styles */
#matrix-detail {

	em {
		font-style: italic;
	}

	.comment-content p {
		white-space: pre-line;
	}

	.MatrixDetailUserAutoComplete .typeahead {
		width: 100%;
		margin-bottom: 30px;
        max-height: 320px;
        overflow-y: scroll;
	}

	.slider-selection {
		background-image: linear-gradient(to bottom, #65bbe6 0, #2aa3e0 100%);
	}

	.slider-horizontal {
		width: 550px;
	}

	.btn-group {

		.user-label {
			position: relative;
    		float: left;
    	}

	 	.btn {
	    	margin-right: 0;
	    	border: 1px solid #ddd;
	    	color: white !important;
	    }
	}

	.btn-group>.user-label {
    	width: 529px;
	    background: white;
	    color: #646464;
	    text-align: left;
	    border: 1px solid #ddd;
	    border-top-left-radius: 4px;
	    border-bottom-left-radius: 4px;
	    padding: 6px 0 6px 10px;
	    border-right: none;
	}

	.comments-container .comment-item {
		border-top: solid lightgrey 1px;

		.comment-delete {
			color: red;
		}

	}
}

/* Sidebar Styles */

.sidebar-nav {
	width: 250px;
	margin: 0;
	padding: 0;
	list-style: none;
	margin-top: 2px;

	.panel-default {
		margin-left: 50px;
		margin-right: 10px;
	}

	.panel-default:first-child {
		margin-top: 20px;
	}

	.panel-heading {
		padding: 2px;
		font-weight: 700;
		line-height: 30px;
	}

	.list-group-item {
		padding: 0;
		line-height: 29px;

		a {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}

.sidebar-nav li {
    text-indent: 15px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #646464;

    &.active {
   		background: #efefef;
    }
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: #000;
    background: #efefef;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
    color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}
.no-margin{
    margin:0;
}

@media(min-width:768px) {
    #wrapper {
        padding-left: 250px;
    }
    .fixed-brand{
        width: 250px;
    }
    #wrapper.toggled {
        padding-left: 0;
    }

    #sidebar-wrapper {
        width: 265px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 265px;
    }
    #wrapper.collapsed #sidebar-wrapper:not(:hover) {
        width: 50px;

        #custom-scrollbar {
          overflow: hidden;
        }
    }
    #wrapper.collapsed #sidebar-wrapper:hover {
        #custom-scrollbar {
          overflow: auto;

          .sidebar-nav {
            width: auto;
            min-width: 250px;
          }
        }
    }

    #page-content-wrapper {
        padding: 10px;
        position: relative;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
        padding-left: 250px;
    }
    #wrapper.collapsed #page-content-wrapper {
        position: relative;
        margin-right: 0;
        margin-left: -200px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        width: auto;

    }
}
.iframe-holder {
    &.iframe-loading {
        display: flex;
        align-items: center;
        height: 580px;
        iframe {
            display: none;
        }
        &.top {
            align-items: flex-start;
        }
    }
}

.matrix-switch-info-container{
	animation-duration: 2s;
	animation-name: zooming;
	animation-iteration-count: infinite;
}

@keyframes zooming {
	90% {
		transform: scale(0.95);
	}
	to {
		transform: scale(1);
	}
}

@charset "UTF-8";
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 26.08.2014, 16:08:20
    Author     : falkmichel  lässt sich hochladen
*/

$break-small: 450px;
$break-small-landscape: 568px;
$break-medium: 800px;
$break-large: 1024px;  
$break-wide: 1440px;

$bluecolor: #0293ed;
$greencolor: #1cb07a; 
$lightgrey: #c6cacc; 
$black: #2e3133;
$whitesmoke: #fafafa;
$facegrey: #F3F3F3; 


%clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after { 
        clear: both;
    }
}

@mixin respond-to($media) {  
    @if $media == mobile {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == mobile-landscape {
        @media only screen and (min-device-width: $break-small + 1) and (max-width: $break-small-landscpae - 1) and (orientation : landscape) { @content; }
    }
    @else if $media == tablet-small {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium - 1) { @content; }
    }
    @else if $media == tablet-large {
        @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == normal {
        @media only screen and (min-width: $break-large) and (max-width: $break-wide - 1) { @content; }
    }
    @else if $media == wide {
        @media only screen and (min-width: $break-wide) { @content; }
    }
}

.activeShiftWrap {
    width:100%;
    height:auto;
    padding:15px;
}

.mainContentWrapper {
    &.planning {
        top: 0px;
        left: 400px;
        bottom:0px;
        right: 0px;

        @include respond-to(wide)   { 
            left:350px;
        }
        @include respond-to(normal) {
            left:350px;
        }
        @include respond-to(break-medium) {
            left:300px;
        }
        @include respond-to(tablet-large)   { 
            left:300px;
        }
        @include respond-to(tablet-small)   { 
            left:200px;
        }
        @include respond-to(mobile) {
            left:0;
        }
    }
}


.popupHeadline.plan {
    .popHeader {
        font-size:14px;
        display:inline-block;      
    }  
    .closeBtn {
        width:27px;
        height:27px;
        background: url("../images/big_close.png") no-repeat;
        cursor: pointer;
        float: right;
        margin: 3px;
        position: absolute;
        right: 22px;
        top: 15px;


        &.secLevel {
            right:26px;
            top:8px;
        }
    }   
}


.logSearchBar {
    background-color:white;
    margin: 6px 0;
    width: 96%;
    height: 22px;
    border: 1px lightgrey solid;
    background-color:white;
    border-radius: 4px;  
    background: url(../images/search.png) no-repeat 95% center white;
    font-size: 13px;
    padding: 0 15px;
    @include respond-to(wide)           { width: 96%; }
    @include respond-to(normal)         { width: 96%; }
    @include respond-to(tablet-large)   { width: 96%; }
    @include respond-to(tablet-small)   { width: 96%; }
    @include respond-to(mobile)         { width: 96%; }

    .cancleButton {
        position:absolute;
        width:22px;
        height:22px;
        right:20px;
        top:10px;
        display:none;
        cursor: pointer;
        background: url(../images/cancel-circle.png) no-repeat 95% center white;
    }
    &.focused {
        .logSearchBar {
            width: 100%; 
            background-position:97%;
        }
        .cancelButton {
            display:inline-block;
        }
    }
}


.switchLog, .present, .absent, .periodWrap {
    width:100%;
    height:auto;
    float:left;
    /* background-color:$whitesmoke;*/
    padding: 5px;

    &.memberList {
        border:1px solid lightgrey;
        border-radius:4px;
        margin-bottom:5px;
    }

    .logChild, .presenceChild {
        float:left;
        width:100%;
        height:auto;

        .shiftWrap {
            border: 1px solid lightgrey;
            border-radius: 4px;
            float: left;
            height: auto;
            margin: 5px 2%;
            overflow: hidden;
            width: 33%;
            .swapName {
                background-color: #f9f9f9;
                border-bottom: 1px solid lightgrey;
                color: $bluecolor;
                display: block;
                float: left;
                font-size: 13px;
                height: 18px;
                line-height: 19px;
                width: 100%;
            }
            .shiftType {
                color: #2e3133;
                float: left;
                font-size: 12px;
                line-height: 14px;
                padding: 2px 0 0;
                width: 100%;
            }
            .shiftDate {
                color: #2e3133;
                float: left;
                font-size: 12px;
                line-height: 12px;
                padding: 2px 0;
                width: 100%;
            }
        }
        .swapSymbolWrap {
            border-radius: 4px;
            float: left;
            height: auto;
            margin: 5px 2% 0;
            text-align: center;
            width: 22%;
            font-size:12px;
            .swapIcon {
                width:16px;
                height:16px;
                margin: 5px 37%;
                &.aktivaktiv {
                    background: url(../images/aktiv-aktiv.png) no-repeat;
                }
                &.aktivpassiv {
                    background: url(../images/aktiv-passiv.png) no-repeat;
                }
                &.passivaktiv {
                    background: url(../images/passiv-aktiv.png) no-repeat;
                }
                &.passivpassiv {
                    background: url(../images/passiv-passiv.png) no-repeat;
                }
            }
            .swapLogDate {
                float:left;
                color:$bluecolor;
                font-size:13px;
                width:100%;
            }
        }
    }
}

.present, .absent {
    padding:10px;
    .presenceHeader {
        font-size:14px;
        color:$bluecolor;
        margin: 7px;

    } 
    .presenceChild, .legende, topListChild {

        cursor:pointer;

        /* &:hover {
             background-color: $facegrey;
         }*/

        .workerName, .workerPosition, .holidayReason, .workerTimes, .nameLegend, .workLegend, .shiftLegend  {
            font-size:12px;
            color:3f3f3f;
            float:left;
            padding:4px;
            /*border-right:1px lightgrey solid;*/
            height:21px;
            text-align: center;
            width: 33.3333%;
            line-height:18px;
            &:last-child {
                border-right:none
            }
        }


        .workerName, .holidayReason {
            text-align:left;
            padding-left:50px;
        }
        .workerPosition {
            text-align:left;
            padding-left:50px;
        }

        .currentShiftIcon {
            width:16px;
            height:16px;
            float:left;
            margin: 3px 0 0 15%;
            &.day {
                background: url(../images/sun.png) no-repeat;
            }
            &.night {
                background: url(../images/moon.png) no-repeat;
            }
        }
    }
    .presenceChild {
        margin-bottom: 5px;
        border-bottom:1px lightgrey solid;
        cursor:pointer;
        border-radius:0px;
        border-left:none;
        border-right:none;
        border-top:none;
    }
    .legende {
        background-color:white;
        border:1px lightgrey solid;
        cursor:default;
        margin-bottom:10px;
        border-radius:4px;

        .nameLegend, .workLegend, .shiftLegend  {
            height:17px;
            padding:4px;
            color:$bluecolor;
            line-height:10px;
        }
    }
}

.periodWrap {

    .legendChild {
        font-size:14px;
        color: #0293ed;
        float:left;
        text-align:left;
        &.w20 {
            width:20%;
        }
        &.phase {
            padding-left: 2%;
        }
        &.name {
            padding-left: 3.8%;  
        }
        &.section {
            padding-left: 6%;
        }
        &.xp {
            padding-left: 5%;
        }
        &.state {
            padding-left: 4%;
        }
    }
    .topLegend {
        margin: 0 0 0 5px;
        width: 99%;
        height:auto;
        border:1px solid lightgrey;
        border-radius:4px;
        .type {
            font-size:14px;
            color: #0293ed;
            padding:5px;
            float:left;
            margin-right:24px;
            &.period {
                margin-left: 65px;
                margin-right: 40px;
            }
        }
    } 
    .topListChild {
        width:99%;
        margin:5px;
        border-radius: 4px;
        border-bottom:1px lightgrey solid;
        padding:5px 0;
        &.aktive {   
            background-color: rgba(29,201,153,0.2);
        }
        &.inaktive {
            background-color:$facegrey;
        }
        &.entry {
            background-color:rgba(237,111,1,0.2) 
        }
        .periodStatusIcon {
            border-radius: 50%;
            width: 8px;
            height: 8px; 
            float:left;
            margin:7px 30px 7px 16px;
            &.aktiv {   
                background-color: $greencolor;
            }
            &.inaktive {
                background-color:grey;
            }
            &.entry {
                background-color:#ed6f01; 
            }
        }
        .periodType, .workers {
            font-size:12px;
            color:$black;
            line-height:19px;
            padding:3px;
            float:left;
            margin:0 20px;
        }
        .workers {
            margin-right:40px;
        }
        .periodType {
            margin-right:42px;
        }
        .buttons {
            display:inline-block;
            margin: 0 45px;
            float:left;
            .btn-white {
                border:1px solid $bluecolor;
                color:$bluecolor;
            }
            .subButton.btn-blue, .subButton.btn-green, .subButton.btn-red, .subButton.btn-grey, .subButton.btn-white {
                float:left; 
                border-radius:4px;
                -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
                box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
                font-size:12px;
                margin:2px 15px 0 15px;
            }
            .startPlanning {

            }
            @include respond-to(normal)         { margin:0;}

        }
    }
}


.tabNav {
    width:100%;
    margin:0 0;
    height:auto;
    float:left;
    border: 1px solid #cccccc;
    text-align:center;

    /*border-radius: 4px;
    border-top-left-radius:0px;
    border-top-right-radius:0px;*/

    .tabChild {
        border-left: 1px solid #e5e5e5;
        color: #404040;
        cursor: pointer;
        float: left;
        padding:5px;
        font-size: 14px;
        height: auto;
        line-height: 35px;
        transition: background 0.1s ease 0s;
        width: 25%;
        &:hover {
            background-color: #f0edeb;
            color: #262626;
        }
        &.active {
            background-color: #dbdbdb;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2) inset;
            color:white;
        }
    }
    &.w100 {
        width:100%;
    }
}


.calHeader {
    width:100%;
    text-align: center;
    font-size: 16px;
    background-color:$facegrey;
    height:auto;
    padding:5px 0;
    margin: 30px 0 0;
    float:left;
}

.datePickWrapper {
    width:100%;
    height:auto;
    float:left;
    padding:15px;

    ul.yearCalendarWrapper.dateChooser {
        width:94%;

        .miniCalYears {
            display:none;
            .miniCal .monthGrid.active  {
                background-color: none;
            }

            &.active {
                display:block;
                .twoTableWrap {
                    position:relative;
                    width:100%;
                }
            }
        }
        .tableContent {
        }
    }

    .clone {
        height: 125px;
        left: 0px;
        top: 23px;
        position: absolute;
        width: 100%;
        z-index: 1;
        tr {
            width:auto;
            height:25px;
            td{
                width:auto;
                height:25px;
                cursor:pointer;
                &.active {
                    background-color: rgba(29,201,153,1);
                    .monthGrid .gridHeader {
                        color:white;
                    }
                }
                &.activePeriod {
                    background-color: rgba(29,201,153,0.2);
                }
                &.activeDeadline {
                    background-color: #ed6f01;
                    .monthGrid .gridHeader {
                        color:white;
                    }
                }
                &.deadlinePhase {
                    background-color: rgba(237,111,1,0.2)
                }
                &.holidayMark {
                    /*background: url(../images/holiday.png) no-repeat;*/
                    background-color:red;
                    .gridHeader {
                        color:white;
                    }
                    .table td .monthGrid  {
                        border-bottom:none;
                    }
                }

            }
        }
        &.secondMonth {
            left: 0px;
            top: 22px;
        }
    }

    .datePickWrapper li.miniCalYears {


        /*tr.clone, td.clone {clone
        
            position:absolute;
        }*/
    }

    ul.yearCalendarWrapper li.miniCalYears {
        width: 50%;
        display:none;

    }

    .skipTwoMonths {
        width:3%;
        height:25px;
        float:left;
        margin-top:80px;
        cursor:pointer;
        &.left {
            background: url(../images/arrow-left-cal.png) no-repeat; 
        }      
        &.right {
            background: url(../images/arrow-right-cal.png) no-repeat; 
        }
    }
    table td.thismonth.period{
        &.entryRuler {
            color:blue;
        }
    }
    .colorLegendWrapper {
        width:100%;
        height:auto;
        float:left;
        margin: 15px 0;
        .colorIcon {
            width:60px;
            height:30px;
            float:left; 
            &.green {
                background: url(../images/colorlegend.png) no-repeat;
                margin: 0 0 0 38px;
            }
            &.orange {
                background: url(../images/colorlegendorange.png) no-repeat;
                margin: 0 0 0 38px;
            }
            &.holiday {
                background: url(../images/holiday.png) no-repeat;
                margin: 0 0 0 38px;

            }
        }
        .colorLegendDescription {
            font-size:14px;
            float:left;
            line-height:25px;
            margin-left: 15px;
        }
    }

}

.tabWrapper {
    width:100%;
    height:auto;
    float:left;
    padding:15px;

    &.overview {

    }

    &.machines {

    }

    .tabHeading {
        width:90%;
        float:left;
        font-size: 14px;
        color:$bluecolor;
        border: 1px solid #cccccc;
        border-radius:4px;
        line-height:35px;
        background-color: $facegrey;
        margin: 20px 5%;
        text-align:center;

        .infoIcon {
            width:19px;
            height:19px;
            float:left;
            background: url(../images/info.png) no-repeat;
            margin: 7px 0 0 10px;
        }
    }

    .tabLegend {
        width:100%;
        float:left;
        height:auto;
        border:1px solid  #cccccc;
        border-radius: 4px; 
        background-color: #f9f9f9;
        margin-bottom:5px;
        overflow:hidden;
        .tabLegendChild {
            float:left;
            width:25%;
            padding:5px;
            font-size:14px;
            color:$bluecolor;

            &.employee {

            }
            &.rules {
                background-color:white;
            }
            &.first {
                text-align:left;
                padding-left:4%;
            }
        }
    }
    &.rulePlanning {
        .ruleWrapper {
            width:100%;
            height:auto;
            float:left;
            .singleRule{
                background-color:#f9f9f9;
                width:100%;
                float:left;
                line-height: 25px;
                margin-bottom:5px;
                overflow:hidden;
                border-radius:4px;
                cursor:pointer;
                .ruleDetails {
                    float:left;
                    width:100%;
                    height:0px;
                    background-color:$facegrey;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;
                }
                &.activeAccordeon {
                    .ruleDetails {
                        height:auto;
                        padding:5px;
                    }
                }

                .ruleItem{
                    width:25%;
                    height:auto;
                    float:left;
                    font-size:13px;                  
                    &.name {
                        padding: 5px 0 5px 35px;
                        text-align:left;

                    }
                    &.type {
                        padding: 5px 0 5px 70px;
                        text-align:left;
                    }
                    &.attribute {
                        padding-left:45px;
                        .inputfield {
                            width:60%;
                            margin-left:45px;
                            margin-top:5px;
                            text-align: center;
                            background-color: transparent;
                        }
                        p {
                            line-height: 20px;
                            input {
                                background-color:transparent;
                                width:20%;
                                margin-left: 30px;
                                color:$bluecolor;
                            }
                        }
                    }
                    &.state {
                        .switch {
                            margin: 7px 0 0 80px;
                        }
                    }
                }
            }
        }
    }
    &.employeePlanning {
        .employeeWrap {
            width:100%;
            height:auto;
            float:left;
            .singleEmployee{
                width:100%;
                float:left;
                height:auto;
                border-bottom:1px solid #cccccc;
                border-radius:4px;
                overflow: hidden;
                margin-bottom: 5px;
                .singleEmployeeHeader, .ruleHeader {
                    width:100%;
                    height:auto;
                    float:left;
                }

                &.inscribed {
                    background-color:rgba(29, 201, 153, 0.2);
                }
                &.pending {
                    background-color: rgba(237, 111, 1, 0.2);
                }
                .employeeDetails {
                    float:left;
                    width:100%;
                    height:0px;
                    background-color:white;
                    -webkit-transition: all 0.3s ease-in-out;
                    -moz-transition: all 0.3s ease-in-out;
                    -ms-transition: all 0.3s ease-in-out;
                    -o-transition: all 0.3s ease-in-out;
                    transition: all 0.3s ease-in-out;

                    .yearCalendarWrapper {
                        .twoTableWrap {
                            position:relative;
                        }
                        .miniCalYears {
                            width:50%;
                        }  
                        .clone {
                            height: 119px;
                            left: 0px;
                            top: 24px;
                            position: absolute;
                            width: 100%;
                            z-index: 1;
                            tr {
                                td{
                                    &.active {                               
                                        .monthGrid .gridHeader {
                                        }
                                    }
                                    &.activePeriod {
                                    }
                                    &.activeDeadline {
                                        background-color:transparent;
                                        .monthGrid .gridHeader {
                                        }
                                    }
                                    &.deadlinePhase {
                                        background-color:transparent;
                                    }
                                }
                            }
                            &.secondMonth {

                            }
                        }
                    }
                }
                &.activeAccordeon {
                    .employeeDetails {
                        height:215px;
                        padding:5px;
                    }
                }
                .employeeItem{
                    width:20%;
                    height:auto;
                    float:left;
                    font-size:12px;
                    cursor:pointer;
                    &.status {
                        padding-left:15px;
                        .periodStatusIcon {
                            border-radius: 50%;
                            width: 8px;
                            height: 8px; 
                            float:left;
                            margin: 10px 0 10px 16px;

                            &.aktive {   
                                background-color: $greencolor;
                            }
                            &.inaktive {
                                background-color: rgba(237, 111, 1, 1);
                            }
                        }
                    }
                    &.name {
                        float:left;
                        padding: 8px 0 8px 35px;
                        text-align: left;

                    }
                    &.field {
                        text-align:left;
                        padding: 8px 0 8px 65px;
                    }
                    &.xp {
                        padding: 8px 5px 8px 35px;
                        .xpIcon {
                            border-radius: 50%;
                            width: 8px;
                            height: 8px; 
                            float:left;
                            margin: 0 5px;

                            &.full {   
                                background-color: $bluecolor;
                            }
                            &.empty {
                                background-color:#bbe5ff;   
                            }
                        } 
                    }
                    &.switch {
                        margin: 2px 0 ;
                    }
                }
            }
        }
    }
}

.algoPlan {
    .algoWrap {
        width:100%;
        height:auto;
        padding:15px;
        float:left;
    }
    .btn-blue{
        &.algo {
            border-radius: 4px;
            height: auto;
            margin: 20px 33.3333%;
            padding: 10px;
            width: 33.3333%;
            cursor:pointer 
        }
    }
}

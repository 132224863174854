/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 16.09.2014, 10:23:45
    Author     : falkmichel test 
*/

$break-small: 600px;
$break-medium: 768px;
$break-large: 1024px;
$break-wide: 1444px;
$break-small-landscape: 568px;

$bluecolor: #0293ed; 
$greencolor: #1cb07a;
$lightergrey: #d3d3d3;

@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (max-width: $break-small) { @content; }
        @media only screen and (min-device-width: $break-small + 1) and (max-width: $break-small-landscape - 1) and (orientation : landscape) { @content; }
    }
    @else if $media == mobile-landscape {
        @media only screen and (min-device-width: $break-small + 1) and (max-width: $break-small-landscape - 1) and (orientation : landscape) { @content; }
    }
    @else if $media == tablet-small {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium - 1) { @content; }
        @media only screen and (min-device-width: $break-medium + 1) and (max-width: $break-large - 1) and (orientation : landscape) { @content; }
    }
    @else if $media == tablet-small-landscape {
        @media only screen and (min-device-width: $break-medium + 1) and (max-width: $break-large - 1) and (orientation : landscape) { @content; }
    }
    @else if $media == tablet-large {
        @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == normal {
        @media only screen and (min-width: $break-large) and (max-width: $break-wide - 1) { @content; }
    }
    @else if $media == wide {
        @media only screen and (min-width: $break-wide) { @content; }
    }
} 


.fixedtableWrapper {
    width:100%;
    height:100%;
    overflow:hidden;
    position: absolute;

}

table {
    margin:0 !important;
    padding:0 !important;
    border-collapse: collapse !important;
    border-spacing: 0 !important;
     background-clip:padding-box;
     border-collapse: collapse;
}

/* THEAD STYLE */ 

table.dataTable thead th, table.dataTable thead td {
    color:$bluecolor;
    font-weight:400;
}

.dataTables_wrapper .dataTables_filter {
    float:left;
    text-align: left;
}

.dataTables_wrapper .dataTables_filter input {
    margin:0;
}

.planStat {
     tr:hover {
         background: $lightergrey;
     }
}

/* Odd Column Fixed */
table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
    width:50px;
    background-color: initial;
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid #ededed;
    color:$bluecolor;
}
/* straight Column Fixed */
table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
    width:50px;
    background-color: initial;
    border-right: 1px solid #ededed;
    border-bottom: 1px solid lightgrey;
    color:$bluecolor;
}


tr.odd, tr.even, sorting_1 {
    border-bottom:1px solid lightgrey !important;
    padding:0;
}

.sorting_1 {
    padding:6px !important;
}

tr.odd td.weekend, tr.even td.weekend {
    background-color:#e8e8e8;
}

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: white;
}

tr.odd td.holiday, tr.even td.holiday{

}

table.dataTable thead th, table.dataTable thead td {
    border-top:1px lightgrey solid;
    &:first-child {
        border-left:1px lightgrey solid;
    } 
}

tr.odd td, tr.even td{
    color:grey;
    font-size:12px;
    height:75px !important
}

/* Cloned Thead Fix*/
.dataTables_scrollBody .sorting_asc, .dataTables_scrollBody .sorting_desc, .dataTables_scrollBody .sorting {
    height:0px;
    line-height:0;
    font-size: 0;
}

table.dataTable thead th, table.dataTable thead td {
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    padding: 10px 18px;
    width: 125px !important;
}

/* left fixed column */
table.dataTable tbody th, table.dataTable tbody td {
    padding: 0px 10px;
    vertical-align: middle;
    height:52px;
}
table.dataTable tbody tr {
    &:hover {

    }
}
/* linke spalte fixed width */

.DTFC_LeftBodyWrapper {}

.DTFC_LeftBodyLiner {
    width:60px;
    overflow-y: hidden !important;
}

tr.odd, tr.even {
    height:71px !important;
}

.sorting {
    font-size: 13px;
}

/* leeres feld oben links */
table.dataTable thead .sorting_asc {
    background-color:#daf1ff;
}

table.dataTable thead .sorting_desc {
    background-color:#daf1ff;
}

.currentShiftIcon {
    height:16px;
    width:16px;
    float:left;

    &.day {
        background: url(../images/sun.png) no-repeat;
    }
    &.night {
        background: url(../images/moon.png) no-repeat;
    }
}

.odd span, .even span {
    font-weight: 700;
   
}

table.dataTable thead th, table.dataTable thead td {

    margin:0 5px;
}

.planGrid {
    margin: 0 5%;
    width: 90%;
    border-bottom:1px grey solid;
    font-weight: 700;
    line-height:55px;
}

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
    border-top: none;
}

.cellBorder {
    width:100%;
    float:left;
    height:1px;
    background-color:#ededed;
}
.infoBox {
    border-bottom:1px solid lightgrey;
    height:50px;
    width:100%;
    float:left;
    margin-top: 5px;
    display:block;
    vertical-align:bottom;

    .timeLine {
        width:100%;
        height:6px;
        float:left;
        margin-bottom:3px;

        &.night{
            padding-left:50%;
        }

        .early {
            height:inherit;
            float:left;
            width: 50%;
            background-color:orange;
            border-radius:4px;
        }
        .day {
            float:left;
            height:inherit;
            width: 50%;
            background: orange;
            border-radius:4px;
        }
        .night {
            float:left;
            height:inherit;
            width: 100%;
            background: $bluecolor;
            border-radius:4px;
        }
        .allday {
            float:left;
            height:inherit;
            width: 100% ;
            border-radius:4px;
            background: orange;
        }
        .holiday {
            float:left;
            height:inherit;
            width: 100%;
            border-radius:4px;
            background: red;
        }
    }
}

.cellHeader {
    font-weight:700;
}


/*table {
    width:100%;
    height:100%;

    thead {
        background-color:whitesmoke;
    }
    tr {
        border: 1px solid grey;
    }
    td {
        border:1px solid blue;
        &:first-child {
            width:45px;
            background-color:lightblue;
        }
    }
}
}
*/
div[data-react-enrollments] {
  margin-bottom: 10px;
}

table.enrollmentTable {

  border-spacing: 3px !important;
  border-collapse: separate !important;;

  thead td {
    font-weight: bold;
  }

  thead td:nth-child(1) {
    width: 180px;
  }

  thead td:nth-child(2) {
    width: 180px;
  }

  thead td:nth-child(3) {
    width: 80px;
  }

  thead td:nth-child(4) {
    width: 50px;
  }
}
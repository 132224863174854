/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 13.01.2015, 15:28:36
    Author     : andreschmidt
*/

$break-small: 469px;
$break-small-landscape: 568px;
$break-medium: 800px;
$break-large: 1024px;
$break-wide: 1444px;

$black: #2e3133;
$colorPrimary:#0293ed;
$colorError:#b07a1c;
$colorSuccess:#1cb07a;
$endColor: #0074bd;
$facegrey: #f3f3f3;
$greencolor: #1cb07a;
$lightgrey: #cccccc;
$lightergrey: #d3d3d3;
$mediumgrey: #999;
$grey: #666;
$colorWhite: #fff;
$textColorDefault: #666;
$whitesmoke: #fafafa;
$blueBackground:  #daf1ff;
$buttonRed: #e23939;
$buttonGreen: #29B87E;

$boxshadow: #b1b1b1;
$hoverBackgroundText: #337ab7;
$buttonPrimaryColor: #0288D1;
$hoverBorderColor: #aaaaaa;

$fontsizeLarge:18px;
$fontsizeSmall:12px;

/* alias */
$startColor: $colorPrimary;
$bluecolor: $colorPrimary;

//An easy to use media-query mixin

@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == mobile-landscape {
        @media only screen and (min-device-width: $break-small + 1) and (max-width: $break-small-landscape - 1) and (orientation : landscape) { @content; }
    }
    @else if $media == tablet-small {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium - 1) { @content; }
    }
    @else if $media == tablet-large {
        @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == normal {
        @media only screen and (min-width: $break-large) and (max-width: $break-wide - 1) { @content; }
    }
    @else if $media == wide {
        @media only screen and (min-width: $break-wide) { @content; }
    }
}

@mixin linear-gradient($startColor, $endColor){
    background: $startColor;
    background: -moz-linear-gradient(top,  $startColor 0%, $endColor 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$startColor), color-stop(100%,$endColor));
    background: -webkit-linear-gradient(top,  $startColor 0%,$endColor 100%);
    background: -o-linear-gradient(top,  $startColor 0%,$endColor 100%);
    background: -ms-linear-gradient(top,  $startColor 0%,$endColor 100%);
    background: linear-gradient(to bottom,  $startColor 0%,$endColor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$startColor}', endColorstr='#{$endColor}',GradientType=0 );
}

@mixin box-shadow($shadows...) {
    -webkit-box-shadow: $shadows;
    -moz-box-shadow: $shadows;
    box-shadow: $shadows;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition($transitions...) {
    -webkit-transition: $transitions;
    -moz-transition: $transitions;
    -o-transition: $transitions;
    transition: $transitions;
}

@mixin msDefaultBoxShadow() {
    box-shadow:0px 0px 4px -1px #a3a3a3;
    -webkit-box-shadow:0px 0px 4px -1px #a3a3a3;
}

@mixin noSelect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin vendor-prefix($name, $argument) {
	-webkit-#{$name}: #{$argument};
	-ms-#{$name}: #{$argument};
	-moz-#{$name}: #{$argument};
	-o-#{$name}: #{$argument};
	#{$name}: #{$argument};
}

%clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

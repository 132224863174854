/*
Copyright (C) 2014 jonathanberroth

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program.  If not, see <http://www.gnu.org/licenses/>.
*/
/* 
    Created on : 18.10.2014, 14:07:31
    Author     : jonathanberroth
*/

@import 'utils';

.notification {
    @include box-shadow(inset 0 0px 2px rgba(0, 0, 0, 0.2));
    
    -moz-transition: all .218s;
    -webkit-transition: all .218s;
    transition: all .218s;
    background: #daf1ff;
    display: inline-block;
    border: 1px solid #0293ed;
    color: #0293ed;
    font-size: 11px;
    font-weight: bold;
    padding: 7px 15px;
    margin-top: 14px;
}

.notification.error {
    border-color: red;
    background-color: lightcoral;
    color:red;
}


.notification.success {
    border-color: rgb(0, 163, 0);
    background-color: rgba(164, 221, 169, 0.33); 
    color:  rgb(0, 163, 0);
}

.inputResponse {
    width: 100%;
    margin: 0px !important;
    padding: 2px !important;
    font-size: 12px;
    color: #e10b05;  
}

label .inputResponse {
    width: auto;
    float: right;

}
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 07.08.2014, 12:43:48
    Author     : Falk bravo bravo deltAaaaa hganz neu
*/

@import 'utils';

$bluecolor: #0293ed;
$greencolor: #1cb07a; 
$lightgrey: #c6cacc; 
$black: #2e3133;
$whitesmoke: #fafafa;
$facegrey: #F3F3F3;

.background-striped-color{
    background: repeating-linear-gradient(135deg,
            #FFF, #FFF .25em /* white stripe */,
            #dbdbdb 0, #dbdbdb .75em /* lightGrey stripe */
    );
}

div.fc-content #eventImgIcon{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 85%;
    top: 7px;
    margin-top: -6px;
}
div.fc-content #eventImgIconOk{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 85%;
    top: 7px;
    margin-top: -6px;
}

#approve-vacancy {
    display:none;

    .modal-content {
        width: 250px;
    }

    .close {
        font-size: 20px;
        color: black;
        font-weight: 700;
    }
}

#table-scroll{
    width: 100%;
    height: 100%;
    max-height: 50px;
    margin: 0;
    padding: 0;
    overflow: auto;
}

#sortable1{
    min-height: 40px;
    list-style-type: none;
    margin: 0;
    padding: 5px 5px 5px 5px;
    background: white;
}

#sortable3 {
    min-height: 40px;
    list-style-type: none;
    padding: 5px 5px 5px 5px;
    background: white;
    margin-top: 85px;
}

#sortable1 li{
    padding: 5px;
    font-size: 1.2em;
    border: 0.001px dashed #c5c5c5;
    text-align: left;
    padding-left: 10px;
}

#sortable3 li{
    padding: 5px;
    font-size: 1.2em;
    border: 0.001px dashed #c5c5c5;
    background: rgba(255,255,255,0.7);
}

#sortable2 li{
    padding: 5px;
    font-size: 1.2em;
    border: 0.001px dashed #c5c5c5;
    background: rgba(255,255,255,0.7);
}

.isQualified, .isNotQualified, .isWorking, .isNotWorking, .isOnVacation, .isNotOnVacation{
    position: relative;
    left: 0;
    top: 0;
}
.isQualified{
    background-image: url("../images/Q.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20px 20px;
    background-position-x: 95%;
    width: 20px;
    position: relative;
    height: 20px;
    display: inline-block;
    float: right;
}

.isNotQualified{
}

.isWorking{
    background-image: url("../images/W.png") ;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20px 20px;
    background-position-x: 95%;
    width: 20px;
    position: relative;
    height: 20px;
    display: inline-block;
    float: right;
}

.isNotWorking{
    background-image: url("../images/W.png"), url("../images/schoolfreeware_Cancel.png") ;
    background-repeat: no-repeat;
    background-position: right;
    background-size: 20px 20px;
    background-position-x: 95%;
    width: 20px;
    position: relative;
    height: 20px;
    display: inline-block;
    float: right;
}

.isOnVacation{
    background-image: url("../images/V.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 25px 25px;
    background-position-x: 95%;
    width: 20px;
    position: relative;
    height: 20px;
    display: inline-block;
    float: right;
}

.isNotOnVacation{

}

#sortable2{
    height: 220px;
    max-height: 220px;
    list-style-type: none;
    margin-top: 50px;
    padding: 5px 5px 5px 5px;
    background: #fff;
    border: 15px solid #000000;
    border-image: url("../images/border-corner.png") 34% repeat;
    background-image: url("../images/drop_here.png") ;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -50;
}

#table-legend{
    .table-header{
        padding-left: 40px;
        padding-right: 40px;
    }
    .table-div{
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
        font-weight: bold;
    }

}

.searchListWrapper {
    &.calendar {
        left:0;
        width:400px;    

        @include respond-to(wide)   { 
            width:400px;
        }
        @include respond-to(normal) {
            width:350px;
        }
        @include respond-to(break-medium) {
            width:300px;
        }
        @include respond-to(tablet-large)   { 
            width:250px;
        }
        @include respond-to(tablet-small)   { 
            width:200px;
        }
        @include respond-to(mobile) {
            display:none;
        }
    }
}

.mainContentWrapper {
    &.calendar{
        top: 0px;
        left: 400px;
        bottom:0px;
        right: 0px;

        @include respond-to(wide)   { 
            left:350px;
        }
        @include respond-to(normal) {
            left:350px;
        }
        @include respond-to(break-medium) {
            left:300px;
        }
        @include respond-to(tablet-large)   { 
            left:250px;
        }
        @include respond-to(tablet-small)   { 
            left:200px;
        }
        @include respond-to(mobile) {
            left:0;
        }
    }
}

.searchList {
    position: absolute;
    bottom: 0px;
    top:52px;
    overflow-y: auto;
    overflow-x: hidden;
    &.date {
        top:0px;

    }
}

.listChild {
    width: 100%;
    float: left;
    border-bottom: 1px lightgrey solid;
    cursor: pointer;
    font-weight: 400;
    color: #646464;
    display:block;
    border-right:1px transparent solid;
    overflow: hidden;

    &.category {
        background-color: rgb(228, 228, 228);
        color: #646464;
        cursor: default;
        font-weight: 700;
        padding: 10px 4%;
        text-align: center;
    }
}
li {
    &.listChild {
        .time {
            float:left;
            height:auto;
            width: 60%;
            line-height: 21px;
            font-size: 12px;
            font-weight: 700;
            text-align: right;
            @include respond-to(normal)         { 

            }
            @include respond-to(tablet-large)   { 
                width:50%;
            }

            .listItem {
                &.datum {
                    width:50%;
                    font-weight: 700;
                    @include respond-to(normal)         { 
                        width:100%;
                        border-bottom:1px #e8e8e8 solid;
                    }
                    @include respond-to(tablet-large)   { 
                        width:100%;
                        border-bottom:1px #e8e8e8 solid;
                    }
                }
                &.zeit {
                    font-weight:400;
                    width:50%;
                    @include respond-to(normal)         { 
                        width:100%;
                    }
                    @include respond-to(tablet-large)   { 
                        width:100%;
                    }
                }
                &.type {
                    width:50%;
                    @include respond-to(normal)         {
                        padding-top: 0px;
                    }
                    @include respond-to(tablet-large)   {
                        width:40%;
                        padding: 20px 10px;
                    }
                }
            }
        }
        .listItem {
            &.datum, &.type, &.zeit {
                font-size: 13px;
                line-height:40px;
                text-align: center;
                display: block;
                float: left;
                clear: both;
                width: 100%;
            }

            &.type {
                color: #0293ed;
                border:none;
                float:left;
                font-weight: 700;
                @include respond-to(normal)         { 
                    padding-top: 0px;
                }
                @include respond-to(tablet-large)   { 
                    width:100%;
                    padding: 20px 10px;
                }
            }
        }
        &:hover {
            color: rgb(139, 139, 139);
            border-right: 1px #1cb07a solid;
        }
        &.active {
            background-color: #DAF1FF;
        }
    }
}

.singleBlockWrapper {
    width: 100%;
    float: left;
    height: auto;
    padding: 10px;

    .blockStyle {
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        background-color: white;
        padding: 10px 15px;
        margin-bottom: 20px;
    }
}


.views {
    width: auto;
    border: 1px rgb(228, 228, 228) solid;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
    li {
        border-right: 1px solid rgb(228, 228, 228);
        color: #646464;
        cursor: pointer;
        float: left;
        font-size: 14px;
        padding: 4px 0;
        text-align: center;
        width: 60px;
        &:last-child {
            border-right: none;
        }
    }
    .active {
        background-color: #0293ed;
        color:white;
    }
}

.wrapperCalendar {
    width: 100%;
    height:auto;
    float: left;
    border-top: 1px #dedede solid;
    padding: 15px;
    &.week {
        border:none;
        padding:15px; 
    }
    &.month li li {
        border:none;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        max-height: 605px;
        height:auto;
        margin-bottom: 20px;
        tr, td {
            border-collapse: collapse;  
        }
        &.disabled {
            display:none;
        }
    }
}

.headerCalendar {
    padding: 10px 0 20px;
    height: 54px;
    float: left;
    display: inline-block;
    width: 100%;
    border-bottom: 1px #dedede solid;
    font-size: 16px;
    position: relative;
    margin-bottom:15px;
    ol{
        float: none;
        width: auto;  
    }
    li {
        display: inline;
        position: relative;  
    }
    .wrapperNextWeek {
        float:left;
        .leftArrow, .rightArrow {
            display:inline-block;
            border: 6px black solid; 
            cursor: pointer;
            margin: 0 -4px 8px;
            opacity: 1;
            &:hover{
                opacity: 0.75;
            }
        }

        .leftArrow {
            left: 150px;
            border-color: transparent #0293ed transparent transparent ;
        }
        .rightArrow {
            right: 150px;
            border-color: transparent transparent transparent #0293ed;
        }

    }
}



.months {
    display: inline-block;
    position:relative;
    height: auto;
    width: auto;
    position:relative;
    webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;  
    li {
        padding: 5px 10px;
        width:auto;
        float:left;
        margin: 0px 5px;
    }
    .inactiveTime {
        opacity: 0.5;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
    .activeTime {
        background-color: white;
        border-radius:4px;
        border:2px #0293ed solid;
        padding: 3px 10px 2px !important;
    }
}

.wrapperYear, .wrapperMonth {
    float: right;
    font-size: 16px;
    color:#028fe0;
    height: 24px;
    line-height: 22px;
    vertical-align: bottom;
    padding-left:10px;

}
.timeCol {
    width: 90px;
    text-align: center;
    font-size: 15px;
    border-collapse: collapse;
    color: #9F9F9F;
}

.fc-view table, 
#matrix-statistics-container table, 
.advancedAbsencesView table, 
.day-schedule-selector table {
    table-layout:fixed;
    width: 100%;
    border-collapse: collapse;
    tr{
        .dayheading {
            height: 15px;
            width: 100px;
            text-align: center; 
            &.month {
                text-align:left;
            }
        }
        .subheading {
            height: 20px;
            min-height: 20px;
        } 
        &.activeWeek {
            background-color: #DBFFF5;
            .activeDay {
                .activeDate {
                    background-color: #1dc999;
                    color: #FFFFFF;
                    padding: 1px 2px;
                    border-radius: 4px;
                    width: 20px;
                    text-align: center;
                    float: right;
                    margin: -1px -5px;
                }
            }
        }
    }
    td {
        vertical-align: top;
        background-clip: padding-box;
        &.timeCol {
            width: 90px;
            text-align: center;
            font-size: 15px;
            border-collapse: collapse;
            color: #9F9F9F;
            .stHour {
                float: left;
                height: 31px;
                width: 100%;

            }
            .normHour {
                float: left;
                height: 21px;
                width: 100%;
            }
            .time {
                float:left;
                height:21px;
                width: 100%;
                line-height: 21px;
                font-size: 12px;
                font-weight: 700;
                padding-right: 20px;
                text-align: right;
                /*color: #9F9F9F;
                height: 20px;*/
            }
        }
        .gridHeader {
            color: #404040;
            padding: 0 3px;
            text-align: right;
        }


        .monthGrid {
            border-bottom: 1px #dedede solid;
            width: 90%;
            margin: 0 5%;
            text-align: left;
            vertical-align: top;
            cursor: pointer;
            font-size: 13px;
            padding: 5px 0;
            height: 80px;
            -webkit-transition: height 0.5s ease-in-out;
            -moz-transition: height 0.5s ease-in-out;
            -o-transition: height 0.5s ease-in-out;
            transition: height 0.5s ease-in-out;

            &.weekend {
                color: #0293ed;          
            }
        }
        .gridContent {
            width:100%;
            height:auto;
            padding:0px 5px;
            margin-top:15px;
            border-radius:4px;
            line-height:18px;
            font-size:13px;
            &.specialShift {
                background-color: rgb(255, 95, 95);
                color:white;
            }
            &.gapShift {
                background-color:lightgrey;
                color:white;
                /*color:#0293ed;*/
            }
            &.holiday {
                background-color:whitesmoke;
            }
        }
        &.thismonth {
            .monthGrid {
                color: #0293ed;  
            }
            &.weekend {
                background-color: rgba(69, 84, 131, 0.05); 
            }
        }
        &.nextmonth{
            &.weekend {
                background-color: rgba(69, 84, 131, 0.05); 
            }
        }
        &.lastmonth {
            &.weekend {
                background-color: rgba(69, 84, 131, 0.05); 
            }
        }
        &.lastmonth .monthGrid, &.nextmonth .monthGrid {
            opacity: 0.6;
        }

        &.greyDay {
            background-color: rgb(244, 244, 244);
        }
        &.dayCol {
            position: relative;
            height:504px
        }
        
        &.avtive
        
        .thisDay {
            margin: 0 5%;
            width: 90%;
            border-bottom: 1px #D7D4D4 solid;
            font-size: 13px;
            padding: 5px 0;
            color: #0293ed;
            background-color: white;
            &.grey {
                color:#9F9F9F;
            }
        }
        &.allDay {
            border-bottom: 3px #0293ed solid;  
            &.timeCol {
                border: none;
            }

            &.greyDay {
                border-bottom: 3px #ABABAB solid;
            }
            &.activeDay {
                background-color: #DBFFF5;
                border-color: #1dc999;
            }
        }
        .dayWrapper {
            z-index:100;
            position: absolute;
            top:0;
            left: 0;
            bottom: 0;
            right: 0;
            &.activeDay {
                background-color: #DBFFF5;
                border-color: #1dc999;
            }
        }
        .dayGrid {
            float:left;
            border-bottom: 1px lightgray solid;
            height:21px;
            width:90%;
            margin: 0 5%;
            &:nth-child(odd) {
                border-bottom:1px lightgray dotted;
            }
        }
        .eventChild {
            position:absolute;
            float:left;
            width:90%;
            left: 0;
            margin: 0 5%;
            background-color: #028fe0;
            border-radius: 4px;
            z-index: 100;
            cursor: pointer;
            &.bottom {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
            &.top {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            &:hover {
                z-index: 200;
                opacity: 0.9;
                .eventContent, .eventContentHead {
                    color:white;
                }
            }
            .eventHeader {
                float: left;
                font-size: 12px;
                color: white;
                height: 20px;
                line-height: 14px;
                width: 100%;
                padding: 3px 7px;
                background-color: rgba(0,0,0,0.1);
            }
            .eventContentHead, .eventContent {
                display:block;
                float:left;
                width:100%;
                text-align: center;
                color: rgb(222, 222, 222);
                padding:5px;
                font-size: 12px;
                font-weight: 700;
                -moz-hyphens:auto;
                -ms-hyphens:auto;
                -webkit-hyphens:auto;
                hyphens:auto;
                word-wrap:break-word;
                -webkit-hyphens: none;
                -moz-hyphens: none;
                -ms-hyphens: none;
                hyphens: none;
            }
            .eventContent {
                text-align: left;
                font-weight:400;
                font-size: 13px;
                line-height: 14px;
            }
        }
    }
}

.yearCalendarWrapper {
    display: block;
    float: left;
    width: 100%;
    .miniCalYears {
        width:25%;
        float:left;
    }
}

.miniCal {
    padding: 10px;
    text-align: center;
    .monthGrid{
        height: 25px;
        .gridHeader {
            background-color:transparent;
            color:#0293ed;
            text-align:center;
            width:100%;
        }
        &.active {
            background-color: #1dc999;
            .gridHeader {
                color:white;
            }
        }
    }
    .monthHead {
        width:100%;
        height:15px;
        text-align:center;
        font-size: 11px;
        font-weight:700;
        color:#0293ed;
    }

    .thisDay {
        text-align: center;
    }
    table {
        margin-bottom: 5px;
    }
}

.minical {
    tr{
        &.dayheading {
            height: 15px;
            width: 100px;
            text-align: center;
            &.month {
                text-align:left;
            }
        }

    }
}

.newDateWrap {
    width:100%;
    height:60px;
    margin: 0 auto; 
    float:left;
    text-align:center;
    .buttonPopup, .buttonCancleRequest {
        margin:0;
        padding: 8px 15px;
        cursor:pointer;
        width:120px;
        text-align: center;
        height: 30px;
        border-radius: 4px;
        -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        font-size: 13px;
    }
    .buttonPopup{
        &.save {
            float:right;
        }
        &.setts {
            float:left;
            margin: 60px 0 0 15px;
        }
    }
    .btn-blue {
        border-color: #0E7DBF;
        color:white;
        background: #0293ed;
        background: -moz-linear-gradient(top,  #0293ed 0%, #0696d4 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0293ed), color-stop(100%,#0696d4));
        background: -webkit-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
        background: -o-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
        background: -ms-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
        background: linear-gradient(to bottom,  #0293ed 0%,#0696d4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0293ed', endColorstr='#0696d4',GradientType=0 );
    }
    .createButton {
        height: 30px;
        width: 80px;
        margin: 15px 4px;
        border-radius: 4px;
        -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        padding: 5px 15px;
        font-size: 13px;
        &.down {
            width:120px;
            float:right; 
            padding: 9px 0px;
            cursor:pointer;
            margin:0 15px;
            font-weight:400;
            vertical-align: top;
            &:hover {
                background: #DAF1FF;
                background: -moz-linear-gradient(top,  #028fe0 0%, #068dc7 100%);
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#028fe0), color-stop(100%,#068dc7));
                background: -webkit-linear-gradient(top,  #028fe0 0%,#068dc7 100%);
                background: -o-linear-gradient(top,  #028fe0 0%,#068dc7 100%);
                background: -ms-linear-gradient(top,  #028fe0 0%,#068dc7 100%);
                background: linear-gradient(to bottom,  #28A6F5 0%,#1598CF 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#028fe0', endColorstr='#068dc7',GradientType=0 );
            }
            &.active {
                border-color: #0E7DBF;
                color:white;
                background: #0293ed;
                background: -moz-linear-gradient(top,  #0293ed 0%, #0696d4 100%);
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0293ed), color-stop(100%,#0696d4));
                background: -webkit-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
                background: -o-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
                background: -ms-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
                background: linear-gradient(to bottom,  #016CAF 0%,#0071A2 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0293ed', endColorstr='#0696d4',GradientType=0 );
            }

        }
    }
}

.listChild {
    width: 100%;
    float: left;
    border-bottom: 1px lightgrey solid;
    cursor: pointer;
    font-weight: 400;
    color: #646464;
    display:block;
    border-right:1px transparent solid;
    overflow: hidden;

    &.category {
        background-color: rgb(228, 228, 228);
        color: #646464;
        cursor: default;
        font-weight: 700;
        padding: 10px 4%;
        text-align: center;
    }
}
li {
    &.listChild {
        .time-home {
            float:left;
            height:auto;
            width: 100%;
            line-height: 21px;
            font-size: 12px;
            font-weight: 700;
            text-align: right;
            @include respond-to(normal)         {

            }
            @include respond-to(tablet-large)   {
                width:100%;
            }

            .listItem {
                float:left;

                &.datum-home {
                    //width:33%;
                    font-weight: 700;
                    /*@include respond-to(normal)         {
                        width:50%;
                        border-bottom:1px #e8e8e8 solid;
                    }
                    @include respond-to(tablet-large)   {
                        width:100%;
                        border-bottom:1px #e8e8e8 solid;
                    }*/
                    width:100%;
                    border-bottom:1px #e8e8e8 solid;
                }
                &.zeit-home {
                    font-weight:400;
                    /*width:33%;
                    @include respond-to(normal)         {
                        width:50%;
                        border-bottom:1px #e8e8e8 solid;
                    }
                    @include respond-to(tablet-large)   {
                        width:100%;
                        border-bottom:1px #e8e8e8 solid;
                    }*/
                    width:100%;
                    border-bottom:1px #e8e8e8 solid;
                }
                &.type-home {
                    //width:33%;
                    color: #0293ed;
                    border:none;
                    font-weight: 700;
                    float: right;
                    /*@include respond-to(normal)         {
                        width:100%;
                        border-bottom:1px #e8e8e8 solid;
                        border-right:1px #e8e8e8 solid;

                    }
                    @include respond-to(tablet-large)   {
                        width:100%;
                        border-bottom:1px #e8e8e8 solid;
                        border-right:1px #e8e8e8 solid;
                    }*/
                    width:100%;
                    border-bottom:1px #e8e8e8 solid;
                    border-right:1px #e8e8e8 solid;
                }
            }
        }
        .listItem {
            &.datum-home, &.type-home, &.zeit-home {
                border-right:1px #e8e8e8 solid;
                float: left;
                font-size: 13px;
                line-height:40px;
                text-align: center;
            }
        }
        &:hover {
            color: rgb(139, 139, 139);
            border-right: 1px #1cb07a solid;
        }
        &.active {
            background-color: #DAF1FF;
        }
    }
}
/*
@media all and (max-width: 1550px) {
    .calendar{

    }
    .searchListWrapper {
        &.calendar{
            width: 250px;
        }
        .searchList {
            .listChild {

            }
        }
    }

    li {
        &.listChild {
            .time {
                float:left;
                width:50%;

                .listItem {
                    float:left;

                    &.datum {
                        display:block;
                        width:100%;
                    }
                    &.zeit {
                        border-bottom:none;
                        float:none;
                        width:100%;  
                    }

                }
            }
            .listItem {
                &.datum, &.type, &.zeit {
                    padding: 0 9px;
                    border-right:1px #e8e8e8 solid;
                    font-size: 13px;
                    line-height:40px;
                    border-bottom:1px #e8e8e8 solid;
                    width:50%;
                    box-sizing: border-box;    
                    text-align: center;
                }

                &.type {
                    border:none;
                    text-align: center;
                    width:50%;
                    line-height: 80px;
                }
            }
        }
    }
}



@media all and (max-width: 1000px) {
    .calendar {
     
        .singleBlockWrapper {
            padding: 0px;
        }
        .blockStyle {
            padding: 0px;
        }
    }
    nav {
        &.mainNavigation {
            ul.sidebar{
                li {
                    &.child {
                        width: 50px;
                        height: 50px;
                        background-size: 123px; }
                    .navTitle {
                        display: none;
                    }
                    &.home {
                        background-position: 14px 14px;
                    }
                    &.caldenar {
                        background-position: -36px 14px;
                    }
                    &.settings {
                        background-position: -86px 14px;
                    }
                }
            }
        }
    }

    .yearCalendarWrapper {
        .miniCalYears {
            width:33%;
            float:left;
        }
    }

    .wrapperCalendar {
        padding: 10px;
    }
}

@media all and (max-width: 755px) {
    .yearCalendarWrapper {
        .miniCalYears {
            width:50%;
            float:left;
        }
    }
}

@media all and (max-width: 500px) {
    .yearCalendarWrapper {
        .miniCalYears {
            width:100%;
            float:left;
        }
    }
}
*/
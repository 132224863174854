/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 12.08.2014, 10:26:05
    Author     : falkmichel watschnbaum
*/

@import 'utils';

header.stable {
    @include respond-to(mobile){
        height: 93px;
        background-color:$bluecolor;
        box-shadow:none;
    }
    @include respond-to(tablet-small)   { height: 50px; }
    @include respond-to(tablet-large)   { height: 50px; }
    @include respond-to(normal)         { height: 60px; }
    @include respond-to(wide)           { height: 60px; }


    .headLogo {
        display: block;
        padding-top: 5px;
    }

    .buttonHeader {
        @include respond-to(mobile)         {padding: 3px 5px; }
        @include respond-to(tablet-small)   {padding: 5px 10px; }
    }
}
.contentOnlyWrapper.login.blue {
    .loginHead {
        background-color: greenyellow;
        border-bottom:1px $bluecolor solid;
        width:100%;
        margin:0 auto;
        .loginText {
            color:white;
        }
    }

    .inputWrap {
        margin:7px 0;

        input {
            &.inputfield {
                border:none;
                border-radius:0;
                box-shadow:none;
                display:block;
                float: left;
                font-size: 16px;
                line-height:1.4em;
                padding:4px 7px;
                width:100%;
                color:$textColorDefault;
                background:#eee;

            }

            &:focus {
                color:$colorPrimary;
                border-bottom-color:$colorPrimary;
            }
        }

        &.hasIcon {
            position:relative;

            input {
                &.inputfield {
                    padding-left:40px;
                }
            }
        }

        .inputIcon {
            font-size:21px;
            position:absolute;
            left:5px;
            top:4px;
            color:$textColorDefault;

            &.passwordIcon {
                left:7px;
                font-size:23px;
            }
        }

        label {
            color:$colorPrimary;
            display: block;
            float: left;
            width:40%;
        }
    }

    .loginFlipContainer {
        width: 340px;
        height: 300px;
        margin-top: 20%;
        margin-bottom: 5%;
        @include respond-to(mobile-landscape) {
            margin-top: 20px;
        }
        @include respond-to(tablet-small) {
            margin-top: 30px;
        }
        @include respond-to(tablet-large) {
            margin-top: 40px;
        }
    }

    .loginWrapper {
        background-color: white; /*#0293ed;*/
        text-align: center;
        @include msDefaultBoxShadow();
        width: 340px;
        height:290px;
        .inner {
            padding:15px 25px;
        }
    }

    .titleContainer {
        width:100%;
        height:40px;
        line-height:30px;
        background-color:$colorPrimary;
        color:$colorWhite;
        text-align:center;
        font-size:$fontsizeLarge;
        padding:5px;
        text-transform: uppercase;
    }



    .options {
        margin:15px 0 10px;

        @include respond-to(mobile) {
            margin: 9px 15px 5px;
        }

        .radioCheck {
            .rememberMeCheckbox {
                float: left;
            }
            .rememberMeLable {
                color: $textColorDefault;
                float:left;
                padding-left:20px;
                cursor:pointer;
            }
        }
    }

    .buttonLog{
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1) inset;
        font-size: 16px;
        line-height:30px;
        margin:8px 0;
        padding:4px 7px;
        width:242px;
    }

    .buttonLogBack {
        width:32%;
        border-radius: 4px;
        box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.1) inset;
        font-size: 16px;
        line-height:30px;
        margin:8px 0;
        padding:4px 7px;
        @include linear-gradient(#ddd, #c3c3c3);
    }

    .logForgot {
        color:$colorPrimary;
        &:hover {
            text-decoration: underline;
        }
    }
}

.passwordConfirmLoginContainer {

    position:absolute;
    background-color: white; /*#0293ed;*/
    text-align: center;
    @include msDefaultBoxShadow();
    width: 340px;
    top:50%;
    left:50%;
    margin-top:-145px;
    margin-left:-170px;

    @include respond-to(mobile) {
        left: 0;
        right: 0;
        bottom: 0;
        top:0;
        margin: 0;
        width: auto;
        height: auto;
        background-color: $bluecolor;
    }

    .inner {
        padding:15px 25px;
    }

    .inputWrap {
        margin:7px 0;

        input {
            &.inputfield {
                border:none;
                border-radius:0;
                box-shadow:none;
                display:block;
                float: left;
                font-size: 16px;
                line-height:1.4em;
                padding:4px 7px;
                width:100%;
                color:$textColorDefault;
                background:#eee;
                @include respond-to(mobile){
                    width:100%;
                    line-height:25px;
                    height:50px;
                    background-color:transparent;
                    margin:0;

                }
            }

            &:focus {
                color:$colorPrimary;
                border-bottom-color:$colorPrimary;
            }
        }

        &.hasIcon {
            position:relative;

            input {
                &.inputfield {
                    padding-left:40px;
                }
            }
        }

        @include respond-to(mobile){
            margin: 0;
            width:100%;
            position:relative;
            height:50px;
            &:last-child{
                border-bottom:none;
            }
        }

        .inputIcon {
            font-size:21px;
            position:absolute;
            left:5px;
            top:4px;
            color:$textColorDefault;

            &.passwordIcon {
                left:7px;
                font-size:23px;
            }
        }



        label {
            color:$colorPrimary;
            display: block;
            float: left;
            width:40%;
        }
    }

    .titleContainer {
        width:100%;
        height:40px;
        line-height:30px;
        background-color:$colorPrimary;
        color:$colorWhite;
        text-align:center;
        font-size:$fontsizeLarge;
        padding:5px;
        text-transform: uppercase;

        @include respond-to(mobile) {
            background-color: transparent;
            color:$colorPrimary;
            width:100%;
            margin:0 auto;
            border:none;

        }
        .title {
            @include respond-to(mobile) {
                display:none;
            }
        }
    }
}

.form-help-message {
    font-size: 12px;
    margin-bottom: 20px;
}

.passwordResetContainer {
    position:absolute;
    top:50%;
    left:50%;
    width:340px;
    margin: -150px -170px;
    @include msDefaultBoxShadow();
    background-color:$colorWhite;
    border-radius:4px;

    @include respond-to(mobile)         {
        position:initial;
        width:100%;
        margin:0;
        top:0;
        left:0;
        height:250px;
    }

    input[type="submit"] {
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1) inset;
        font-size: 16px;
        line-height:30px;
        margin:20px 0 0 0;
        padding:4px 7px;
        width:100%;

        @include respond-to(mobile) {
            margin:10px 0;
            background:white;
            color:$bluecolor;
            width:90%;
            height:50px;
        }
    }

    input[type="text"],
    input[type="password"] {
        border:1px solid #eee;
        border-radius:0;
        box-shadow:none;
        display:block;
        float: left;
        font-size: 16px;
        line-height:1.4em;
        padding:4px 7px;
        width:100%;
        color:$textColorDefault;
        background:#eee;
        margin-bottom:10px;
        @include respond-to(mobile){
            width:100%;
            line-height:25px;
            height:50px;
            background-color:transparent;
            margin:0;

        }

        &:focus {
            color:$colorPrimary;
        }
    }

    label {
        padding:0;
        text-align:left;
        color:$textColorDefault;
    }

    .inner {
        padding:15px 25px;
    }

    .titleContainer {
        width:100%;
        height:40px;
        line-height:30px;
        background-color:$colorPrimary;
        color:$colorWhite;
        text-align:center;
        font-size:$fontsizeLarge;
        padding:5px;
        text-transform: uppercase;

        @include respond-to(mobile) {
            background-color: transparent;
            color:$colorPrimary;
            width:100%;
            margin:0 auto;
            border:none;

        }
        .title {
            @include respond-to(mobile) {
                display:none;
            }
        }
    }
}

.loginfoot {
    display:none;
}

.loginImpress {
    color: white;
    display: block;
    font-size: 12px;
    margin: 8px;
}
.loginCopyright {
    color: white;
    float: left;
    font-size: 12px;
    margin-top: 10px;
    width: 100%;
}

/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
}

/* flip the pane when hovered */
.flip-container .flipper {
    @include vendor-prefix(transform, rotateY(0deg));
}

/* flip the pane when hovered */
.flip-container.flip .flipper {
    @include vendor-prefix(transform, rotateY(180deg));
}

/* flip speed goes here */
.flipper {
    @include vendor-prefix(transition, 0.6s);
    @include vendor-prefix(transform-style, preserve-3d);
    position: relative;
}

.ie .flipper {
    @include vendor-prefix(transition, 0.2s);
}

/* hide back of pane during swap */
body:not(.ie) .front, body:not(.ie) .back {
    @include vendor-prefix(backface-visibility, hidden);
}

.front, .back {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}

/* front pane, placed above back */
.front {
    @include vendor-prefix(transform, rotateY(0deg));
}

/* back, initially hidden pane */
.back {
    top: -300px;
    @include vendor-prefix(transform, rotateY(180deg));
}

:not(.flip) .front, .flip .back {
    z-index: 2;
}
.acceptTermsForm {
    position: relative;
    top: -580px;
    z-index: 10;
    background-color: white;
}

.dropdown-lang-picker-login {
    margin-right: -30px;
}

.btn-languages-login {
    padding: 9px 13px;
    margin-bottom: 0;
}

.lang-pic-login {
    float: left;
    width: 50px;
}

.div-btn-login {
    margin-left: 50px;
}

.customRememberMeCheckbox {
    width: 23px;
    height: 21px;
    color: #0293ed;
}

.customRememberMeLabel {
    margin-left: 4px;
    margin-top: 5px;
    color: #0293ed;
    font-size: 14px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}

/* CSS Created by CSS CHECKBOX */
/**********************************/
/**** www.CSScheckbox.com *********/

/*general styles for all CSS Checkboxes*/
label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input[type=checkbox].customRememberMeCheckbox {
    clip: rect(0 0 0 0);
    height:1px;
    width:1px;
    margin:-1px;
    padding:0;
    border:0;
}

input[type=checkbox].customRememberMeCheckbox + label.customRememberMeLabel {
    padding-left:20px;
    height:15px;
    display:inline-block;
    line-height:17px;
    position: relative;
    top: 2px;
    background-repeat:no-repeat;
    background-position: 0 0;
    font-size:14px;
    vertical-align:middle;
    cursor:pointer;
}

input[type=checkbox].customRememberMeCheckbox:checked + label.customRememberMeLabel {
    background-position: 0 -15px;
    position: relative;
    top: 2px;
}

.customRememberMeLabel{
    background-image:url(../images/lite-blue-check.png);
}

/*specific classes related to Checkbox skins*/

.lite-green-check{background-image:url(http://csscheckbox.com/checkboxes/lite-green-check.png);}
.lite-blue-check{background-image:url(../images/lite-blue-check.png);}
.lite-gray-check{background-image:url(http://csscheckbox.com/checkboxes/lite-gray-check.png);}
.lite-cyan-check{background-image:url(http://csscheckbox.com/checkboxes/lite-cyan-check.png);}
.lite-orange-check{background-image:url(http://csscheckbox.com/checkboxes/lite-orange-check.png);}
.lite-red-check{background-image:url(http://csscheckbox.com/checkboxes/lite-red-check.png);}

.lite-x-cyan{background-image:url(http://csscheckbox.com/checkboxes/lite-x-cyan.png);}
.lite-x-gray{background-image:url(http://csscheckbox.com/checkboxes/lite-x-gray.png);}
.lite-x-blue{background-image:url(http://csscheckbox.com/checkboxes/lite-x-blue.png);}
.lite-x-orange{background-image:url(http://csscheckbox.com/checkboxes/lite-x-orange.png);}
.lite-x-red{background-image:url(http://csscheckbox.com/checkboxes/lite-x-red.png);}
.lite-x-green{background-image:url(http://csscheckbox.com/checkboxes/lite-x-green.png);}

.mac-style{background-image:url(http://csscheckbox.com/checkboxes/mac-style.png);}
.mario-style{background-image:url(http://csscheckbox.com/checkboxes/mario-style.png);}
.alert-style{background-image:url(http://csscheckbox.com/checkboxes/alert-style.png);}
.lite-plus{background-image:url(http://csscheckbox.com/checkboxes/lite-plus.png);}
.dark-plus{background-image:url(http://csscheckbox.com/checkboxes/dark-plus.png);}
.dark-plus-cyan{background-image:url(http://csscheckbox.com/checkboxes/dark-plus-cyan.png);}
.dark-plus-orange{background-image:url(http://csscheckbox.com/checkboxes/dark-plus-orange.png);}
.dark-check-cyan{background-image:url(http://csscheckbox.com/checkboxes/dark-check-cyan.png);}
.dark-check-green{background-image:url(http://csscheckbox.com/checkboxes/dark-check-green.png);}
.dark-check-orange{background-image:url(http://csscheckbox.com/checkboxes/dark-check-orange.png);}


.depressed-lite-small{background-image:url(http://csscheckbox.com/checkboxes/depressed-lite-small.png);}
.elegant{background-image:url(http://csscheckbox.com/checkboxes/elegant.png);}
.depressed{background-image:url(http://csscheckbox.com/checkboxes/depressed.png);}
.chrome-style{background-image:url(http://csscheckbox.com/checkboxes/chrome-style.png);}
.web-two-style{background-image:url(http://csscheckbox.com/checkboxes/web-two-style.png);}
.vlad{background-image:url(http://csscheckbox.com/checkboxes/vlad.png);}
.klaus{background-image:url(http://csscheckbox.com/checkboxes/klaus.png);}


/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*  jugu
    Created on : 08.08.2014, 12:04:33
    Author     : Falk checken
*/

@import 'utils';

.mainContentWrapper {
    &.settings{
        top: 0px;
        left: 400px;
        bottom:0px;
        right: 0px;

        @include respond-to(wide)   { 
            left:350px;
        }
        @include respond-to(normal) {
            left:350px;
        }
        @include respond-to(tablet-large)   { 
            left:300px;
        }
        @include respond-to(tablet-small)   { 
            right:-200px;
        }
        @include respond-to(mobile) {
            right:-200px;
        }
    }
}

.searchListWrapper {
    &.settings {
        left:0;
        width:250px;
    }
}
.searchList {
    position: absolute;
    bottom: 0px;
    top:0px;
    overflow-y: auto;
    overflow-x: hidden;
    &.settings {
        width:100%;
        top:0px;
    }
}
.listChild {
    width: 100%;
    float: left;
    border-bottom: 1px #e8e8e8 solid;
    cursor: pointer;
    font-weight: 400;
    color: #646464;
    display:block;
    border-right:1px transparent solid;
    overflow: hidden;



}

.contentWrapper.settings .mainContentWrapper {
    background-color:white;
}

li {
    &.listChild {
        text-align:center;
        &.category {
            background-color: rgb(228, 228, 228);
            color: #646464;
            cursor: default;
            font-weight: 700;
            padding: 10px 4%;
        }
        &.point {
            padding:20px 0;
        }
        &:hover {
            color: rgb(139, 139, 139);
            border-right: 1px #1cb07a solid;
        }
        &.active {
            background-color: #DAF1FF;
            &:hover {
                border-right:transparent;
            }
        }
    }
}
.singleBlockWrapper {
    width: 100%;
    float: left;
    height: auto;
    padding: 10px;

    .blockStyle {
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        background-color: white; 
        margin-bottom: 20px;
    }
}

.popupHeadline {
    padding: 0 20px;
    font-weight: 700;
    color: white;
    line-height: 30px;
    background-color: #0293ed;
    width:100%;
}

.onboardingForm {
    width: 100%;
    padding: 15px 15px;
    text-align: left;
    float: left;

    .form-group {
        margin-bottom: 0;
    }
}

.personForm {
    width: 100%;
    padding: 15px 15px;
    text-align: left;
    float: left;
    @extend %clearfix;

    .personData {
        padding-left: 20px;
        font-weight: 700;
        color: white;
        line-height: 30px;
        background-color: #0293ed;
        margin-bottom: 15px; }
    .clear {
        height: 56px;
        width: 100%;
    }

    .wrapper-buttons {
        margin-top:15px;
    }

    span{
        margin-top: 5px;

        &.w50 {
            width:50%;
            &.other {
                margin-top: 18px;
                .inputfield {
                    padding:5px 15px;

                }
            }

        }
        &.w85 {
            width:85%;
        }
        &.w100 {
            float:none;
            width:100%;

            &.formHeading {
                color:$bluecolor; 
                padding: 20px 15px;
                &.employee {
                    padding: 12px 0;
                }
            }

            &.attachment
            {
                padding-top:25px;
            }

            &.formParagraph {
                padding: 15px 15px 0
            }

            &.aditionalContent {
                padding: 0 36px;
            }

        }
        &.w15 {
            width:15%;
        }
        &.w75 {
            width: 75%;
        }

        &.w25 {
            width:25%;
        }

        &.w125 {
            width:125%;
        }
        &.w33 {
            width:33.3333%;
        }

        &.clear {
            clear: left
        }

        .radioWrapper {
            height: 30px;
            line-height: 30px;
            margin: 0;
            padding: 0;

            input {
                margin-left: 10px;
            }

            span {
                margin-right:10px;
                display:inline;
                float:none;
                color:$bluecolor;
                font-size:14px;
                padding:0 5px;

            }
            &.state {
                display:inline;
                height:auto;
            }
        }
    }
    &.down {
        margin-top:100px;
    }
    .additionalContent {
        width: 100%;
        float:left;
        padding: 0 15px 0 51px;

        table {

            &.contentTable {
                float:left;
                width: 100%;
                table-layout: fixed;
                border-collapse: collapse;

                thead,tbody,tr {
                    width: 100%;
                }


                td {
                    vertical-align: middle;
                    padding: 5px 0 10px;    

                    input[type=text]{
                        margin-top: 1px;
                    }

                }

                td:first-child {
                    padding-top:12px;
                    width:15px;
                    color: $bluecolor;

                }

                thead {
                    tr {
                        border-bottom:1px lightgrey solid;
                    }

                    td {
                        padding: 0;

                        span {
                            margin:10px 0 5px;
                        }
                    }
                }
                tbody tr {
                    border-bottom:1px lightgrey solid;

                }

            }

        }

    }

    .noLable {
        padding-top: 34px;
    }
}



label { 
    display:block;
    font-size: 13px;
    font-weight:400;
}

.childFields {
    margin-top:60px;
}

.formParagraph {
    font-weight:400;
    font-size: 12px;
    color:#9c9c9c;
    line-height:20px;


    &.pad50 {
        padding-top:50px;
    }
    &.pad100 {
        padding-top:100px;
    }
}

.buttonPopup {
    margin:0;
    padding: 8px 15px;
    cursor:pointer;
    width:120px;
    text-align: center;
    height: 30px;
    border-radius: 4px;
    @include box-shadow(inset 0px 1px 1px 0px rgba(0,0,0,0.1));
    font-size: 13px;
    &.save {
        float:right;
    }
    &.setts {
        float:left;
        margin: 60px 0;
    }
}

.row + .row {
    margin-top:10px;
    &.blocked-area {
        margin-top: 0;
        padding-top: 10px;
        &:last-child {
            margin-bottom: -15px;
            padding-bottom: 15px;
        }
    }
}

.row.nospacing + .row {
    margin-top:0;
}

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 07.08.2014, 12:43:48
    Author     : Falk
HalloTest*/

$break-small: 450px;
$break-medium: 800px;
$break-large: 1024px;
$break-wide: 1444px;
$bluecolor: #0293ed;
$greencolor: #1cb07a;  

@mixin respond-to($media) {
    @if $media == mobile {
        @media only screen and (max-width: $break-small) { @content; }
    }
    @else if $media == tablet-small {
        @media only screen and (min-width: $break-small + 1) and (max-width: $break-medium - 1) { @content; }
    }
    @else if $media == tablet-large {
        @media only screen and (min-width: $break-medium) and (max-width: $break-large - 1) { @content; }
    }
    @else if $media == normal {
        @media only screen and (min-width: $break-large) and (max-width: $break-wide - 1) { @content; }
    }
    @else if $media == wide {
        @media only screen and (min-width: $break-wide) { @content; }
    }
}



header.stable {
    @include respond-to(mobile)         { height: 50px; }
    @include respond-to(tablet-small)   { height: 50px; }
    @include respond-to(tablet-large)   { height: 50px; }
    @include respond-to(normal)         { height: 60px; }
    @include respond-to(wide)           { height: 60px; }

}
.mainContentWrapper.home {
    left:370px;
}


.searchListWrapper {
    &.home {
        width:370px;
    }
}
.searchBar {
    width: 100%;
    height: 52px;
    background-color: rgb(238, 238, 238);
    float:left;
    .search {
        position:absolute;
        margin: 11px 5%;
        width: 90%;
        height: 30px;
        border: 1px lightgrey solid;
        background-color:white;
        border-radius:4px;
        float:left;
        background-image: url(../images/sprites-search.png);
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) center;
        font-size: 14px;
        padding: 0 15px;
    }
}
.searchList {
    position: absolute;
    bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    &.date {
        top:52px;

    }
}

.listChild {
    width: 100%;
    float: left;
    border-bottom: 1px #e8e8e8 solid;
    cursor: pointer;
    font-weight: 400;
    color: #646464;
    display:block;
    border-right:1px transparent solid;
    overflow: hidden;

    &.category {
        background-color: rgb(228, 228, 228);
        color: #646464;
        cursor: default;
        font-weight: 700;
        padding: 10px 4%;
        text-align: center;
    }
}
li {
    &.listChild {
        .time {
            float:left;
            height:auto;
            width: 60%;
            line-height: 21px;
            font-size: 12px;
            font-weight: 700;
            text-align: right;

            .listItem {
                float:left;

                &.datum {
                    width:50%;
                    font-weight: 700;
                }
                &.zeit {
                    font-weight:400;
                    width:50%;  
                }

            }
        }
        .listItem {
            &.datum, &.type, &.zeit {
                border-right:1px #e8e8e8 solid;
                font-size: 13px;
                line-height:40px;
                text-align: center;
            }

            &.type {
                color: #0293ed;
                border:none;
                float:left;
                width:40%;
                font-weight: 700;
            }
        }
        &:hover {
            color: rgb(139, 139, 139);
            border-right: 1px #1cb07a solid;
        }
        &.active {
            background-color: #DAF1FF;
        }
    }
}
.mainContentWrapper {
    position:absolute;
    top: 0px;
    left: 500px;
    bottom:0px;
    right: 0px;
    text-align: center;
    padding:  10px;
    overflow: auto;

    &.calendar{
        left: 370px;
    }
}
.singleBlockWrapper {
    width: 100%;
    float: left;
    height: auto;
    padding: 10px;

    .blockStyle {
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        background-color: white;
        margin-bottom: 20px;
        &.space {
            height:517px;
        }
    }
}
.popupHeadline {
    padding: 0 20px;
    font-weight: 700;
    color: white;
    line-height: 30px;
    background-color: #0293ed;
    width:100%;
}

.views {
    width: auto;
    border: 1px rgb(228, 228, 228) solid;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
    li {
        border-right: 1px solid rgb(228, 228, 228);
        color: #646464;
        cursor: pointer;
        float: left;
        font-size: 14px;
        padding: 4px 0;
        text-align: center;
        width: 60px;
        &:last-child {
            border-right: none;
        }
    }
    .active {
        background-color: #0293ed;
        color:white;
    }
}

.wrapperCalendar {
    width: 100%;
    height:auto;
    float: left;
    border-top: 1px #dedede solid;
    &.week {
        border:none;
        /* padding: 0 20px 0 10px; */
    }
    &.month li li {
        border:none;
    }
    table {
        border-collapse: collapse;
        width: 100%;
        max-height: 605px;
        height:auto;
        margin-bottom: 20px;
        tr, td {
            border-collapse: collapse;  
        }
    }
}

.headerCalendar {
    padding: 10px 0 20px;
    height: 54px;
    float: left;
    display: inline-block;
    width: 100%;
    border-bottom: 1px #dedede solid;
    font-size: 16px;
    position: relative;
    margin-bottom:15px;
    ol{
        float: none;
        width: auto;  
    }
    li {
        display: inline;
        position: relative;  
    }
}

.wrapperNextWeek {
    position: absolute;
    width: 100%;
    .leftArrow, .rightArrow {
        display:inline-block;
        border: 6px black solid; 
        cursor: pointer;
        margin: 0 -4px 4px;
        opacity: 1;
        &:hover{
            opacity: 0.75;
        }
    }

    .leftArrow {
        left: 150px;
        border-color: transparent #0293ed transparent transparent ;
    }
    .rightArrow {
        right: 150px;
        border-color: transparent transparent transparent #0293ed;
    }

}

.months {
    display: inline-block;
    position:relative;
    height: auto;
    width: auto;
    position:relative; 
    webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;  
    li {
        padding: 5px 10px;
        width:auto;
        float:left;
        margin: 0px 5px;
    }
    .inactiveTime {
        opacity: 0.5;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
    .activeTime {
        background-color: white;
        border-radius:4px;
        border:2px #0293ed solid;
        padding: 3px 10px !important;
    }
}

.wrapperYear, .wrapperMonth {
    float: right;
    font-size: 16px;
    color:#028fe0;
    height: 24px;
    line-height: 22px;
    vertical-align: bottom;
    padding:0 10px;

}
.timeCol {
    width: 90px;
    text-align: center;
    font-size: 15px;
    border-collapse: collapse;
    color: #9F9F9F;
}

.yearCalendarWrapper {
    display: block;
    float: left;
    width: 100%;
    .miniCalYears {
        width:25%;
        float:left;
    }
}

.miniCal {
    padding: 10px;
    text-align: center;
    .monthGrid{
        height: 25px;
        .gridHeader {
            background-color:transparent;
            color:#0293ed;
            text-align:center;
            width:100%;
        }
        &.active {
            background-color: #1dc999;
            .gridHeader {
                color:white;
            }
        }
    }
    .monthHead {
        width:100%;
        height:15px;
        text-align:center;
        font-size: 11px;
        font-weight:700;
        color:#0293ed;
    }

    .thisDay {
        text-align: center;
    }
    table {
        margin-bottom: 5px;
    }
}

.minical {
    tr{
        &.dayheading {
            height: 15px;
            width: 100px;
            text-align: center;
            &.month {
                text-align:left;
            }
        }

    }
}

.newDateWrap {
    width:100%;
    height:60px;
    margin: 0 auto; 
    float:left;
    text-align:center;
    .buttonPopup, .buttonCancleRequest {
        margin:0;
        padding: 8px 15px;
        cursor:pointer;
        width:120px;
        text-align: center;
        height: 30px;
        border-radius: 4px;
        -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        font-size: 13px;
    }
    .buttonPopup{
        &.save {
            float:right;
        }
        &.setts {
            float:left;
            margin: 60px 0 0 15px;
        }
    }
    .btn-blue {
        border-color: #0E7DBF;
        color:white;
        background: #0293ed;
        background: -moz-linear-gradient(top,  #0293ed 0%, #0696d4 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0293ed), color-stop(100%,#0696d4));
        background: -webkit-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
        background: -o-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
        background: -ms-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
        background: linear-gradient(to bottom,  #0293ed 0%,#0696d4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0293ed', endColorstr='#0696d4',GradientType=0 );
    }
    .createButton {
        height: 30px;
        width: 80px;
        margin: 15px 4px;
        border-radius: 4px;
        -webkit-box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        box-shadow: inset 0px 1px 1px 0px rgba(0,0,0,0.10);
        padding: 5px 15px;
        font-size: 13px;
        &.down {
            width:120px;
            float:right; 
            padding: 9px 0px;
            cursor:pointer;
            margin:0 15px;
            font-weight:400;
            vertical-align: top;
            &:hover {
                background: #DAF1FF;
                background: -moz-linear-gradient(top,  #028fe0 0%, #068dc7 100%);
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#028fe0), color-stop(100%,#068dc7));
                background: -webkit-linear-gradient(top,  #028fe0 0%,#068dc7 100%);
                background: -o-linear-gradient(top,  #028fe0 0%,#068dc7 100%);
                background: -ms-linear-gradient(top,  #028fe0 0%,#068dc7 100%);
                background: linear-gradient(to bottom,  #28A6F5 0%,#1598CF 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#028fe0', endColorstr='#068dc7',GradientType=0 );
            }
            &.active {
                border-color: #0E7DBF;
                color:white;
                background: #0293ed;
                background: -moz-linear-gradient(top,  #0293ed 0%, #0696d4 100%);
                background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#0293ed), color-stop(100%,#0696d4));
                background: -webkit-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
                background: -o-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
                background: -ms-linear-gradient(top,  #0293ed 0%,#0696d4 100%);
                background: linear-gradient(to bottom,  #016CAF 0%,#0071A2 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0293ed', endColorstr='#0696d4',GradientType=0 );
            }

        }
    }
}
    .timetrack-action.disabled,timetrack-action.disabled:hover
    {
        color:gray;
        text-decoration: line-through!important;
    }
/*
@media all and (max-width: 1550px) {
    .home{
        .mainContentWrapper {
            left: 250px;
        }
    }
    .searchListWrapper {
        &.home{
            width: 250px;
        }
        .searchList {
            .listChild {

            }
        }
    }

    li {
        &.listChild {
            .time {
                float:left;
                width:50%;

                .listItem {
                    float:left;

                    &.datum {
                        display:block;
                        width:100%;
                    }
                    &.zeit {
                        border-bottom:none;
                        float:none;
                        width:100%;  
                    }

                }
            }
            .listItem {
                &.datum, &.type, &.zeit {
                    padding: 0 9px;
                    border-right:1px #e8e8e8 solid;
                    font-size: 13px;
                    line-height:40px;
                    border-bottom:1px #e8e8e8 solid;
                    width:50%;
                    box-sizing: border-box;  
                    text-align: center;
                }

                &.type {
                    border:none;
                    text-align: center;
                    width:50%;
                    line-height: 80px;
                }
            }
        }
    }
    .yearCalendarWrapper {
        .miniCalYears {
            width:33%;
            float:left;
        }
    }
}
@media all and (max-width:1800px) {
    .yearCalendarWrapper {
        .miniCalYears {
            width:33%;
            float:left;
        }
    }
}
@media all and (max-width:1320px) {
    .yearCalendarWrapper {
        .miniCalYears {
            width:50%;
            float:left;
        }
    }
}
@media all and (max-width: 1250px) {
    .home {
        .mainContentWrapper {
            left: 0px;
        }
        .searchListWrapper {
            left: -250px;

        }
    }
    .yearCalendarWrapper {
        .miniCalYears {
            width:50%;
            float:left;
        }
    }
}

@media all and (max-width: 1000px) {
    .home {
        .mainContentWrapper {
            left: 0px;
            padding: 0px;
        }
        .searchListWrapper {
            left: -250px;
        }
        .singleBlockWrapper {
            padding: 0px;
        }
        .blockStyle {
            padding: 0px;
        }
    }
    .contentWrapper {
        left: 51px;
    }
    nav {
        &.mainNavigation {
            width: 51px;
            ul.sidebar{
                
                li {
                    &.child {
                        width: 50px;
                        height: 50px;
                        background-size: 123px; 
                        @include respond-to(mobile)         { width: 100%; }
                        @include respond-to(tablet-small)   { width: 100%; }
                    }
                    .navTitle {
                        display: none;
                    }
                    &.home {
                        background-position: 14px 14px;
                        @include respond-to(mobile)         { background-position: 14px 14px; }
                        @include respond-to(tablet-small)   { background-position: 14px 14px; }
                        @include respond-to(tablet-large)   { background-position: 14px 14px; }
                    }
                    &.caldenar {
                        background-position: -36px 14px;
                        @include respond-to(mobile)         { background-position: -36px 14px }
                        @include respond-to(tablet-small)   { background-position: -36px 14px }
                        @include respond-to(tablet-large)   { background-position: -36px 14px }
                    }
                    &.settings {
                        background-position: -86px 14px;
                        @include respond-to(mobile)         { background-position: -86px 14px; }
                        @include respond-to(tablet-small)   { background-position: -86px 14px; }
                        @include respond-to(tablet-large)   { background-position: -86px 14px; }
                    }
                }
            }
        }
    }

    .yearCalendarWrapper {
        .miniCalYears {
            width:50%;
            float:left;
        }
    }

    .wrapperCalendar {
        padding: 10px;
    }
}

@media all and (max-width: 800px) {
    .yearCalendarWrapper {
        .miniCalYears {
            width:100%;
            float:left;
        }
    }
}

@media all and (max-width: 500px) {
    .yearCalendarWrapper {
        .miniCalYears {
            width:100%;
            float:left;
        }
    }
}
*/

/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19.08.2014, 10:55:39  
    Author     : falkmichel halloHALLOOOO
*/

@import 'utils';

.leftSubNavWrapper {
    width:auto;
    background-color: white;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0px;
    border-right:1px solid $lightgrey;
    z-index:250;
    overflow:hidden;
    @include noSelect();

    &.planning {
        @include respond-to(mobile) {
            width: 100%;
            left: 0;
        }
        @include respond-to(tablet-small) {
            width: 100%;
            left: 0;
        }
        @include respond-to(tablet-large) {
            width: 300px;
            left: 0;
        }
        @include respond-to(normal) {
            width: 360px;
            left: 0;
        }
        @include respond-to(wide) {
            width: 360px;
            left: 0;
        }
    }

    &.settings {
        width:400px;
        @include respond-to(mobile) {
            width: 100%;
            left: 0;
        }
        @include respond-to(tablet-small) {
            width: 100%;
            left: 0;
        }
        @include respond-to(tablet-large) {
            width: 300px;
            left: 0;
        }
        @include respond-to(normal) {
            width: 360px;
            left: 0;
        }
        @include respond-to(wide) {
            width: 360px;
            left: 0;
        }
    }
}

.subNav {

    position: absolute;
    width:100%;
    min-height: 100%;
    right: 0;
    background-color:white;

    @include transition(right .4s ease-in-out 0.1s);

    .circleIcon {
        width:23px;
        height:23px;
        float:left;
        margin:5px;

        &.createNew {
            -webkit-filter: invert(60%);
            filter: invert(60%);
            display: inline-block;
            border-radius: 60px;
            box-shadow: 0px 0px 2px #ffffff;
        }

        &.customer {
            background: url("../images/adminlistsprite.png") no-repeat -98px 2px;
        }

        &.department {
            background: url("../images/adminlistsprite.png") no-repeat -3px 3px;
        }

        &.location {
            background: url("../images/icons/pointer.svg") no-repeat;
            width:24px;
            height:24px;
            margin: 8px 11px;
            background-size: 12px;
            &.iconPlusItem {
                background-position: 6px;
            }
        }

        &.notify {
            background: url(../images/notify.png) no-repeat 4px 4px / 16px auto rgba(0, 0, 0, 0);
        }

        &.plan {
            background: url(../images/rocket.png) no-repeat 4px 4px / 16px auto rgba(0, 0, 0, 0);
        }

        &.planningPeriod{
            background: url("../images/icons/compass.svg") no-repeat;
            background-size: auto 23px;
        }

        &.qualification {
            background: url("../images/icons/bulb.svg") no-repeat;
            width:24px;
            height:24px;
            margin: 8px 11px;
            background-size: 12px;
            &.iconPlusItem {
                background-position: 6px;
            }
        }

        &.shiftGroup {
            background: url("../images/adminlistsprite.png") no-repeat -69px 3px;
        }

        &.rights {
            background: url(../images/hammer.png) no-repeat 4px 4px / 16px auto rgba(0, 0, 0, 0);
        }

        &.rule {
            background: url(../images/rule.png) no-repeat 4px 4px / 16px auto rgba(0, 0, 0, 0);
        }

        &.ruleSet {
            background: url(../images/hammer.png) no-repeat 4px 4px/16px auto transparent;
        }

        &.user {
            background: url("../images/adminlistsprite.png") no-repeat -35px 2px;
        }

        &.timetrackProfile {
            background: url("../images/adminlistsprite.png") no-repeat -98px 2px;
        }

        &.rolePermission {
            background: url("../images/user-sprites-white.png") no-repeat -31px ;
        }

        &.pauseAdjustment {
            background: url("../images/icons/pause.png") no-repeat;
        }

        &.users {
            background: url(../images/users.png) no-repeat 4px 3px / 16px auto rgba(0, 0, 0, 0);
        }

        &.workplace {
            background: url("../images/adminlistsprite.png") no-repeat -132px 2px;
        }

        &.enrollment {
            background: url("../images/adminlistsprite.png") no-repeat -196px 3px;
        }

        &.swapRequests {
            background: url("../images/swapIcon2.png") no-repeat 2.6px 2px;
        }

        &.device {
            background: url("../images/icons/photo.svg") no-repeat;
            width:24px;
            height:24px;
            margin: 8px;
            background-size: 18px;
            &.iconPlusItem {
                background-position-x: 3px;
                background-position-y: 3px;
            }
        }

        &.timetrack {
            background: url("../images/icons/clock.svg") no-repeat;
            width:24px;
            height:24px;
            margin: 7px 8px;
            background-size: 18px;
        }

        &.setup {
          background: url("../images/settings-24.png") no-repeat 0 0;
          width:36px;
          height:33px;
        }

        &.onboarding {
            background: url("../images/icons/onboarding.png") no-repeat 0 0 ;
            margin: 6px 0 0 6px;
            background-size: 22px;
        }

        &.devices {
            background: url("../images/icons/devices.png") no-repeat 0 0;
            margin: 6px 0 0 6px;
            background-size: 22px;
        }

        &.timetrackIdentifier {
            background: url("../images/icons/tag.svg") no-repeat;
            width:24px;
            height:24px;
            margin: 8px 7px;
            background-size: 18px;
            &.iconPlusItem {
                background-position: 2px;
            }
        }
    }

    .listHead, .listBottom {
        display: table-row;
        width: 100%;
    }

    .listMiddle {
        display: block;
        overflow-y: auto;
        height: 100%;
        
        ul {
        	margin: 0;
        }
    }

    .scrollWrapper {
        overflow-y: auto;
        max-height: 100%;
        height: 100%;
    }

    .listHead {
        height: 40px;
        padding: 0;
        display: table;
        vertical-align: top;
        position: relative;
        width: 100%;

        .goBack {
            background: url("../images/arrow-left.png") no-repeat scroll center center rgba(0, 0, 0, 0);
            text-align: center;
            border-right: 1px solid #D3D3D3;
            color: #0293ED;
            cursor: pointer;
            font-size: 14px;
            width: 40px;
            height: 40px;
            display: table-cell;
            vertical-align: top;
        }

        .sectionHeading {
            display: table-cell;
            border-right: 1px solid #D3D3D3;
            color: $colorPrimary;
            text-align: center;
            height: 40px;
            font-weight:500;
            vertical-align: middle;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }


        .searchButtonWrapper {
            display: table-cell;
            width: 40px;
            height: 40px;


            .activateSearchButton, .cancelSearchButton {
                text-align: center;
                color: #0293ED;
                cursor: pointer;
                font-size: 14px;
                vertical-align: top;
                width: 100%;
                height: 100%;
            }

            .activateSearchButton{
                background: url("../images/search-0293ed.png") no-repeat center 55% transparent;
            }

            .cancelSearchButton{
                display: none;
                background: url("../images/cancel-circle-0293ed.png") no-repeat center 55% transparent;
            }
        }
    }

    .searchElement {
        width: 100%;
        height: 44px;
        min-height: 0;
        background-color: #EEEEEE;
        position: relative;
        border-bottom: 1px solid #D3D3D3;
        display: none;
        padding-top: 7px;

        .search{
            width: 95%;
            background-color: white;
            height: 30px;
            min-height: 0;
            border: 1px $lightgrey solid;
            font-size: 14px;
            display: block;
            margin: auto;
            float: none;

            &:focus {
                outline: none;
                border: 1px $colorPrimary solid;
            }
        }
    }

    .listBottom {
        width: 100%;
        padding: 0 10px 0 4px;
        height: 42px;
        margin-bottom: -42px;
        background-color: rgb(238, 238, 238);
        border-bottom:1px lightgrey solid;
        &.forAdd {
            cursor:pointer;
            height:auto;
            display: block;
            padding: 0;
            
            .mainWrap {
                border-top:1px solid $lightgrey;
            }

            .basicEmployeeData {
				position: absolute;
				bottom: -10px;
				width: 100%;
				opacity: 0;
                visibility: hidden;
				@include transition(all .1s ease-in-out 0.1s);
				
                .personForm {
                    padding:0 10px;
                    @extend %clearfix;
                }
                
                &.in {
                	bottom: 0;
                	visibility: visible;
                	opacity: 1;
                }
                
                fieldset + fieldset {
				    margin-top: 0;
				}
            }
        }
    }

    &.secondLevel {
        right: -100%;
        padding: 42px 0;
        &.active {
            right: 0;
        }
    }

    &.passiv {
        right: 25%;

    }

    .menuHeading {
        width:100%;
        height:60px;
        color:$grey;
        font-size:20px;
        padding:20px 114px;
        border-bottom:1px $lightgrey solid;
    }

    .subChild {
        &.active {
            background-color:$facegrey;
        }

        width:100%;
        cursor:pointer;
        -webkit-tap-highlight-color: lightblue;
        height:auto;
        float:left;
        border-bottom:1px solid $lightgrey;
        @include transition(background .15s ease-in-out);

        &:hover {
            background-color:$facegrey;
        }


        .mainWrap {
            width:100%;
            .descriptionWrap {
                height:auto;
                width:auto;
                float:left;

                .listHeading {
                    color:$grey;
                    display:inline-block;
                    font-weight:500;
                    line-height:22px;
                }
                .listSubHeading {
                    color:$textColorDefault;
                }
            }
           
           &.noSubheading {
                .listHeading {
                	line-height:38px;
                    width: 230px;
           	 	}
           }

           &.noSubheading.noIcon {
                .listHeading {
                	line-height:22px;
           	 	}
           }

        }
       
        &.addToList {
            border-bottom:0;
            background-color:initial !important;
            &:hover {
                background-color:initial !important;
            }
        }

    }

    ul.subNav {
        @include transition(right .4s ease-in-out 0.1s);
    }
    
    .subChild {
        .leftwrap {
            width:20%;
            float:left;
            .listCircle {
                border-radius: 50%;
                width: 34px;
                height: 34px;
                margin:8px auto;
                background-color:$grey;
            }
        }
        .mainWrap {
            width:80%;
            float:left;
            .descriptionWrap {
                margin: 6px 0;
                line-height:18px;
            }
            .listArrow {
                float:right;
                width:15px;
                height:22px;
                line-height:22px;
                margin:13px 15px;
                font-size:21px;
            }
            &.addSection {
                width:25%;
                float:left;
                border-right:1px $lightgrey solid;
                border-top:1px $lightgrey solid;
                border-bottom:none;
                background-color: $facegrey;
                &:last-child {
                    border-right:none;
                }
                &:hover {
                    background-color:#eeeeee;
                }
                &.w100 {
                    width:100%;
                }
                &.w50 {
                    width:50%;
                }
                &.w30 {
                    width:33.3333%;
                }

                .addSprite {
                    background: url("../images/addsprites.png") no-repeat center;
                    width:26px;
                    height:26px;
                    display:block;
                    margin:10px auto;
                    &.department {
                        background-position: 0 0px;
                    }
                    &.shiftGroup {
                        background-position: -52px 0px;
                    }
                    &.user {
                        background-position: -156px 0px;
                    }

                    &.workplace {
                        background-position: -104px 0px;
                    }

                    &.location {
                        background-position: -200px 0px;
                    }

                    &.workplaceallocation {
                        background-position: -260px 0px;
                    }
                }

                &.active {
                    background-color:#eeeeee;
                    border-bottom: none;
                }

            }

            &.noIcon {
                width:100%;
                float:none;
                .listArrow {
                    margin:5px 15px;
                }
                .descriptionWrap {
                    padding-left:15px;
                    margin-top: 6px;
                }
            }
        }
        &.picked {
            background-color: #eeeeee !important;

            .listSubHeading {
                color:grey;
            }
            .listArrow {
                background-position: -3px 0;
            }
        }
        &.addToList {
            bottom:0;
            width:100%;

            .mainWrap {
                width:25%;
                float:left;
            }
        }
    }

    &.firstLevel {

        overflow: hidden;
        height: 100%;

        .listHead, .listBottom {
            width: 100%;
            height: 42px;
            background-color: $facegrey;
            border-bottom: 1px lightgrey solid;
        }

        .notifcation {
            background-color: $colorPrimary;
            border-radius: 50%;
            color:$colorWhite;
            display: inline-block;
            float: right;
            font-weight:400;
            height: 20px;
            line-height:20px;
            text-align:center;
            margin: 15px 0;
            width: 21px;
            @include respond-to(mobile)         {
                left: 245px;
                padding: 4px 6px;
            }
            @include respond-to(tablet-small)   { left: 315px; }
            @include respond-to(tablet-large)   { left: 220px; }
            @include respond-to(normal)         { left: 250px; }
            @include respond-to(wide)           { left: 275px; }
        }

        .badge {
            margin: 9px 0;
        }

    }

    &:not(.secondLevel) .listHeading .badge {
        margin: 10px 0;
    }

    &.secondLevel {

        overflow: hidden;
        height: 100%;

        .badge {
            margin: 2px 0;
        }

        .mainWrap {
            width: 100%;
        }

        .listHead, .listBottom {
            width: 100%;
            height: 42px;
            background-color:$facegrey;
            border-bottom:1px lightgrey solid;
            &.forAdd {
                cursor:pointer;
                display: block;
                .mainWrap {
                    border-top:1px solid $lightgrey;
                }

                .basicEmployeeData {

                    .personForm {
                        padding:0 10px;
                        @extend %clearfix;
                    }
                }
            }

            &.shiftgroups {
                .search {
                    width:64%;
                    @include respond-to(normal)         { width: 58%; }
                }

                .goBack {
                    @include respond-to(tablet-large)   { max-width: 45%; }
                }
            }

        }
        
        .listHead {
        	margin-top: -42px;
        }
        	
        .listBottom {
        	margin-bottom: -42px;
        }
        	
        .listContainer {
            position:absolute;
            width:100%;
            top:41px;
            bottom:44px;
            overflow:scroll;

            &.shrink {
                bottom:128px;
            }
        }

        .listBottom {

            .listHeading {
                color:#9b9b9b;
            }
            .addEmp {
                padding:0;
                span {
                    padding:0;

                    &.w100 {
                        &:last-child {
                            margin-top:0;
                        }
                    }
                    &.w90 {
                        margin:0px;
                    }
                    &.w40 {
                        width:40%;
                        margin:0 ;


                    }
                    &.w90 {
                        width:90%;
                    }
                    &.w20 {
                        width:20%;
                    }
                }
                .search {
                    float:left;
                    width:49%;
                    background:white;

                    &.em {
                        width:100%;
                    }
                    &.left {
                        float:left;
                    }
                    &.right {
                        float:right;
                    }
                }

                .regButton {
                    margin:5px 8px;
                    height:23px;
                    width:auto;
                    padding: 4px 7px;
                    &.right {
                        float:right;
                        margin-right:0;
                    }

                    &:first-child {
                        margin-left:0;
                    }
                }

                .inputfield {
                    float:left;
                    width:100%;
                }

            }
        }

        &.employee {
            .mainWrap {

                padding:6px 0 7px 0;

                .listHeading {
                    display: inline-block;
                    padding-left:13px;
                    font-size: 13px;
                    font-weight:500;
                    color:$grey;
                    line-height:22px;
                    width: 300px;
                }
                .plusIcon {
                    background: url("../images/icons/plus.svg") no-repeat; /* #9b9b9b */
                    #background-position:-154px 0;
                    width:32px;
                    height:32px;
                    float:left;
                    margin: 0px 10px 6px 10px;

                }
                .listArrow {
                    margin: 1px 8px;
                }
            }

            .listBottom {
                .listHeading {
                    line-height:31px;
                }
            }
        }
    }
}

.createNewPlusIcon {
    float: left;
    margin-left: 92%;
    margin-top: -28%;
    zoom: 1;
    -ms-zoom: 1;
    -webkit-zoom: 1;
    -moz-transform:  scale(1,1);
    -moz-transform-origin: left center;
}

